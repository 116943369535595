import For from 'nano/For.jsx'

import {on_mounted, ref} from 'nano/reactive.jsx'
import "./Recorder.css"

console.log(EditorPage.name, {type: 'tracer'})
export default function EditorPage(props={}, children=[]) {

    const $canvas = ref()
    let audio_buffer
    const samples = []
    const $samples = ref()
    let context
    async function load_audio() {
        const resp = await fetch('/stories/Pipi1-6.m4a')
        const contentLength = resp.headers.get('content-length');
        const array_buffer = await resp.arrayBuffer()
        console.log(contentLength, array_buffer.byteLength)
        const context = new (window.AudioContext || window.webkitAudioContext)();

        audio_buffer = await context.decodeAudioData(array_buffer)


        console.log(audio_buffer)


        for (let i = 0; i < array_buffer.byteLength; i += sampleRate) {

        }
    }

    let start = 0
    let end = 0

    const POINTS = 1024

    async function visualize() {
        const canvas = $canvas.value
        const context = canvas.getContext('2d')

        context.fillStyle = "rgb(200 200 200)";
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.lineWidth = 2;
        context.strokeStyle = "rgb(0 0 0)";

        const sliceWidth = (canvas.width * 1.0) / POINTS;
        let x = 0;

        context.beginPath();
        for (let i = start; i < end; i++) {
            const v = samples[i] / 128.0;
            const y = samples[i] * canvas.height / 2 + canvas.height / 2;

            if (i === 0) {
                context.moveTo(x, y);
            } else {
                context.lineTo(x, y);
            }

            x += sliceWidth;
        }
        // canvasCtx.lineTo(WIDTH, HEIGHT / 2);
        context.stroke();


        end += 1
        start = Math.max(0, end - POINTS)
        if (end < samples.length) {
            requestAnimationFrame(visualize)
        }
    }

    async function play() {
        const resp = await fetch('/stories/Pipi1-6.m4a')
        const contentLength = resp.headers.get('content-length');
        const array_buffer = await resp.arrayBuffer()
        console.log(contentLength, array_buffer.byteLength)
        const context = new (window.AudioContext || window.webkitAudioContext)();

        audio_buffer = await context.decodeAudioData(array_buffer)


        const {sampleRate, duration, length, numberOfChannels} = audio_buffer

        const start = 30
        const end = 50

        const channelData = audio_buffer.getChannelData(0)

        const rate = sampleRate / 60



        const slice = channelData.slice(start * sampleRate, end * sampleRate)

        for (let i = 0; i < slice.length; i += rate) {
            samples.push(slice[i])
        }
        $samples.value = samples
        console.log(samples.length)
        await visualize()

        const buffer = context.createBuffer(1, (end - start) * sampleRate, sampleRate)

        // Define the duration of the ramp-up and ramp-down in seconds
        const rampDuration = 5; // 0.5 second for ramp-up and ramp-down
        const rampLength = Math.floor(sampleRate * rampDuration); // Number of samples in the ramp

        // Ramp-Up
        for (let i = 0; i < rampLength; i++) {
            const normalizedTime = i / rampLength; // Time goes from 0 to 1 over the ramp
            const rampValue = Math.log10(1 + 9 * normalizedTime);
            slice[i] *= rampValue;
        }

        // Ramp-Down
        for (let i = 0; i < rampLength; i++) {
            // const rampValue = 1 - Math.sqrt(i / rampLength); // Linear ramp from 1 to 0
            const normalizedTime = i / rampLength; // Time goes from 0 to 1 over the ramp
            const rampValue = Math.log10(1 + 9 * (1 - normalizedTime));
            slice[length - 1 - i] *= rampValue;
        }

        buffer.getChannelData(0).set(slice, 0)

        const sourceBuffer = context.createBufferSource()
        sourceBuffer.buffer = buffer
        sourceBuffer.connect(context.destination)
        sourceBuffer.start()
    }

    // on_mounted(load_audio)
    return (
        <div>
            <div style="display: flex; gap: 30px;">
                <button onclick={play}>play</button>
            </div>
            <canvas _ref={$canvas} class="audio_visualizer"></canvas>
        </div>)
}