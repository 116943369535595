import {hierarchy as d3_hierarchy, tree, linkHorizontal, linkVertical} from 'd3'
import {ref, computed} from 'nano/reactive.jsx'
import For from 'nano/For.jsx'

function WordNode(props={}, children=[]) {
    const {node} = props
    const style = {position: `absolute`, left: `${node.y}px`, top: `${node.x - 2}px`, transform: `translate(0, -50%)`}
    return (
        <div style={style}>
            word
        </div>)
}

function SenseNode(props={}, children=[]) {
    const {node} = props
    const style = {position: `absolute`, left: `${node.y}px`, top: `${node.x}px`, transform: `translate(0, -50%)`}
    return (
        <div style={style}>
        sense
    </div>)
}


export default function WordnetPage(props={}, children=[]) {
    const width = window.innerWidth
    const height = window.innerHeight - 90
    const size= [width, height]
    const data = ref({
        word: 'dog',
        children: [
            {definition: 'animal'},
            {definition: 'person'}
        ],
    })
    //
    // const data = ref({
    //     name: "Eve",
    //     children: [
    //         {name: "Cain"},
    //         {name: "Seth", children: [{name: "Enos"}, {name: "Noam"}]},
    //         {name: "Abel"},
    //         {name: "Awan", children: [{name: "Enoch"}]},
    //         {name: "Azura"}
    //     ]
    // });

    const hierarchy = computed(() => d3_hierarchy(data.value))
    const layout = computed(() => tree().size([ height, width / 3])(hierarchy.value))
    const nodes = computed(() => layout.value.descendants())
    const links = computed(() => layout.value.links())

    console.log(nodes.value)
    console.log(links.value)
    const generator = linkHorizontal().x(d => d.y).y(d => d.x)
    const shifted_generator = link => {
        const {source, target} = link
        return generator({
            source: {x: source.x, y: source.y + 60},
            target: {x: target.x, y: target.y - 5}
        })
    }
    return (
        <div class="page wordnet" >
            <svg class="full">
                <For _each={links}>{(link, index) => {
                    // const display = computed(() => is_inside(link.source) || is_inside(link.target) ? 'unset' : 'none')
                    const source = link?.source?.data?.name
                    const target = link?.target?.data?.name
                    return <path d={shifted_generator(link)} class="link" data-source={source} data-target={target} _draw={{duration: 500, delay: 1000}}></path>
                }}</For>
            </svg>
            <div class="full top">
                <For _each={nodes}>{(node, i) => {
                    return i === 0 ? <WordNode node={node}></WordNode> : <SenseNode node={node}></SenseNode>
                }}</For>
            </div>
        </div>)
}