import {ref_render} from "nano/nano.jsx";
import StoryPage from "./StoryPage.jsx";

const NAME = 'StoryAlgorithm'
console.log({type: "tracer"})
export default function StoryAlgorithm(context) {
    const props = {data: context.data}
    const me = {update}
    async function update($dom) {
        await ref_render($dom, StoryPage, {props})
    }
    return me
}