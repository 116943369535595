import "./Options.css";
import IconArrowUp from "solidjs-feather/IconArrowUp.jsx";
import IconArrowLeft from "solidjs-feather/IconArrowLeft.jsx";
import For from 'nano/For.jsx'
import Show from 'nano/Show.jsx'
import Visible from 'nano/Visible.jsx'

import {computed, ref} from 'nano/reactive.jsx'
import IconPlus from "solidjs-feather/IconPlus.jsx";
import IconEdit3 from "solidjs-feather/IconEdit3.jsx";

import {
    $app_language,
    header,
    $languages,
    meta,
    $native_languages,
    $passive_learning_languages,
    position,
    $active_learning_language
} from "../store.jsx";
import api from '../api.jsx'

import {get_lang_flag} from "nano/lib/unicode.js";
import Header from "../components/Header.jsx";
import {to_base64} from "../../common/converter.js";

console.log({type: 'tracer'})
export default function Options(props = {}, children = []) {
    const {
        data = {},
        $shiftX,
    } = props;

    // const app_language = ref(meta.value.app_language)
    // const active_learning_language = ref(meta.value.active_learning_language)
    // const native_languages = ref(meta.value.native_languages)
    // const passive_learning_languages = ref(meta.value.passive_learning_languages)

    const $edit_app_language = ref(false)
    const $edit_active_learning_language = ref(false)
    const $adding_native_languages = ref(false)
    const $adding_passive_learning_languages = ref(false)

    const enabled_languages = meta.value.enabled_languages || {}


    async function save_options() {
        const data = JSON.stringify({
            app_language: $app_language.value,
            active_learning_language: $active_learning_language.value,
            native_languages: $native_languages.value,
            passive_learning_languages: $passive_learning_languages.value,
        })
        console.log(data)
        const response = await api.post('/api/meta', {data: to_base64(data)})
        meta.languages = response.languages
        $languages.value = response.languages
    }


    function LanguageHeader(props, children) {
        const {label, $editing, Icon} = props
        function onclick(event) {
            // event.stopPropagation()
            event.$editing = $editing
            $editing.value = !$editing.value
        }

        return (
            <div style="padding: 0 20px;">
                <span>{label}</span>
                <span style={{
                    color: computed(() => $editing.value ? '' : 'var(--blue)'),
                    margin: '0 5px',
                    position: "relative",
                    display: 'inline-block',
                    top: "4px"
                }}
                      onclick={onclick}>
                        <Icon/>
                    </span>
            </div>
        )
    }
    function Languages(props = {}, children) {
        const {$languages} = props
        console.log($languages)
        const is_array = Array.isArray($languages.value)
        if (is_array) {
            return (
                <div style="display: flex; padding: 0 20px; align-items: center; gap: 5px 15px; flex-wrap: wrap; z-index: -1">
                    <For _each={$languages}>{(lang, i) => {
                        async function onclick() {
                            $languages.value = $languages.value.filter(language => language !== lang)
                            await save_options()
                        }
                        return <span class="language" onclick={onclick}>{get_lang_flag(lang)}</span>
                    }}</For>
                </div>)
        } else {
            return (
                <div style="display: flex; padding: 0 20px; align-items: center; gap: 5px 15px; flex-wrap: wrap; z-index: -1">
                    <span class="language">{computed(() => get_lang_flag($languages.value))}</span>
                </div>)
        }
    }

    function LanguageSelector(props) {
        const {$result, total, radius=70, $editing = ref(true), stagger=60} = props

        const is_array = Array.isArray($result.value)

        const each = computed(() => {
            const keys = Object.keys(total)
            const langs = is_array ?
                keys.filter(lang => !$result.value.includes(lang)) :
                keys.filter(lang => lang !== $result.value)

            console.log(langs)
            // const step = 360 / langs.length
            return langs.map((lang, i) => {
                const flag = get_lang_flag(lang);
                // const angle = step * i;
                // const radical = angle * Math.PI / 180;  // Convert to radians
                // const x = (radius +  Math.cos(radical) * radius).toFixed(1);   // Use 'radical' (radians) here
                // const y = ( radius + Math.sin(radical) * radius).toFixed(1);   // Use 'radical' (radians) here
                // const transform = `translate(${x}px, ${y}px)`
                const label = total[lang]
                return {lang, flag, label, /*angle, radical, x, y, transform, delay: i * stagger*/};

            })
        })
        function onclicker(lang) {
            return async function (event) {
                const {clientX, clientY, currentTarget} = event
                if (is_array) {
                    event.stopPropagation() // so that will not close it
                }
                const rect = currentTarget.getBoundingClientRect()
                console.log({clientX, clientY, rect})
                if (is_array) {
                    $result.push(lang)
                } else {
                    $result.value = lang
                }
                await save_options()
            }
        }


        // console.log(JSON.stringify(each.value, null, 4))

        return (
            <div class="position_holder">
                <div style={`--radius: ${radius}px`} class={{language_selector: true, editing: $editing}}>
                    <For _each={each}>{(item, i) => {
                        const style = {
                            position: 'relative',
                            // '--transform': item.transform,

                            // '--delay': `${item.delay}ms`
                        }
                        return  <div onclick={onclicker(item.lang)}
                                     style={style}>
                            <span>{item.flag} </span>
                            <span class={`${item.lang}`}>{item.label}</span>
                        </div>
                    }}</For>
                </div>
            </div>)
    }

    function onclick_option(event) {
        const {$editing} = event

        if ($editing !== $edit_app_language) {
            $edit_app_language.value = false
        }
        if ($editing !== $edit_active_learning_language) {
            $edit_active_learning_language.value = false
        }
        if ($editing !== $adding_native_languages) {
            $adding_native_languages.value = false
        }
        if ($editing !== $adding_passive_learning_languages) {
            $adding_passive_learning_languages.value = false
        }
    }

    function Option(props, children) {
        const {$editing, $languages, Icon, total, label} = props
        return (
            <div class="option" style={{'z-index': computed(() => $editing.value ? 1 : 0)}}>
                <LanguageHeader label={label} $editing={$editing} Icon={Icon}/>
                <Languages $languages={$languages}/>
                <Visible when={$editing}>
                    <LanguageSelector $result={$languages} total={total} $editing={$editing}/>
                </Visible>
            </div>)
    }
    return (
        <div class="page options" onclick={onclick_option}>
            <Show when={computed(() => !header.value || position.value === 'right')}>
                <Header $shiftX={$shiftX}></Header>
            </Show>
            <div style="height: 10px"></div>


            <Option label="App Language"
                    $editing={$edit_app_language}
                    $languages={$app_language}
                    total={enabled_languages}
                    Icon={IconEdit3}/>
            <Option label="Active Learning Language"
                    $editing={$edit_active_learning_language}
                    $languages={$active_learning_language}
                    total={enabled_languages}
                    Icon={IconEdit3}/>
            <Option label="Native Languages"
                    $editing={$adding_native_languages}
                    $languages={$native_languages}
                    total={enabled_languages}
                    Icon={IconPlus}/>
            <Option label="Passive Learning Languages"
                    $editing={$adding_passive_learning_languages}
                    $languages={$passive_learning_languages} total={enabled_languages} Icon={IconPlus}/>
            <div style="flex: 1"></div>
        </div>
    );
}