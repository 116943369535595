import "./ShootingWord.css";
import {db, meta} from "../store.jsx";
import {AsyncImage} from "nano/lib/Async.js";
import Player from "./Player.js";
import Word from "./Word.js";
import Projectile from "./Projectile.js";
import Star from "./Star.js";
import Meteor from "./Meteor.js";
import {after_mounted, ref} from "nano/reactive.jsx";
import Particle from "./Particle.js";

console.log({type: 'tracer'})


const MINUTE = 60 * 1000;

export default async function ShootingWord(props, children) {
    // const {words} = props;
    let {max_game_time = 10 * MINUTE, audio, level=1, min_y= 300, loop, words, is_mobile} = props;

    function Pool() {
        let last_index = 0
        const pool = []
        let len = 0
        function push(obj) {
            pool.push(obj)
            obj._i = len
            len += 1
        }
        function get() {
            for (let i = (last_index + 1) % len; i !== last_index; i = (i + 1) % len) {
                const obj = pool[i]
                if (!obj._is_active) {
                    last_index = i
                    obj._is_active = true
                    return obj
                }
            }
            return undefined
        }
        function release(obj) {
            obj._is_active = false
        }

        return {pool, push, get, release}
    }

    const word_pool = Pool()
    const particle_pool = Pool()
    const projectile_pool = Pool()

    const language = props.language || meta.value.active_learning_language;
    let sra
    // SpaceRepetitionAlgorithm(db, language, level).then(_sra => sra = _sra);
    console.log(props);


    //
    // // Orientation("landscape");
    // const game = Game();

    // 34 * 50
    const bullets_images = {
        "n": await AsyncImage("/BlueBullet.webp"),
        "v": await AsyncImage("/GreenBullet.webp"),
        "a": await AsyncImage("/YellowBullet.webp"),
        "g": await AsyncImage("/RedBullet.webp"),
    };
    const bullets_hues = {
        "n": 220,
        "v": 40,
        "a": 160,
        "g": 250,
    };

    // const bullet_img = RefImage("/bullet.webp");
    // const bullet_large_img = RefImage("/bullet_large.webp");

    const background_img = await AsyncImage("/background.webp");
    const spaceship_img = await AsyncImage("/spaceship.webp");


    const background = `rgba(0, 0, 0, 1)`;
    const explosion_radius = 3;
    const explosion_speed = 4;
    const margin = 20;
    const bottom_margin = 30; // leave some room for the system swipe button
    const player_radius = 20;
    const projectile_interval = 15;
    const word_interval = 90; // 1.5 / second
    const projectile_radius = 5;
    const projectile_speed = -3 // is_mobile ? -6 : -3;
    const font_size = 20

    const me = {
        projectiles: [],
        words: [],
        particles: [],
        meteors: [],
        bullets: [
            // Bullet({img: bullets_images["n"], x: 30, y: innerHeight - 250}),
            // Bullet({img: bullets_images["v"], x: 30, y: innerHeight - 195}),
            // Bullet({img: bullets_images["a"], x: 30, y: innerHeight - 142}),
            // Bullet({img: bullets_images["g"], x: 30, y: innerHeight - 80})
        ],
        stars: is_mobile ? [] : Array(20).fill(0).map(_ => {
            return Star({
                x: Math.random() * innerWidth,
                y: Math.random() * innerHeight,
                dx: 0,
                dy: 0.3,
                radius: Math.random() * 1 + 1.2,
                color: "white",
                background,
            });
        }),
        player: Player({
                x: window.innerWidth / 2,
                y: window.innerHeight - player_radius - margin - bottom_margin,
                // dx: 1,
                // a: 0.1,
                img: spaceship_img,
                background,
                radius: player_radius,
                color: "#889"
            }
        )
    }

    // let projectiles = [];

    let health = ref(10);
    let times_up = false;

    let bullet = "g";
    let hue = bullets_hues[bullet];


    // const language = meta.value.learning_languages[0];
    const use_language = meta.value.app_language || meta.value.native_languages[0];
    // const vocabulary_length = await fetch_vocabulary(language);
    // const all_vocabulary = await db.get(language, 1);
    // const mastered = new Set();
    // const vocabulary = all_vocabulary.filter(word => !mastered.has(word[language]));

    // const times = {};
    //
    // function loop(objects, fun, options, name) {
    //     const start = performance.now();
    //
    //     for (let i = 0, len = objects.length; i < len; i += 1) {
    //         const object = objects[i];
    //         object[fun](options);
    //     }
    //     times[name] = (times[name] || 0) + performance.now() - start;
    // }

    function init(game, N=3) {
        me.player.init(game)

        for (let i = 0; i < N; i += 1) {
            words.forEach(word => {
                const x = ~~(Math.random() * (range - word.text.length * font_size))
                // const x = 100
                const w = Word({
                    // text: ["abandon", "a", "ban", "don"],
                    dx: 0,
                    dy: 1, // is_mobile ? 2 : 1,
                    word,
                    use_language,
                    language,
                    background,
                    x,
                    y: -font_size,
                    min_y,
                    explosion_radius,
                    explosion_speed,
                    font_size,
                    color: `hsl(${~~(Math.random() * 360)}, 50%, 50%)`,
                    particle_pool,
                })
                w.init(game)
                word_pool.push(w)
            })
        }

        for (let i = 0; i < 200; i += 1) {
            particle_pool.push(new Particle({
                x: -10,
                y: -10,
                dx: (Math.random() - 0.5) * explosion_speed,
                dy: (Math.random() - 0.5) * explosion_speed,
                radius: Math.random() * explosion_radius,
                color: me.color,
                alpha: 1
            }))
        }

        for (let i = 0; i < 50; i += 1) {
            const projectile = new Projectile({
                x: -10,
                y: -10,
                dx: 0,
                dy: projectile_speed,
                radius: projectile_radius,
                color: `hsla(${hue}, 50%, 50%, 1)`,
                img: bullets_images[bullet],
                width: 22,
                height: 32,
                background,
                velocity: null
            });
            projectile_pool.push(projectile)
        }

    }
    function update(game) {
        const {ctx, frames} = game
        loop(me.stars, me, "update", "update stars");

        loop(me.meteors, me, "update", "update meteors");

        if (!is_mobile && frames % 200 === 0) {
            const r = Math.random();
            me.meteors.push(Meteor({
                x: r > 0.5 ? r * innerWidth + innerWidth : -r * innerWidth,
                y: Math.random() * innerHeight - innerHeight,
                dx: r > 0.5 ? -r * 4 : r * 8,
                dy: Math.random() * 4 + 3,
                color: "white",
                radius: Math.random() * 2
            }));
        }
        // }
        me.player.update(game);

        if (frames % projectile_interval === 0) {
            add_projectile(game);
        }

        if (frames % word_interval === 0) {
            add_word(game);
        }

        loop(me.words, me, "update", "update words");
        loop(me.projectiles, me, "update", "update projectiles");
        loop(me.particles, me, "update", "update particles");

        loop(me.words, me, "resolve_collision", "resolve word_collision");

        health.value = me.player.health;


    }

    function gc(game) {
        const {frame_time} = game

        for (let i = me.projectiles.length - 1; i >= 0; i -= 1) {
            const projectile = me.projectiles[i]
            if ( projectile.y < 0 || projectile.collide) {
                projectile._is_active = false
                me.projectiles.splice(i, 1)
            }
        }

        for (let i = me.words.length - 1; i >= 0; i -= 1) {
            const word = me.words[i]
            if (word.y > (innerHeight + word.height) || word.finished) {
                word._is_active = false
                me.words.splice(i, 1)
            }
        }

        // me.words = me.words.filter(word => {
        //     return word.y < (innerHeight + word.height) && word.finished !== true;
        // });
        for (let i = me.particles.length - 1; i >= 0; i -= 1) {
            const particle = me.particles[i]
            if (particle.alpha <= 0) {
                particle._is_active = false
                me.particles.splice(i, 1)
            }
        }
        // me.particles = me.particles.filter(function visible(particle) {
        //     return particle.alpha > 0;
        // });
        if (frame_time > 25) {
            me.stars = [];
        }
        me.meteors = me.meteors.filter(object => object.x > -innerWidth && object.x < (2 * innerWidth) && object.y < (innerHeight + margin));

    }

    function clear(game) {
        const {ctx} = game
        loop(me.stars, me, "clear", "clear star");
        loop(me.projectiles, me, "clear", "clear projectiles");
        loop(me.words, me, "clear", "clear words");

        me.player.clear(game);
    }


    function draw(game) {
        const {ctx} = game
        ctx.fillStyle = `rgba(0, 0, 0, 0.4)`;
        ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
        // ctx.fillText(fps || prev_fps, innerWidth - 100, 20);
        // ctx.drawImage(background_img, 0, 0, innerWidth, innerHeight);

        loop(me.stars, me, "draw", "draw star");
        loop(me.meteors, me, "draw", "draw meteosr");

        loop(me.bullets,me,  "draw", "draw bullets");
        loop(me.words, me, "draw", "draw words");
        loop(me.projectiles, me, "draw", "draw projectiles");
        loop(me.particles, me, "draw", "draw particles");

        me.player.draw(game);
    }



    const word_margin = 50;

    const range =  innerWidth - word_margin
    let word_index = 0
    async function add_word(game) {
        const cached = word_pool.get()

        if (cached) {
            me.words.push(cached);
        } else {
            console.log("no word left in the pool");
        }
    }

    function add_projectile() {
        const projectile = projectile_pool.get()
        if (projectile) {
            projectile.x = ~~me.player.x
            projectile.y = ~~me.player.y
            projectile.collide = false
            me.projectiles.push(projectile);
        } else {
            console.log(`not enough projectile`)
        }
    }


    const style_n = {"--h": me.bullets["n"]};
    const style_v = {"--h": me.bullets["v"]};
    const style_a = {"--h": me.bullets["a"]};
    const wealth_style = {"--content": 180, "--translate": "-80%", "--background": "orange"};
    const health_style = {"--content": health, "--translate": "-80%", "--background": "rgb(255, 59, 48)"};

    function set_bullet(pos) {
        return function onclick(event) {
            event.preventDefault();
            event.stopPropagation();
            bullet = pos;
            hue = me.bullets[pos];
            me.player.color = `hsla(${hue}, 50%, 50%, 1)`;
        };
    }

    return {clear, update, gc, draw, init}
}

export const SyllableMaster = ShootingWord;

function handleOrientation(event) {
    const gamma = event.gamma; // Rotation around the y-axis (-90 to 90 degrees)
    console.log(gamma, event);
    //
    // if (gamma > 10) {
    //     // Tilted to the right
    //     player.x += player.speed;
    // } else if (gamma < -10) {
    //     // Tilted to the left
    //     player.x -= player.speed;
    // }
    //
    // // Ensure player stays within canvas bounds
    // if (player.x < 0) {
    //     player.x = 0;
    // } else if (player.x + player.width > canvas.width) {
    //     player.x = canvas.width - player.width;
    // }
}

// Request permission for iOS 13+ devices
function requestPermission(event) {
    if (typeof DeviceOrientationEvent.requestPermission === "function") {
        console.log(`request DeviceOrientationEvent`);
        DeviceOrientationEvent.requestPermission().then(permissionState => {
            if (permissionState === "granted") {
                window.addEventListener("deviceorientation", handleOrientation);
            }
        }).catch(console.error);
    } else {
        // Non iOS 13+ devices
        console.log(`add DeviceOrientationEvent`);
        window.addEventListener("deviceorientation", handleOrientation);
    }
}