import IconTranslator from "solidjs-feather/IconTranslator.jsx";

import IconBookOpen from "solidjs-feather/IconBookOpen.jsx";
import IconSettings from "solidjs-feather/IconSettings.jsx";
import IconGitMerge from "solidjs-feather/IconGitMerge.jsx";
import IconImage from "solidjs-feather/IconImage.jsx";
import {position, current_progress, progress_percent, meta} from "../store.jsx";
import {computed, ref, watch0, watch1, after_mounted2} from 'nano/reactive.jsx'
import './Header.css'
import {ref_render} from "nano/nano.jsx";

const icons = {
    IconTranslator,
    IconBookOpen,
    IconGitMerge,
    IconSettings,
    IconImage,
}
console.log({type: 'tracer'})
export default function Header(props, children) {
    const {$shiftX} = props
    function position_color(pos) {
        return computed(() => position.value === pos ? 'var(--blue)' : '')
    }


    function position_setter(pos) {
        return function (event) {
            console.log(position.value, pos)
            if (position.value === pos) {
                //     nothing need to do
            } else {
                position.value = pos
            }
        }
    }

    const $icon_left = ref()
    const $icon_right = ref()
    watch1(meta, (new_value, old_value) => {
        const IconLeft = icons[meta.value?.left?.icon] || IconTranslator
        ref_render($icon_left, IconLeft, {})

        const IconRight = icons[meta.value?.right?.icon] || IconSettings
        ref_render($icon_right, IconRight, {})
    })

    const transform = computed(() => `translate(${$shiftX.value / window.innerWidth * 40}%, 0)`)
    return (
        <div class="header">
            <span style={{color: position_color('left'), transform, position: 'relative', display: 'inline-block'}}
                  _dom={$icon_left}
                  onclick={position_setter('left')}>
                {/*<IconTranslator backgroundColor="#556"/>*/}
            </span>
            <span style={{color:  position_color('middle'), transform}} onclick={position_setter('middle')}>
                <IconBookOpen/>
            </span>
            <span style={{color:  position_color('right'), transform}} onclick={position_setter('right')} _dom={$icon_right}>
                {/*<IconSettings/>*/}
            </span>
            <span class="progress" style={{'--progress': progress_percent}} ></span>
        </div>
    )
}