export default function Star(options) {
    const me = {
        alpha: 0.6,
        min_alpha: 0.2,
        d_alpha: (Math.random() - 0.5) * 0.01,
        ...options,
        shinning: options.radius * 1.2,
        shinning_width: 0.5,
        update,
        draw,
        clear
    };

    function update(game) {
        me.x = me.x + me.dx;
        me.y = me.y + me.dy;
        if (me.y > innerHeight) {
            me.y = -me.radius;
        }
        me.alpha = me.alpha + me.d_alpha;
        if (me.alpha < me.min_alpha || me.alpha > 1) {
            me.d_alpha = -me.d_alpha;
        }
    }

    function clear(game) {
        const {ctx} = game
        // if (me.alpha > 0.8) {
        //     ctx.beginPath();
        //     ctx.strokeStyle = me.background;
        //     ctx.strokeWidth = me.shinning_width;
        //     ctx.moveTo(me.x - me.shinning, me.y);
        //     ctx.lineTo(me.x + me.shinning, me.y);
        //     ctx.moveTo(me.x, me.y - me.shinning);
        //     ctx.lineTo(me.x, me.y + me.shinning);
        //     ctx.stroke();
        // } else {
        //     ctx.beginPath();
        //     ctx.fillStyle = me.background;
        //     ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
        //     ctx.fill();
        // }
    }

    function draw(game) {
        const {ctx} = game

        if (me.alpha > 0.8) {
            ctx.beginPath();
            ctx.strokeStyle = me.color;
            ctx.strokeWidth = me.shinning_width;
            ctx.moveTo(me.x - me.shinning, me.y);
            ctx.lineTo(me.x + me.shinning, me.y);
            ctx.moveTo(me.x, me.y - me.shinning);
            ctx.lineTo(me.x, me.y + me.shinning);
            ctx.stroke();
        } else {

            ctx.save();
            ctx.globalAlpha = me.alpha;
            ctx.beginPath();
            ctx.fillStyle = me.color;

            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();

            ctx.restore();
        }
    }

    return me;
}