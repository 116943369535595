import For from 'nano/For.jsx'
import Show from 'nano/Show.jsx'
import IconPlay from "solidjs-feather/IconPlay.jsx";
import IconCornerUpLeft from "solidjs-feather/IconCornerUpLeft.jsx";
import IconPause from "solidjs-feather/IconPause.jsx";
import {computed, ref, on_mounted} from 'nano/reactive.jsx'
import api from "../api.jsx";
let index = 1
const transcript_id = `transcript_${index}`

const NAME = 'StoryPage'
console.log({type: 'tracer'})

function Chapter(props, children) {
    const {story, $playing, sections, $playing_story, $playing_chapter, i} = props
    index = index + 1

    const audio_id = `audio_${index}`

    const playing = ref(false)
    const play_started = ref(false)
    const check_interval = 250;
    let interval_id;

    const transcript = ref([])

    async function onclick(event) {
        if (transcript.value.length === 0) {
            transcript.value = await api.get(story.transcript)
        }
        if ($playing_story.value === i) {

        } else {
            $playing_story.value = i

            const $el = document.getElementById(audio_id)
            play_started.value = true
            // it's me playing
            if (!playing.value) {
                // other is playing
                if ($playing.value) {
                    $playing.value.pause()
                }
                playing.value = true
                $playing.value = $el
                $el.play()
            }
        }
    }
    function pause(event) {
        event.stopPropagation()
        play_started.value = false
        if (playing.value) {
            const $el = document.getElementById(audio_id)
            $el.pause()
            playing.value= false

            $playing.value = undefined
        }

    }
    function rewind(event) {
        event.stopPropagation()

        if (playing.value) {
            const $el = document.getElementById(audio_id)

            const last_section = sections.value.at(-1)
            if (last_section) {
                $el.currentTime = last_section.start
            }
        }
    }


    const height = computed(() => playing.value ? "100%" : 'auto')
    console.log(playing.value, height.value)
    const style = {height, color: "var(--blue)"}
    const $play = <div class="flex_center" style={style}><IconPlay/></div>
    console.log($play)

    function onplay(event) {
        $playing.value = true
        $playing_chapter.value = i
        sections.value = []
        const $transcript = document.getElementById(transcript_id)
        interval_id = setInterval(() => {
            const currentTime = event.target.currentTime;
            const currentSegment = transcript.value.findIndex(segment => currentTime >= segment.start && currentTime < segment.end);

            if (currentSegment !== -1) {
                const sliced = transcript.value.splice(0, currentSegment + 1)
                sections.value.push(...sliced)
                sections.trigger()
                requestAnimationFrame(() => {
                    $transcript.scrollTop = $transcript.scrollHeight;
                })
            }
        }, check_interval);
    }

    function onpause(event){
        $playing_chapter.value = undefined
        clearInterval(interval_id);
    }

    function onended(event) {
        clearInterval(interval_id);
    }

    const display = computed(() => {
        if ($playing_chapter.value === undefined ) {
            return 'flex'
        } else if ($playing_story.value === i ) {
            return 'flex'
        } else {
            return "none"
        }
    })

    return (
            <div style={{display, gap: "20px", "justify-content": "left", "align-items": "center"}} onclick={onclick}>
                <Show when={play_started} >
                    <div class="flex_center" style="height: 100%; color: var(--blue)" onclick={rewind}>
                        <IconCornerUpLeft/>
                    </div>
                </Show>

                <div>{story.title} </div>
                <div style="flex: 1"></div>
                <Show when={playing} fallback={$play}>
                    <div class="flex_center" style="height: 100%; color: var(--blue)" onclick={pause}>
                        <IconPause/>
                    </div>
                </Show>

                <audio src={story.audio} id={audio_id} preload="none" onplay={onplay} onpause={onpause} onended={onended}></audio>
            </div>
        )
}

export default function StoryPage(props={}, children=[]) {
    const {data} = props
    const $playing = ref()
    const story = data[0]
    const sections = ref([])
    const $playing_story = ref()
    const $playing_chapter = ref()

    function rewind(event) {

    }
    function pause(event) {

    }


    const page_style = {
        height: "100%",
        gap: "20px",
        width: '100%',
        left: 0,
        top: 0,
        "justify-content": "left",
        "align-items": "center",
        "padding-top": "var(--header-height, 64px)",
        "padding-bottom": "var(--footer-height, 100px)"
    }

    return (
        <div style={page_style}>
            <div style="height: 100%; padding: 1rem; display: flex; flex-direction: column; gap: 10px;">
                <For _each={data}>{(story, i) => {
                    return (
                            <Chapter story={story} $playing={$playing} sections={sections} $playing_story={$playing_story}
                                     $playing_chapter={$playing_chapter} i={i}></Chapter>
                        )
                    // return <div style="display: flex; gap: 20px; justify-content: left; align-items: center" onclick={onclick}>
                    //
                    //     <div>{story.title} </div>
                    //     <div style="flex: 1"></div>
                    //     <div class="flex_center" style="color: var(--blue)"><IconPlay/></div>
                    //
                    //     {/*<audio src={story.audio} id={audio_id} onplay={onplay} onpause={onpause} onended={onended}></audio>*/}
                    // </div>

                }}</For>
            </div>

            <Show when={computed(() => $playing_chapter.value !== undefined)}>
                <div style="position: absolute; left: 0; top: 120px; height: calc(100% - 135px); width: 100vw; padding: 10px; background: white; overflow-y: auto; scroll-behavior: smooth; "
                     id={transcript_id}>
                    <For _each={sections}>{(section, i) => {
                        return <p>{section.text}</p>
                    }}</For>
                </div>
            </Show>
        </div>
    )
}