import IconAlertCircle from "solidjs-feather/IconAlertCircle.jsx";

export default function Tip(props, children) {
    const style = {
        display: "inline-flex",
        "justify-content": "center",
        "align-items": "center",
        color: 'var(--blue)'
    }
    return (
        <span style={style}>
            <span style="display: inline-block; padding: 0 5px;"> TIP </span>
            <IconAlertCircle/>
        </span>
    )
}