async function blob_to_string(blob) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onload = function () {
                // 'result' contains the string representation of the blob data
                const string = reader.result;
                resolve(string);
            };

            reader.readAsText(blob);
        } catch (error) {
            reject(error);
        }
    });
}

function ArrayBuffer_to_string(buf) {
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(buf);
}

async function to_string(message) {
    if (typeof message === "string") {
        return message;
    } else if (message instanceof Blob) {
        return await blob_to_string(message);
    } else if (message instanceof ArrayBuffer) {
        return ArrayBuffer_to_string(message);
    } else {
        console.log("unknown yet", message);
        return String(message);
    }
}

export function to_base64(str){
    if (!str) return '';
    if (typeof Buffer !== 'undefined') {
        return Buffer.from(str).toString('base64');
    }

    if (typeof btoa !== 'undefined') {
        let encoder = new TextEncoder();
        let data = encoder.encode(str);
        return btoa(String.fromCharCode(...new Uint8Array(data.buffer)));
    }
}

export function from_base64(encoded) {
    if (!encoded) return '';
    if (typeof Buffer !== 'undefined') {
        return Buffer.from(encoded, 'base64').toString('utf8');
    }

    if (typeof atob !== 'undefined') {
        // Browser environment
        let binaryString = atob(encoded);
        let bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        let decoder = new TextDecoder();
        return decoder.decode(bytes);
    }
}

export function base64_to_object(encoded, default_value) {
    try {
        const json = from_base64(encoded[0] === "#" ? encoded.slice(1) : encoded);
        return JSON.parse(json);
    } catch (error) {
        console.log(error, encoded);
        return default_value;
    }
}