import {effect, is_ref, watch} from "./reactive.jsx";
import {
    add_event_listener,
    add_element,
    enable_class,
    remove_element,
    remove_event_listener,
    sanitize,
    set,
    set_attribute, set_style
} from "./dom.jsx";
import For from "./For.jsx";

import tags from "minimals/common/tags.js";

// import Logger from "minimals/common/logger.js";
import Show from "./Show.jsx";
import {dummy} from "./lib/_.js";

// const logger = Logger(import.meta);
const logger = {
    log(...args) {

    },
    trace(...args) {

    },
    debug(...args) {

    },
    error(...args) {

    },
}
console.log("nano");

window.onerror = function onerror(msg, url, line, col, error) {
    console.log(msg, url, line, col, error);
};

// window.addEventListener("error", event => {
//     console.log(event);
//     event.preventDefault();
// });

const svgNS = "http://www.w3.org/2000/svg";
function create_svg_or_dom_element(tag, ns) {
    try {
        // both HTML and SVG has <a> tag
        // in that case we use _ns to distinguish it
        // console.log(tag)
        let $el = undefined
        if (tags.svg.includes(tag) || ns === svgNS) {
            // console.log(`<${tag} ns=${svgNS}/>`)
            $el = document.createElementNS(svgNS, tag);
        } else if (tags.html.includes(tag)) {
            $el =  document.createElement(tag);
        } else {
            $el =  document.createTextNode(tag);
        }
        // console.log($el)
        return $el
    } catch (error) {
        // logger.error(error);
        return document.createTextNode(tag);
    }
}

function js_to_css_case(str) {
    return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

// some attributes are in camelCase not like other attributes as dash-case
const camelCases = new Set(["contentEditable", "markerWidth", "markerHeight", "markerUnits", "viewBox"]);

function option_js_to_css_case(key) {
    return camelCases.has(key) ? key : js_to_css_case(key);
}

// use class as attribute is easier to read then className
// while webstorm always automatically change to className even when copy
// so as the before find a solution, we also support className
// https://youtrack.jetbrains.com/issue/WEB-28691/Allow-editing-of-Emmet-Zen-shortcuts-for-JSX-to-change-className-to-class
const key_map = (function () {
    const maps = {
        "className": "class",
        "onClick": "onclick"
    };
    return (key) => {
        return option_js_to_css_case(maps[key] || key);
    };
}());


function handle_event_prop([key, callback, options], $el) {
    const event = key_map(key);
    // if its a function, then it is expected on event handler
    // in case of error, might forget to call the function to return a value

    // lifecycle hook
    if (event.startsWith("on_")) {
        switch (event) {
            case 'on_mounted':
                return  callback($el)
            case 'on_after_mounted':
                return requestAnimationFrame(() => callback($el))
            default:
                console.error(`${event} not supported yet`)
        }
    }
    else if (event.startsWith("on")) {
        const event_name = event.slice(2);
        add_event_listener($el, event_name, callback, options);
    } else {
        add_event_listener($el, event, callback, options);
    }
}

function handle_class_prop(val, $el) {
    if (is_ref(val)) {
        effect(() => {
            if (typeof val.value === "string") {
                $el.className = val.value;
            } else {
                console.log("not supported", val.value);
            }
        });
    } else {
        for (const clazz of Object.keys(val)) {
            const enabled = val[clazz];
            if (is_ref(enabled)) {
                effect(() => {
                    enable_class($el, enabled.value, clazz);
                });
            } else if (typeof enabled === "boolean") {
                enable_class($el, enabled, clazz);
            } else if (typeof enabled === "string") {
                enable_class($el, true, enabled);
            } else if (typeof enabled === "function") {
                effect(() => {
                    enable_class($el, enabled(), clazz);
                });
            } else if (enabled === undefined) {
                logger.debug({clazz, enabled});
            } else {
                logger.error({type: "not supported", clazz, enabled});
            }
        }
    }
}

function handle_style_prop(val, $el) {
    try {
        if (is_ref(val)) {
            effect(() => {
                const type = typeof val.value
                if (type  === "object" ) {
                    Object.entries(val.value).forEach(([name, style]) => {
                        set_style($el, name, is_ref(style) ? style.value : style);
                    });
                } else if (type === "string") {
                    $el.style.cssText = val
                    console.error("wrong code, style shall be an object");
                }
            });
            const styles = val.value;
        } else {
            Object.entries(val).forEach(([name, style]) => {
                if (is_ref(style)) {
                    watch(style, (new_value, old_value) => {
                        if (new_value !== old_value) {
                            set_style($el, name, style.value);
                        }
                    });
                    // effect(() => {
                    //     set_style($el, name, style.value);
                    // });
                } else {
                    set_style($el, name, style);
                }
            });
        }
    } catch (error) {
        console.log({error, val, $el});
    }
}

function handle_data_prop(key, val, $el) {
    const name = key.split("-").at(-1);
    if (is_ref(val)) {
        effect(() => {
            $el.dataset[name] = val.value;
        });
    } else {
        $el.dataset[name] = val;
    }
}

const svg_attributes = new Set("x y x1 y1 x2 y2 cx cy".split(" "));

function handle_normal_attribute(key, val, $el) {
    // logger.debug({type: "setAttribute", key, val});
    try {
        if (svg_attributes.has(key)) {
            if (isNaN(val)) {
                console.error(key, val, $el);
                val = "111.11";
            }
            set_attribute($el, key, val);

        } else {
            set_attribute($el, key, val);
        }
    } catch (error) {
        console.log({$el, key, val, error});
    }
}

function handle_internal_prop(key, val, $el) {

    $el[key] = val;
    if (["_in", "_inout"].includes(key)) {
        const {
            ease = "ease-in-out",
            duration = 300,
            delay = 0,
            opacity = 0,
            transform = "translate(20%)",
        } = val;
        set_style($el, "transition", `all ${duration}ms ${ease} ${delay}ms`);
        set_style($el, "opacity", opacity);
        set_style($el, "transform", transform);

        const _in = val._in || val._inout;

        function trigger_transition() {
            logger.trace("transition start");
            set_style($el, "opacity", 1);
            set_style($el, "transform", "");
        }

        if (typeof _in === "object") {
            effect(() => {
                if (_in.value) {
                    trigger_transition();
                }
            });
        } else if (_in === true) {
            trigger_transition();
        }
    }

    if (["_out", "_inout"].includes(key)) {

        const _out = val._out || val._inout;

        function trigger_transition() {
            const {
                ease = "ease-in-out",
                duration = 300,
                delay = 0,
                opacity = 0,
                transform = "translate(20%)",
            } = val;
            set_style($el, "transition", `all ${duration}ms ${ease} ${delay}ms`);
            set_style($el, "opacity", opacity);
            set_style($el, "transform", transform);
            add_event_listener($el, "transitionend", ontransitionend);
        }

        async function ontransitionend() {
            remove_event_listener($el, "transitionend", ontransitionend);

            // using _out/_inout already define how to transition out
            // so the default effect in remove_element shall not be applied
            const effect = false
            await remove_element($el, effect);
        }

        if (typeof _out === "object") {
            effect(() => {
                if (_out.value) {
                    trigger_transition();
                }
            });
        } else if (_out === true) {
            trigger_transition();
        }
    }

    // _draw shall only used for path element
    // otherwise, it will not have any effect
    if (key === `_draw`) {
        if ($el.tagName === `path`) {
            const {duration = 300, delay = 0} = val || {}
            const pathLength = $el.getTotalLength()
            $el.style[`stroke-dasharray`] = pathLength;
            $el.style[`stroke-dashoffset`] = pathLength;

            $el.style.transition = `stroke-dashoffset ${duration}ms ease ${delay}ms`;

            setTimeout(() => {
                $el.style[`stroke-dashoffset`] = 0;
            }, delay);
        } else {
            console.warn(`_draw shall only used for path element`, $el)
        }
    }

    if (key === "_flip") {
        logger.trace("flip");
    }

    // _ref is to be used by the element need be referenced at multiple place, but not only at the event listener
    // currentTarget: the element event is listened on
    // currentTarget: the element event which start firing the event
    // const {target, currentTarget} = event
    if (key === '_ref') {
        val.value = $el
    }

    // TODO, why this does not work
    // if (key === '$element') {
    //     val.value = $el
    // }
}

const boolean_attributes = new Set([
    "allowfullscreen",
    "async",
    "autofocus",
    "autoplay",
    "checked",
    "controls",
    "default",
    "defer",
    "disabled",
    "enabled",
    "formnovalidate",
    "inert",
    "ismap",
    "itemscope",
    "loop",
    "multiple",
    "muted",
    "nomodule",
    "novalidate",
    "open",
    "playsinline",
    "readOnly",
    "required",
    "reversed",
    "selected"]);

function handle_boolean_prop(key, val, $el) {
    if (is_ref(val)) {
        effect(() => {
            const present = val.value === true || String(val.value).toLowerCase() === true;
            if (present) {
                $el[key] = present;
            } else {
                $el.removeAttribute(key);
            }
        });
    } else {
        const present = val === true || String(val).toLowerCase() === true;
        console.log(present);
        $el[key] = present;
    }
}


function handle_prop([key, val], $el) {
    const val_type = typeof val;
    if (key === "_html") {
        if (is_ref(val)) {
            effect(() => {
                set($el, "innerHTML", val.value);
                // $el.innerHTML = val.value;
            });
        } else {
            // $el.innerHTML = val;
            set($el, "innerHTML", val);
        }
        return;
    }
    if (key === "_dom") {
        if (is_ref(val)) {
            requestAnimationFrame(() => {
                return effect(() => {
                    const dom = val.value;
                    const children = [...$el.children];
                    const child = children[0];
                    const name = $el.dataset['name']
                    try {
                        if (dom) {

                            if (child) {
                                // $el.replaceChild(dom, child);
                                child.replaceWith(dom);
                            } else {
                                $el.appendChild(dom);
                            }
                        } else {
                            child && child.remove();
                        }
                    } catch (error) {
                        console.error({error, dom, child, $el, children});
                    }
                });
            });
        } else if (val instanceof Element) {
            requestAnimationFrame(() => {
                return $el.appendChild(val);
            });
        } else if (Array.isArray(val)) {

        } else {
            return console.log("it shall be either ref or an Element");
        }
    }
    if (key === "_idom") {
        if (is_ref(val)) {
            requestAnimationFrame(() => {
                return effect(() => {
                    const dom = val.value;
                    if (dom) {
                        $el.replaceWith(dom);
                    }
                });
            });
        } else if (val instanceof Element) {
            console.log($el, val);
            requestAnimationFrame(() => {
                $el.replaceWith(val);
                console.log($el);
            });

            // Create a new element to replace it

            return $el;
        } else if (Array.isArray(val)) {

        } else {
            return console.log("it shall be either ref or an Element");
        }
    }

    try {
        if (key === "_onmounted") {
            const handler = val;
            return requestAnimationFrame(() => requestAnimationFrame(() => requestAnimationFrame(() => handler($el))));
        }
    } catch (error) {
        console.log(error, key, val);
    }

    if (["_slide_left"].includes(key)) {

    }

    if (key === "_naturalViewBox") {
        // requestAnimationFrame(() => {
        return add_event_listener(window, "resize", function (event) {
            const rect = $el.getBoundingClientRect();
            set_attribute($el, "viewBox", `0 0 ${rect.width} ${rect.height}`);
        });
        // });
    }

    if (key === "_boundingClientRect") {
        return requestAnimationFrame(() => {
            const rect = $el.getBoundingClientRect();
            console.log(rect);
            val.value = rect;
        });
    }

    if (key === "_show") {
        return effect(() => {
            console.log("_show", val);
            const _show = is_ref(val) ? val.value : val;
            const v = _show === true ? "visible" : "unset";
            set_style($el, "visibility", v);
        });
    }
    if (key === "_hide") {
        return effect(() => {
            console.log("_hide", val);
            const _hide = is_ref(val) ? val.value : val;
            set_style($el, "visibility", _hide ? "hidden" : "unset");
        });
    }

    if (["_display", "_height"].includes(key)) {
        return effect(() => {
            console.log("_show", val);
            const v = is_ref(val) ? val.value : val;
            set_style($el, key.slice(1), v);
        });
    }

    if (["$"].includes(key[0])) {
        return;
    }

    if (val === undefined) {
        // logger.debug({message: "check why prop value is undefined", key, val, props});
        return;
    }

    if (val_type === "function") {
        handle_event_prop([key, val, {}], $el);
    } else if (Array.isArray(val) && typeof val[0] === "function") {
        const [listener, options = {}] = val;
        handle_event_prop([key, listener, options], $el);
    } else if (val_type === "object" && val.handler) {
        const event_listener = val.handler;
        const event = key_map(key);
        if (key === "onintersecting") {
            const observer = (fn) => {
                return new IntersectionObserver(entries => entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        fn(entry, $el);
                    }
                }), {threshold: .8});
            };
            observer(event_listener).observe($el);
        } else {
            handle_event_prop([key, event_listener], $el);
        }
    } else if (boolean_attributes.has(key)) {
        handle_boolean_prop(key, val, $el);
    } else if (["class", "className"].includes(key) && val_type === "object") {
        handle_class_prop(val, $el);
    } else if (key === "style" && val_type === "object") {
        handle_style_prop(val, $el);
    } else if (key === "style" && val_type === "string") {
        $el.style.cssText = val
    } else if (key.startsWith("data-")) {
        handle_data_prop(key, val, $el);
    } else if (key.startsWith("_")) {
        handle_internal_prop(key, val, $el);
    } else if (key === "editable") {
        $el.textContent = val.value || "";
        // const contentEditable = ref(false)
        $el.onkeydown = (event) => {
            if (event.key === "Enter") {
                // Prevent the default behavior (inserting a line break)
                event.preventDefault();
                if (val.value === $el.textContent) {
                    // does not need to reset
                } else {
                    val.value = $el.textContent;
                }

                $el.blur();
            }
        };

        watch(val, (old_value, new_value) => {
            if (val) {
                if (val.value === $el.textContent) {
                    // does not need to reset
                } else {
                    $el.textContent = val.value;
                }
            }
        });

        $el.onclick = (event) => {
            console.log(event);
            if ($el.contentEditable === "true") {

            } else {
                $el.contentEditable = "true";
            }
            event.stopPropagation();
        };
        $el.onblur = (event) => {
            console.log(event);
            $el.contentEditable = false;
            if (val.value === $el.textContent) {
                // does not need to reset
            } else {
                val.value = $el.textContent;
            }

            $el.blur();
        };

    } else if (key === "editables") {
        const lines = (val.value || "").split("\n");
        lines.forEach(line => {
            const $line = document.createElement("div");
            $line.textContent = line;
            $el.appendChild($line);
        });
        // const contentEditable = ref(false)
        $el.onkeydown = (event) => {
            if (event.key === "Enter") {
                // Prevent the default behavior (inserting a line break)
                // event.preventDefault();
                const contents = [...$el.childNodes].map(e => e.textContent).join("\n");
                val.value = contents;
            }
        };
        $el.onclick = (event) => {
            $el.contentEditable = true;
        };
        $el.onblur = (event) => {
            $el.contentEditable = false;
            const contents = [...$el.childNodes].map(e => e.textContent).join("\n");
            val.value = contents;
        };

    } else if (key === "model") {
        effect(() => {
            const k = key_map(key);
            try {
                const v = is_ref(val) ? val.value : val;
                if ($el.type === "checkbox") {
                    set($el, "checked", v);
                }
                // NOTE: the value attribute has to present before model
                // otherwise the value is still not assigned yet
                else if ($el.type === "radio") {
                    if ($el.value === v) {
                        $el.checked = true;
                    }
                } else {
                    if (v !== undefined) {
                        set($el, "value", v);
                    }
                }
            } catch (error) {
                logger.error({k, val, error});
            }
        });

        add_event_listener($el, "input", (event) => {
            // TODO, this will trigger again the effect as well in the previous section
            //  it can also not use untrack otherwise the other part will not be notified
            if ($el.type === "checkbox") {
                val.value = sanitize(event.target.checked);
            } else if ($el.type === "radio") {
                if ($el.checked) {
                    val.value = sanitize($el.value);
                }
            } else {
                val.value = sanitize(event.target.value);
            }
        });
    } else if (is_ref(val)) {
        effect(() => {
            set_attribute($el, key, val.value);
        });

        // $el.addEventListener("input", (event) => {
        //     if (event.target.type === "checkbox") {
        //         val.value = event.target.checked;
        //     } else {
        //         val.value = event.target.value;
        //     }
        // });

    } else if (["string", "number", "boolean"].includes(val_type)) {
        handle_normal_attribute(key, val, $el);
    } else {
        logger.error({type: "not supported", key, val});
    }
}

function handle_props(props, $el) {
    Object.entries(props || []).forEach(([key, val]) => {
        // console.log(event, callback);
        try {
            handle_prop([key, val], $el);
        } catch (error) {
            console.log({key, val, $el, error});
        }
    });
}

function is_svg_text_element($el) {
    return $el instanceof SVGElement && $el.tagName === "text";
}

const iso_length = "2023-10-18 12:00:00".length;

function object_to_string(object) {
    if (object instanceof Date) {
        return object.toISOString().slice(0, iso_length).replace("T", " ");
    } else {
        return object.toString();
    }
}

function handle_child(children, child, $parent, $previous) {
    if (child === undefined || child === null) {
        console.log("child is empty", children, $parent, $previous);
    } else {
        let $child;
        try {
            if (["string", "number"].includes(typeof child)) {
                if (children.length === 1) {
                    $parent.textContent = child;
                } else {
                    $child = _create(child);
                    add_element($parent, $child).then(dummy);
                }
            } else if (child instanceof Element) {
                add_element($parent, child).then(dummy);
            } else if (Array.isArray(child)) {
                // console.log(child, typeof child);
                const [tag_or_fn, props, ...grand_children] = child;
                const props_with_context = {...props, $parent, $previous};
                $child = _create(tag_or_fn, props_with_context, ...grand_children);
                if ($child) {
                    logger.trace({child$: $child, child});
                    Array.isArray($child) ? $child.forEach($c => add_element($parent, $c)) : add_element($parent, $child);
                }
            } else if (typeof child === "object") {
                if (is_ref(child)) {
                    effect(() => {
                        $parent.textContent = child.value || '';
                    });
                } else {
                    $parent.textContent = object_to_string(child);
                }
            } else {
                console.error({child, error: "not supported"});
            }
        } catch (error) {
            console.error({error, child, $parent});
        }
        return $child;
    }
}

function handle_children(children, $parent) {
    let $previous = undefined;
    if (is_svg_text_element($parent)) {
        effect(() => {
            const text = children.map(child => is_ref(child) ? child.value : child).join("");
            $parent.textContent = text;
        });

    } else {

        children.forEach(child => {
            $previous = handle_child(children, child, $parent, $previous);
        });

    }
}

function create_transition_element(tag, props, children) {
    const $el = create_svg_or_dom_element(tag, props["_ns"]);

    handle_children(children, $el);
    handle_props(props, $el);


    logger.trace($el);
    return $el;
}

export function _create(...args) {

    const args_type = typeof args;
    logger.trace({args_type, args});
    if (Array.isArray(args)) {

        const [tag_or_fn, props, ...children] = args;
        const props_with_context = props || {};
        if (typeof tag_or_fn === "function") {
            const fn = tag_or_fn;
            // JSX use fn(tag, props, children)
            // while the fn itself already determine the type
            // we just need to pass props and children
            // $parent and $previous is used in For.jsx loop
            const el = Render(fn, props_with_context, children);
            // console.log(el);
            return el;
        } else if (typeof tag_or_fn === "string") {
            const tag = tag_or_fn;
            return create_transition_element(tag, props_with_context, children);
        } else {
            return args;
        }

    } else if (["string", "number"].includes(args_type)) {
        return document.createTextNode(args);
    } else if (args instanceof Element) {
        return args;
    } else if (args_type === "object") {
        const el = document.createTextNode(args);
        effect(() => {
            // console.log(args, args.value);
            el.textContent = args.value;
        });
        return el;
    } else if (args_type === "function") {
        logger.trace("function");
        const $el = document.createTextNode("placeholder");
        const fn = args;
        effect(() => {
            $el.textContent = fn();
        });
        return $el;
    } else if (args === undefined || args === null) {
        return document.createTextNode(String(args));
    } else {
        logger.error("unknown", args);
    }
}

export function _create_element(...args) {
    return _create(...args);
}

// use two stages
// stage1: convert jsx to array style args
// stage2: convert args to HTML element
//
// why:
// use two stages can create parent element first, then go down to children element
// in this way we can pass the parent when create children
export function create_element(...args) {
    logger.trace(args);
    return args;
}

const debug = true;

export async function ref_render($dom, fn, contexts = {}) {
    const {
        props = {},
        dataset = {},
        children = [],
        ...others
    } = contexts;

    if ($dom) {
        $dom.value = await async_render(fn, props, children);
        $dom.value.dataset['component'] = fn.name
        Object.entries(others).forEach(([key, value]) => {
            $dom.value[key] = value;
        });
        Object.entries(dataset).forEach(([key, value]) => {
            $dom.value.dataset[key] = value;
        });

    } else {
        // ignore if $dom is undefined
        // console.log()
    }
}

// NOT tested yet

export async function lazy_render(filename, props = {}, children) {
    const Component = await import(/* @vite-ignore */filename)
    return await async_render(Component, props, children)
}

export async function async_render(fn, props = {}, children) {

    if ([For, Show].includes(fn)) {
        window.React = {createElement: _create_element};
        window.h = _create_element;

        return await fn(props, children);
    } else {
        window.React = {createElement: create_element};
        window.h = create_element;
        const args = await fn(props, children);
        // logger.trace(JSON.stringify(args,
        //     function (key, value) {
        //         return (typeof value === "function") ? value.toString() : value;
        //     }, 4));
        window.React = {createElement: _create_element};
        window.h = _create_element;

        // const [tag_or_fn, _props, ..._children] = args;
        // return _create(tag_or_fn, {...props, ..._props}, ..._children);

        if (args instanceof Element) {
            return args;
        } else if (Array.isArray(args)) {
            const [tag_or_fn, _props = {}, ..._children] = args;
            if (!tag_or_fn && !_props) { // both tag and props are not present, then it's Fragement
                return _children.map(child => _create(...child));
            } else {
                return _create(tag_or_fn, {..._props}, ..._children);
            }
        } else {
            console.log({message: 'Might missing return', fn, props, children, args});
        }
    }
}

export function create_or_render(args) {
    return typeof args[0] === 'string' ?  _create(...args) : Render(...args)
}

export function Render(fn, props = {}, children = [], trace = false) {
    props = props || {}
    children = children || []
    if ([For, Show].includes(fn)) {
        window.React = {createElement: _create_element};
        window.h = _create_element;

        return fn(props, children);
    } else {
        window.React = {createElement: create_element};
        window.h = create_element;
        // is Array means it's for For (d, i) case
        const args = Array.isArray(props) ? fn(...props, children) : fn(props, children);
        trace && logger.trace(JSON.stringify(args,
            function (key, value) {
                return (typeof value === "function") ? value.toString() : value;
            }, 4));
        window.React = {createElement: _create_element};
        window.h = _create_element;

        // const [tag_or_fn, _props, ..._children] = args;
        // return _create(tag_or_fn, {...props, ..._props}, ..._children);
        if (args instanceof Element) {
            return args;
        } else if (Array.isArray(args)) {
            const [tag_or_fn, _props = {}, ..._children] = args;
            if (!tag_or_fn && !_props) { // both tag and props are not present, then it's Fragement
                return _children.map(child => _create(...child));
            } else {
                return _create(tag_or_fn, {..._props}, ..._children);
            }
        } else if (args instanceof Promise) {
            args.then(result => {
                console.log({message: 'Might missing return', result, fn, props, children, args});
            });
        } else if (args === 'placeholder') {
            // just placeholder
        } else {
            console.log({message: 'Might missing return', fn, props, children, args});
        }
    }
}

export function mount0($el, $mounting) {
    if ($mounting) {
        $mounting.appendChild($el);
    } else {
        // add all to the beginning of <body></body>
        // we assume there might be at least one child
        const $first = document.body.firstChild;
        $first.before(...$el.childNodes);
    }
}

export function router(routes) {

}

export function mount($el, $mounting) {

    if (typeof $el?.then === "function") {
        // for async_render
        $el.then($$el => mount0($$el, $mounting));
    } else {
        if ($mounting) {
            $mounting.appendChild($el);
        } else {
            // add all to the beginning of <body></body>
            // we assume there might be at least one child
            const $first = document.body.firstChild;
            $first.before(...$el.childNodes);
        }
    }
}


export function render_mount_modal(fn, props, children) {
    const $portal_content = Render(fn, props, children);
    const $current = document.getElementById("modal_content");
    const $portal = document.getElementById("modal");
    $current.replaceWith($portal_content);
    $portal.style.display = "flex";
}

export function render_mount_dialog(Dialog, props, children) {
    const $new_dialog = Render(Dialog, props, children);
    const $dialog = document.getElementById("dialog");
    $dialog.replaceWith($new_dialog);

    requestAnimationFrame(function wait_dialog_initialized() {
        try {
            const $dialog = document.getElementById("dialog");
            $dialog.classList.remove("running");
            $dialog.classList.add("open");
            $dialog.showModal();
        } catch (error) {
            console.error(error, $dialog);
        }
    });
}