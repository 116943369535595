// https://youtu.be/s06Z_e8ac0Y?si=bGNaxrCmJUxANHtk
// https://www.youtube.com/watch?v=8q_05PUYv1o
//      https://codepen.io/cassie-codes/full/xxperqo
export function flip(first, last, {transformOrigin = "top left", duration = 1000, easing = "ease-in-out"} = {}) {
    if (first && last) {
        const dx = first.x - last.x;
        const dy = first.y - last.y;
        const scale_x = first.width / last.width;
        const scale_y = first.height / last.height;
        console.log({dx, dy, scale_x, scale_y});
        const transform = `translate(${dx}px, ${dy}px) scale(${scale_x}, ${scale_y})`;
        console.log("transform", transform);
        const keyframes = [{
            transformOrigin,
            transform
        }, {
            transformOrigin,
            transform: "none",
        }];
        const options = {
            duration,
            easing,
            fill: "both"
        };
        return [keyframes, options];
    } else {
        return [undefined, undefined];
    }
}

export function grow(first, last) {
    const keyframes = [{
        height: "1px"

    }, {
        height: `${last.height}px`
    }];
    const options = {duration: 100, easing: "ease-in"};
    return [keyframes, options];
}

export function shrink(first, last) {
    const keyframes = [{
        height: `${first.height}px`
    }, {
        height: "1px"
    }];
    const options = {duration: 100, easing: "ease-in"};
    return [keyframes, options];
}

export function fade_in(first, last, {duration = 300, easing = "ease-out"}) {
    const keyframes = [{
        opacity: 0,
    }, {
        opacity: 1,
    }];
    const options = {duration, easing};
    return [keyframes, options];
}

// fly up in        : translate(0,      -100px)
// fly down in      : translate(0,      100px)
// fly right in     : translate(-100px, 0)
// fly left in      : translate(100px,  0)
// fly top left in  : translate(-100px, -100px)
export function fly_in(first, last, {transform = "translateY(50px)", duration = 300, easing = "ease-out"}) {
    const keyframes = [{
        opacity: 0,
        transform
    }, {
        opacity: 1,
        transform: "translate(0, 0)"
    }];
    const options = {duration, easing};
    return [keyframes, options];
}

// fly up out        : translate(0,      -100px)
// fly down out      : translate(0,      100px)
// fly right out     : translate(-100px, 0)
// fly left out      : translate(100px,  0)
// fly top left out  : translate(-100px, -100px)
export function fly_out(first, last, {transform = "translate(0, -50px)", duration = 300, easing = "ease-out"}) {
    const keyframes = [{
        opacity: 1,
        transform: "translate(0, 0)"
    }, {
        opacity: 0,
        transform: transform
    }];
    const options = {duration, easing};
    return [keyframes, options];
}

export default {
    flip, shrink, fly_in, fly_out, fade_in, grow
};