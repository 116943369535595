import {_create, create_element, create_or_render, Render} from "./nano.jsx";
import {is_ref, ref, not_ref, on_mounted, untrack, watch, watch0} from "./reactive.jsx";

console.log({type: "tracer"});

// Show is using inline display: it
export default function If(props, children=[]) {
    let {when, fallback, $parent, description} = props


    const $last = $parent.lastElementChild

    // console.log(when, fallback, $parent, children)
    const $fallback = fallback && untrack(() => {
        return _create(...fallback)
    });
    let fallback_value = $fallback && window.getComputedStyle($fallback).display
    const $children = []

    function update(value) {
        const $first = $parent.firstElementChild
        if (is_ref(when) ? when.value : when) {
            $fallback && $fallback.remove()
            $children.forEach($child => $child.remove())
            $children.length = 0
            for (let i = 0; i < children.length; i += 1) {
                const child = children[i]
                const $child = child instanceof Element ? child : create_or_render(child);
                // $parent.append($child)
                if ($last) {
                    $last.after($child)
                } else if ($first) {
                    $parent.insertBefore($child, $first)
                } else {
                    $parent.appendChild($child)
                }
                $children.push($child)
            }
        } else {
            if ($fallback) {
                $children.forEach($child => $child.remove())
                if ($last) {
                    $last.after($fallback)
                } else if ($first) {
                    $parent.insertBefore($fallback, $first)
                } else {
                    $parent.appendChild($fallback)
                }
            }
        }
    }

    watch0(when, (value, old_value) => {
        update()
    })

    return $children[0] || `placeholder`
}