import "./Word.css";
import Tip from "../components/Tip.jsx";
import TextRect from "nano/components/TextRect.jsx";

console.log({type: 'tracer'})

export default function Prompt(props={}, children) {
    const status_texts = ['  REVIEW  ', ` WORDS `, `in your basket`]
    const tap_texts = ['  TAP  ', ` to speak`]
    return (
        <div class="page review_word flex_center" style="gap: 2rem; text-align: center;">
            <TextRect texts={status_texts} colors={{1: 'var(--blue)'}}/>

            <div><Tip/></div>
            <TextRect texts={tap_texts} colors={{0: 'var(--blue)'}}/>
            {/*<h3>Tap on any words to get explanation</h3>*/}
        </div>
    )
}
