import {num_correction_promote_stage, number_matches, per_page_words, word_match_batch_size} from "../config.js";
import MatchWordPage from "./MatchWordPage.jsx";
import {algorithm, basket, page_theme, batch, current_progress, header, next_progress} from "../store.jsx";
import {ref_render} from "nano/nano.jsx";
import {clone_object, picker, shuffler} from "nano/lib/_.js";
import {to_base64} from "../../common/converter.js";
console.log({type: "tracer"})

export default function MatchAlgorithm() {
    const me = {
        // update,
        name: 'Match',
        get_generator,
        goto_next_mode: false,
        match_times: 0
    }

    const navigate = {
        right: 'MatchWord',
        next: 'MatchWord'
    }

    const pick = picker(['id', 'language', 'text', 'stage'])
    async function get_generator(/* $current_dom, */$dom) {
        const words = shuffler()(batch.value).slice(0, per_page_words)
        const props = {words}

        const progress = next_progress.value
        next_progress.value = next_progress.value + 1

        me.match_times = me.match_times + 1
        const finished_match = me.match_times >= number_matches

        if (finished_match) {
            me.goto_next_mode = true
        }

        const post_hook = async () => {
            if (finished_match) {
                const promoted = []
                batch.value.forEach(w => {
                    const {_index, _corrected} = w
                    if (_corrected > num_correction_promote_stage) {
                        w.stage += 1
                        basket.value[_index] = undefined
                        promoted.push(pick(w))
                    }
                })
                if (promoted.length > 0) {
                    await api.put(`/api/basket`, {promoted: true, words: to_base64(promoted)})
                }
                batch.value = []
                me.match_times = 0
            }
        }

        const name = words.map(word => word.text).join(',')
        return {name, generator: match_generator}
        async function match_generator($dom) {
            const pre_hook = () => {
                page_theme.value = 'light'
                algorithm.value.swipe.use(true)
                current_progress.value = progress
                header.value = true
            }

            const contexts = {props, pre_hook, post_hook, navigate,
                dataset: {
                    // word: word.text,
                    component: 'Word'
                }}

            await ref_render($dom, MatchWordPage, contexts)
            return false
        }
    }

    return me
}
