// https://languageknowledge.eu/countries/sweden

export const enabled_languages = {
    english: "hello", // 54%
    arabic: "مرحبا",
    chinese: "你好",
    french: "bonjour", // 7%
    japanese: "こんにちは",
    russian: "здравствуйте",
    swedish: "hej", // 96%
};

export const supported_languages = {
    english: "hello", // 54%
    arabic: "مرحبا",
    chinese: "你好",
    danish: 'hej',
    finish: 'hei',
    french: "bonjour", // 7%
    german: "hallo", // 19%
    hebrew: 'שלום',
    hindi: "नमस्ते",
    italian: "",
    japanese: "こんにちは",
    korean: '안녕하세요',
    norwegian: 'hallo',
    portuguese: "",
    russian: "здравствуйте",
    spanish: "halla", // 5%
    swedish: "hej", // 96%
    thai: "สวัสดี",
    ukraine: "привіт"
};


export const language_names = Object.keys(enabled_languages);
export const language_columns_declaration = language_names.map(lang => `${lang}  TEXT`).join(",");
export const language_columns_placeholder = language_names.map(_ => `?`).join(",");