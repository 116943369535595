console.log({type: "tracer"})

export default function Projectile(options) {
    const me = {
        ...options,
        update,
        draw,
        clear,

    };

    const width = me.width || me.img.width
    const height = me.height || me.img.height
    const half_width = width / 2
    const half_height = height / 2


    function clear(game) {
        const {ctx} = game
        if (me.img) {
            ctx.fillStyle = me.background;
            ctx.fillRect(me.x - half_width, me.y, width, height);
        }
    }

    function update(game) {
        me.x = me.x + me.dx;
        me.y = me.y + me.dy;
    }

    function draw(game) {
        const {ctx} = game
        if (me.img) {
            ctx.drawImage(me.img, me.x - half_width, me.y, width, height);
        } else {
            ctx.beginPath();
            ctx.fillStyle = me.color;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
    }

    return me;
}