import IconLogout from "solidjs-feather/IconLogout.jsx";
import IconPause from "solidjs-feather/IconPause.jsx";
import IconFrown from "solidjs-feather/IconFrown.jsx";
import {speak} from "../audio.js";
import Keyboard from "./Keyboard.js";
import Show from "nano/Show.jsx";

import {after_mounted, computed, ref} from "nano/reactive.jsx";
import {ref_render} from "nano/nano.jsx";
import "./Game.css";
import ShootingWord from "./ShootingWord.jsx";
import {algorithm, report, page_theme, get_audioContext} from "../store.jsx";
import {enable_report} from "../config.js";


const MINUTE = 1000 * 60;
const SECOND = 1000;

console.log({type: "tracer"});

export default async function GamePage(props = {}) {
    const {
        audio_url, message = "START",
        label = "START",
        name = "ShootingWord",
        sequence,
        game_timeout = 1.5 * MINUTE,
        game_over_screen_time = 2 * SECOND,
        is_mobile= /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        audio,
    } = props;
    const id = `canvas-${sequence}`;

    const Games = {
        ShootingWord,
    };

    let start = undefined;
    let game = undefined;

    const Game = Games[name];
    if (!Game) {
        throw {message: `Game ${name} not supported yet`};
    }
    // const audio = AsyncAudio("/Prototype.aac");

    const statuses = {
        unstarted: "PLAY A GAME",
        paused: "RESUME",
        game_over: "GAME OVER",
        playing: undefined
    };

    let resume_game = undefined; // a function will be assigned by start

    let frames = 0;

    function Enum(values) {
        return Object.freeze(Object.fromEntries(values.map(v => [v, v])));
    }

    const Status = Enum(["unstarted", "playing", "paused", "background_paused", "game_over"]);

    const status = ref(Status.unstarted);
    const status_label = computed(() => statuses[status.value]);
    // const game_over = ref(false)
    const width = window.innerWidth;
    const height = window.innerHeight;
    const times = {};


    function setup_canvas() {
        const canvas = document.getElementById(id);
        const offscreen_canvas = document.createElement("canvas");

        const devicePixelRatio = window.devicePixelRatio;

        canvas.width = width * devicePixelRatio;
        canvas.height = height * devicePixelRatio;
        offscreen_canvas.width = canvas.width;
        offscreen_canvas.height = canvas.height;

        const onscreen_ctx = canvas.getContext("2d");
        onscreen_ctx.scale(devicePixelRatio, devicePixelRatio);

        const offscreen_ctx = offscreen_canvas.getContext("2d");
        offscreen_ctx.scale(devicePixelRatio, devicePixelRatio);

        onscreen_ctx.fillStyle = `rgba(0, 0, 0, 0.4)`;
        onscreen_ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

        return {canvas, offscreen_canvas, onscreen_ctx, offscreen_ctx};
    }


    after_mounted(async () => {
        const {canvas, offscreen_canvas, onscreen_ctx, offscreen_ctx} = setup_canvas();

        onscreen_ctx.fillStyle = `rgba(0, 0, 0, 0.4)`;
        onscreen_ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);


        start = _start;
        let game_start_time = performance.now();

        let frame_start_time;
        let frame_used_time;
        let frame_time;

        const $clientX = ref();
        const $clientY = ref();


        game = {
            canvas,
            offscreen_canvas,
            ctx: onscreen_ctx,
            onscreen_ctx,
            offscreen_ctx,
            keyboard: Keyboard(),

            $clientX,
            $clientY,

            audio,
            turn_down_volume() {
                audio.volume = 0.05;
            },
            turn_up_volume() {
                audio.volume = 0.5;
            },
            game_start_time,
            game_played_time: 0,
            prev_time: game_start_time,
            frames: 0,
            frame_time: 16,
            frame_total_time: 0,
            frame_max_time: 0,
            frame_min_time: 100,
            frame_used_time: 0,
            frame_max_used_time: 0,
            frame_min_used_time: 100,
        };
        const content = await Game({...props, audio, loop});

        async function _start() {
            console.log("start");

            game.prev_time = game_start_time = performance.now();

            status.value = Status.playing;

            content.init(game);
            swipe.use(false, $clientX, $clientY);

            audio.play();
            animate();


            document.onvisibilitychange = function onvisibilitychange(event) {
                if (document.hidden) {
                    console.log("App is minimized or in the background");
                    if (status === Status.playing) {
                        // only set to background_paused when it's in playing status
                        // otherwise (unstarted/paused) nothing need to do
                        pause_game(Status.background_paused);
                    }

                    // Handle app minimization (e.g., pause tasks, save state)
                } else {
                    console.log("App is visible or restored");
                    // delay invoke resume_game so that previous event able to finish when pause game
                    if (status === Status.background_paused) {
                        // only need to resume if it's put to paused status by due to visibility change
                        setTimeout(resume_game, 500);
                    }
                }
            };


            return function resume_game() {
                if (status.value === Status.paused) {
                    swipe.use(false);
                    // audio.play();
                    status.value = Status.playing;
                    animate();
                } else {
                    // console.log('ignore, not in paused status')
                }
            };
        }
        function animate() {
            frame_start_time = performance.now();
            frame_time = frame_start_time - game.prev_time;
            game.frames += 1;
            game.frame_time = frame_time;
            game.frame_total_time += frame_time;
            game.prev_time = frame_start_time;
            game.played_time += frame_time;

            if (game.played_time > game_timeout) {
                status.value = Status.game_over;
                setTimeout(onclick_exit, game_over_screen_time);
                return;
            }


            content.clear(game);
            content.update(game);
            content.gc(game);
            content.draw(game);
            game.ctx.drawImage(game.offscreen_canvas, 0, 0, game.offscreen_canvas.width, game.offscreen_canvas.height, 0, 0, innerWidth, innerHeight); //canvas.width, canvas.height);

            frame_used_time = performance.now() - frame_start_time;
            game.frame_used_time += frame_used_time;
            game.frame_max_used_time = Math.max(game.frame_max_used_time, frame_used_time);
            game.frame_min_used_time = Math.min(game.frame_min_used_time, frame_used_time);
            game.frame_max_time = Math.max(game.frame_max_time, frame_time);
            game.frame_min_time = Math.min(game.frame_min_time, frame_time);

            if (game.frames % 1_000 === 0) {
                const entries = Object.entries(times).map(([name, ms]) => [`${name}: `.padEnd(30, " "), (ms / game.frames).toFixed(2)].join(""));
                console.log([
                    ...entries,
                    `${"frame avg used time: ".padEnd(30, " ")}${(game.frame_used_time / game.frames).toFixed(2)}`,
                    `${"frame min used time: ".padEnd(30, " ")}${game.frame_min_used_time.toFixed(2)}`,
                    `${"frame max used time: ".padEnd(30, " ")}${game.frame_max_used_time.toFixed(2)}`,
                    `${"frame avg time: ".padEnd(30, " ")}${(game.frame_total_time / game.frames).toFixed(2)}`,
                    `${"frame min time: ".padEnd(30, " ")}${game.frame_min_time.toFixed(2)}`,
                    `${"frame max time: ".padEnd(30, " ")}${game.frame_max_time.toFixed(2)}`
                ].join("\n"));
            }

            if (status.value === Status.playing) {
                // if (is_mobile) {
                //     setTimeout(() => {
                        requestAnimationFrame(animate);
                //     }, 30)
                // } else {
                //     requestAnimationFrame(animate);
                // }
            } else {
                console.log("playing value changed, timout/visibility change");
            }

        }

        function loop(objects, me, fun, name) {
            const loop_start_time = performance.now();

            for (let i = 0, len = objects.length; i < len; i += 1) {
                const object = objects[i];
                object[fun](game, me);
            }
            times[name] = (times[name] || 0) + performance.now() - loop_start_time;
        }

    });

    async function onclick_exit(event) {
        console.log(event);
        event && event.stopPropagation();
        document.onvisibilitychange = undefined;
        status.value = Status.game_over;
        audio.pause();
        // algorithm.value.pointer.use({touch: false, swipe: true})
        algorithm.value.onswipeup(event);
    }


    function pause_game(pause_type = Status.paused) {
        status.value = pause_type;
        // audio.pause will invoke system api
        // when change to background, after that part will NOT be executed until it become visible
        audio.pause();
        swipe.use(true);
    }

    function onclick_pause(event) {
        event.stopPropagation();
        return pause_game();
    }

    function on_click_canvas(event) {
        console.log("on_click_canvas", event);
        if (status.value === Status.paused) {
            resume_game();
        }
    }

    async function onclick_middle_button(event) {
        switch (status.value) {
            case "unstarted":
                await speak(message);

                // const $ignitor = document.getElementById('ignitor')
                // $ignitor.remove()
                status.value = Status.playing;
                resume_game = await start();
                break;
            case "paused":
                // status.value = Status.playing
                // resume_game()
                // do nothing the parent node will handle on click
                break;
            case "game_over":
                status.value = Status.game_over;
                await onclick_exit(event);
                break;
            default:
                console.log(`unsupported ${status.value}`);
        }
    }

    async function onclick_save_for_gems(event) {
        console.log(event);
        await onclick_exit(event);
    }

    function oncontextmenu(event) {
        event.stopPropagation();
        return false;
    }

    return (
        <div id="app" class="page game flex_center" onclick={on_click_canvas}>
            <canvas id={id} class="canvas undraggable" oncontextmenu={oncontextmenu}></canvas>

            <Show when={computed(() => status.value === Status.playing)}>
                <div class="pause_button" onclick={onclick_pause}>
                    <IconPause/>
                </div>
            </Show>


            <Show when={computed(() => status.value !== Status.unstarted)}>
                <div class="exit_button" onclick={onclick_exit}>
                    <IconLogout/>
                </div>
            </Show>

            <Show when={computed(() => status.value === Status.unstarted)}>
                <div class="top_button">
                    <div>Awesome work</div>
                    <div>10 words reviewed</div>
                    <div>20<span style="font-size: 1.4em"> 💎</span></div>
                </div>
            </Show>

            <Show when={computed(() => status.value !== Status.playing)}>
                <div class="middle_button" onclick={onclick_middle_button}>{status_label}</div>
            </Show>

            <Show when={computed(() => status.value === Status.unstarted)}>
                <div class="bottom_button" onclick={onclick_save_for_gems}><span>Skip, Save for Gems</span><span
                    style="font-size: 1.4em"> 💎</span></div>
            </Show>

            <Show when={enable_report}>
                <span onclick={report} class="report" style="top: 50%; left: 5%; color: #ccd">
                    <IconFrown/>
                </span>
            </Show>
        </div>
    );
}