export const from_callback_fn = fn => {
    return new Promise((resolve, reject) => fn(resolve, reject));
};

export async function fileEntry_to_File(fileEntry) {
    return new Promise((resolve, reject) => {
        try {
            fileEntry.file(resolve, reject);
        } catch (error) {
            reject(error);
        }
    });
}


// from_callback_fn(fileEntry.file)

export const async_read_text = (file, encoding = "UTF-8") => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
        const result = e.target.result;
        console.log(result);
        resolve(result);
        // const doc = yaml.load(result);
        // await upload_tables(result);
    };
    reader.onerror = e => {
        reject(e);
    };
    reader.readAsText(file, encoding);
});

export const promisify_request = (request) => {
    return new Promise(((resolve, reject) => {
        request.onsuccess = event => {
            // console.log(event);
            resolve(event.target);
        };
        request.oncomplete = event => {
            // console.log(event);
            resolve(event.target);
        };
        request.onerror = event => {
            console.log(event);
            reject(event.target);
        };
    }));
};