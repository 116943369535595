// https://languageknowledge.eu/countries/sweden
import {
    arabic_to_arabizi,
    japanese_to_romaji,
    cyrillic_to_roman,
    korean_to_roman,
    greek_to_roman,
    hindi_to_roman
} from 'nano/lib/romanization.js'

export const enabled_languages = {
    "english": "English",
    "arabic": "العربية",
    "chinese": "中文",
    "dutch": "Nederlands",
    "french": "Français",
    "german": "Deutsch",
    "hindi": "हिन्दी",
    "italian": "Italiano",
    "japanese": "日本語",
    "korean": "한국어",
    "portuguese": "Português",
    "russian": "Русский",
    "spanish": "Español",
    "swedish": "Svenska"
};


export const companion_converter = {
    arabic: arabic_to_arabizi,
    japanese: japanese_to_romaji,
    russian: cyrillic_to_roman,
    korean: korean_to_roman,
    greek: greek_to_roman,
    hindi: hindi_to_roman,
}

export const supported_languages = {
    english: "hello", // 54%
    arabic: "مرحبا",
    chinese: "你好",
    danish: 'hej',
    dutch: 'hallo',
    finish: 'hei',
    french: "bonjour", // 7%
    german: "hallo", // 19%
    hebrew: 'שלום',
    hindi: "नमस्ते",
    italian: "",
    japanese: "こんにちは",
    korean: '안녕하세요',
    norwegian: 'hallo',
    portuguese: "",
    russian: "здравствуйте",
    spanish: "halla", // 5%
    swedish: "hej", // 96%
    thai: "สวัสดี",
    ukraine: "привіт"
};

export const language_names = Object.keys(enabled_languages);

export const language_columns_declaration = language_names.map(lang => `${lang}  TEXT`).join(",");
export const language_columns_placeholder = language_names.map(_ => `?`).join(",");