import {_create, create_element, create_or_render, Render} from "./nano.jsx";
import {after_mounted, on_mounted, untrack, watch} from "./reactive.jsx";

const NAME = Hover
console.log({NAME, type: "tracer"});
export default function Hover(props, children=[]) {
    const {debounce_time = 100} = props

    // console.log(props, children)
    after_mounted(() => {
        const {$parent} = props
        $parent.addEventListener('mouseenter', on_mouse_enter)
        let $children = []
        let debounce
        function on_mouse_enter(event) {
            if (debounce) {
                clearTimeout(debounce)
                debounce = undefined
            }
            $parent.style['z-index'] = '100'
            // already initialized
            if ($children.length > 0) {

            } else {
                $parent.addEventListener('mouseleave', on_mouse_leave)
                for (let i = 0; i < children.length; i += 1) {
                    const child = children[i]
                    const $child = create_or_render(child)
                    console.log(child, $child)
                    $parent.append($child)
                    $children.push($child)
                }
            }
        }
        function on_mouse_leave(event) {
            debounce = setTimeout(() => {
                $children.forEach($child => $child.remove())
                $parent.style['z-index'] = '' // 'initial'
                $children = []
            }, debounce_time)

        }
    })
    return 'placeholder'
}