import For from 'nano/For.jsx'

import IconMic from "solidjs-feather/IconMic.jsx";
import IconCamera from "solidjs-feather/IconCamera.jsx";
import {after_mounted, computed, ref} from 'nano/reactive.jsx'
import "./Recorder.css"
import Show from 'nano/Show.jsx'
import {get_lang_flag} from 'nano/lib/unicode.js'
import {rfc5646_language} from "../audio.js";
import {get_audioContext} from "../store.jsx";

console.log(RecorderPage.name, {type: 'tracer'})
function audioBufferToWav(buffer) {
    let numOfChan = buffer.numberOfChannels,
        length = buffer.length * numOfChan * 2 + 44,
        bufferArr = new ArrayBuffer(length),
        view = new DataView(bufferArr),
        channels = [],
        i,
        sample,
        offset = 0,
        pos = 0;

    // Write WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    // Write interleaved data
    for (i = 0; i < buffer.numberOfChannels; i++)
        channels.push(buffer.getChannelData(i));

    while (pos < length) {
        for (i = 0; i < numOfChan; i++) {
            sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit
            view.setInt16(pos, sample, true); // write 16-bit sample
            pos += 2;
        }
        offset++;
    }

    return bufferArr;

    function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
    }

    function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
    }
}



function MediaRecorderWrapper(stream) {
    const recorder = new MediaRecorder(stream);

    let chunk = undefined
    async function stop_ignore_chunk() {
        return new Promise((resolve, reject) => {
            recorder.onstop = async (event) => {
                console.log('onstop', event)
                resolve(event)
            };
            recorder.ondataavailable = event => {
                console.log('ignore ondataavailable', event)

            };
            recorder.stop()
        })
    }

    async function stop() {
        return new Promise((resolve, reject) => {
            recorder.onstop = (event) => {
                console.log('onstop', event)
            };
            // might due to the stream stopped earlier before calling stop
            if (chunk) {
                recorder.stop()
                resolve(chunk)
            } else {
                recorder.ondataavailable = event => {
                    console.log('ondataavailable', event)
                    resolve(event.data);
                };
                recorder.stop()
            }
        })
    }

    async function restart(delay_start=0.3) {
        const chunk = await stop()

        setTimeout(() => {
            recorder.start()
        }, delay_start * 1000)
        return chunk
    }

    function start(delay_start = 0.2) {
        recorder.ondataavailable = event => {
            console.log('ondataavailable',  event)
            chunk = event.data
        };
        setTimeout(() => {
            recorder.start()
        }, delay_start * 1000)
    }


    return {
        start,
        stop_ignore_chunk,
        stop,
        restart
    }
}

export default function RecorderPage(props={}, children=[]) {
    const {interval = 100,
        // Adjust based on your silence detection needs
        silence_threshold = 0.001,
        max_silence_duration = 5000 } = props
    let recorder = undefined
    let fftSize = 8192
    const $video = ref()
    let $canvas = ref()
    let $camera_canvas = ref()
    let $transcript = ref()
    let chunks = []
    let confirmed_chunks = []
    const transripts = ref([])
    const taking_photo = ref(false)
    const recording = ref(false)
    // const language = ref('chinese')
    const language = ref('english')
    const languages= ['chinese', 'english']

    let recognition = undefined;
    let analyser = undefined;
    let buffer_length = undefined
    let data_array = undefined
    let data_time

    let closestPianoKeys

    // after_mounted(animate)

    function getPianoFrequencies() {
        const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
        const pianoFrequencies = {};

        for (let octave = 0; octave <= 8; octave++) {
            for (let i = 0; i < notes.length; i++) {
                const keyNumber = i + 12 * octave + 1
                const frequency = 440 * Math.pow(2, (keyNumber - 49) / 12);
                pianoFrequencies[notes[i] + octave] = frequency;
            }
        }

        return pianoFrequencies;
    }

    function findClosestValueIndex(dataArray, targetFrequency, sampleRate) {
        const nyquist = sampleRate / 2;
        const binSize = nyquist / dataArray.length;
        const targetIndex = Math.round(targetFrequency / binSize);

        return targetIndex < dataArray.length ? targetIndex : dataArray.length - 1;
    }

    function getPianoKeysWithValues(dataArray, sampleRate) {
        const pianoFrequencies = getPianoFrequencies();
        const pianoKeysWithValues = {};

        for (const [key, frequency] of Object.entries(pianoFrequencies)) {
            const closestIndex = findClosestValueIndex(dataArray, frequency, sampleRate);
            pianoKeysWithValues[key] = dataArray[closestIndex];
        }

        return pianoKeysWithValues;
    }

    const data = []

    function animate() {
        analyser.getByteFrequencyData(data_array)
        analyser.getByteTimeDomainData(data_time)
        const sampleRate = audioContext.sampleRate;
        closestPianoKeys = getPianoKeysWithValues(data_array, sampleRate, analyser.fftSize);

        // console.log(Object.fromEntries(Object.entries(closestPianoKeys).filter(([key, value]) => value > 0)))
        const sum = data_time.reduce((acc, cur) => acc + cur, 0)
        // console.log(sum.toFixed(4))

        // const domain = 'time'
        const domain = 'piano'

        const canvas = $canvas.value
        const context = canvas.getContext('2d')
        const POINTS = 1024
        if (domain === 'time') {

            if (data.length > 1024) {
                data.shift()
            }
            data.push(data_time[10])
            context.lineWidth = 2;
            context.strokeStyle = "rgb(0 0 0)";

            const sliceWidth = (canvas.width * 1.0) / POINTS;
            let x = 0;

            context.beginPath();
            for (let i = 0; i < data.length; i++) {
                const v = data[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    context.moveTo(x, y);
                } else {
                    context.lineTo(x, y);
                }

                x += sliceWidth;
            }
            // canvasCtx.lineTo(WIDTH, HEIGHT / 2);
            context.stroke();
        } else if (domain === 'piano') {
            const data = Object.entries(closestPianoKeys)
            const buffer_length = data.length
            const bar_width = canvas.width / buffer_length
            context.clearRect(0, 0, canvas.width, canvas.height)

            for (let i = 0, x = 0; i < buffer_length; i += 1, x += bar_width) {
                context.fillStyle = 'gray'
                context.fillRect(x, canvas.height - data[i][1], bar_width - 1, data[i][1])
            }

        }

        if (recording.value) {
            requestAnimationFrame(animate)
        }
    }

    async function chunk_to_audio_buffer(chunk) {
        try {
            const arrayBuffer = await chunk.arrayBuffer();
            return await audioContext.decodeAudioData(arrayBuffer);

            // console.log(audioBuffer.numberOfChannels)
            //
            // return audioBuffer.getChannelData(0);
        }
        catch (error) {
            console.log(error)
            return new Promise((resolve, reject) => {
                const audioBlob = new Blob([chunk], {type: 'audio/webm'});
                const reader = new FileReader();
                reader.readAsArrayBuffer(audioBlob);
                reader.onloadend = async () => {
                    const arrayBuffer = reader.result;
                    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
                    resolve(audioBuffer)
                }
                reader.onerror = reject
            })
        }
    }

    async function is_silent_chunk() {
        return new Promise((resolve, reject) => {
            const audioBlob = new Blob(chunks, { type: 'audio/webm' });
            const reader = new FileReader();
            reader.readAsArrayBuffer(audioBlob);
            reader.onloadend = async () => {
                const arrayBuffer = reader.result;
                const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                console.log(audioBuffer.numberOfChannels)

                const channelData = audioBuffer.getChannelData(0);
                const is_silent = channelData.every(sample => Math.abs(sample) < silence_threshold);
                resolve(is_silent)
            }
            reader.onerror = reject
        })

    }
    async function get_none_silent_buffer(chunks,
                                          silenceThresholdInSeconds = 5,
                                          keepLastSilentSecond=1,
                                          trimHeadSecond = 0.01,
                                          trimTailSecond = 0.28,
                                          silence_threshold=0.0001) {
        let sampleRate
        const allChannelData = []

        for (let i = 0; i < chunks.length; i += 1) {
            const chunk = chunks[i]
            const audio_buffer = await chunk_to_audio_buffer(chunk)
            sampleRate = audio_buffer.sampleRate
            const silenceThreshold = silenceThresholdInSeconds * sampleRate;
            const keepSilenceThreshold = keepLastSilentSecond * sampleRate;

            const channelData = audio_buffer.getChannelData(0)
            let newBuffer = []
            let inSilentSegment = true
            for (let i = trimHeadSecond * sampleRate,
                     last = channelData.length - trimTailSecond * sampleRate,
                     silentFrames = 0;
                 i < last; i++) {
                if (Math.abs(channelData[i]) < silence_threshold) {
                    silentFrames++;
                } else {
                    if (silentFrames > silenceThreshold) {
                        // If we were in a silent segment longer than the threshold, add the last second of silence
                        newBuffer.push(...channelData.slice(i - keepSilenceThreshold, i));
                    }
                    newBuffer.push(channelData[i]);
                    silentFrames = 0;
                    inSilentSegment = false;
                }
            }

            if (inSilentSegment) {

            }
            else {
                allChannelData.push(newBuffer)
            }
        }


        const outputLength = allChannelData.reduce((total, buffer) => total + buffer.length, 0);

        const outputBuffer = audioContext.createBuffer(1, outputLength, sampleRate);
        let offset = 0;

        for (const channelData of allChannelData) {
            outputBuffer.getChannelData(0).set(channelData, offset);
            offset += channelData.length;
        }

        return outputBuffer;
    }


    async function exportAudio(buffer) {
        const wavBuffer = audioBufferToWav(buffer);
        const blob = new Blob([new DataView(wavBuffer)], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'output.wav';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
    }



    let video
    let stream

    // let facingMode = 'environment'
    let facingMode = 'user'
    async function on_open_camera(event) {
        $video.value.setAttribute('autoplay', '');
        $video.value.setAttribute('muted', '');
        $video.value.setAttribute('playsinline', '')
        const media = await navigator.mediaDevices.getUserMedia({video: {live: false}})
        $video.value.srcObject = media
        taking_photo.value = true
        try {
            $video.value.play()
        } catch (error) {
            console.log(error)
        }

        // video.onloadeddata = update_camera_canvas
    }
    function take_photo(event) {
        const context = $camera_canvas.value.getContext('2d')
        context.drawImage($video.value, 0, 0, $camera_canvas.value.width, $camera_canvas.value.height)
    }

    async function use_speech_recognition() {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            recognition = new SpeechRecognition();
            recognition.continuous = true; // Keep recognizing speech continuously
            const lang = rfc5646_language[language.value];
            recognition.lang = lang
            recognition.interimResults = true;
            recognition.start();
            // Event listener for when speech is recognized
            recognition.onresult = (event) => {
                transripts.value = [...event.results].map(result => result[0].transcript);
                // console.log(results);
                // $transcript.value.innerText = results.join('');
            };

        }
    }

    async function getMicrophones() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'audioinput');
    }

    const microphones = ref([])
    after_mounted(async () => {
        microphones.value = await getMicrophones()
        console.log(microphones.value)
    })
    window.onkeydown = onkeydown

    async function onkeydown(event) {
        const {key} = event
        console.log(key)
        if (recorder) {
            switch (key) {
                case "ArrowDown":
                    console.log('↓ Ignore the data and restart recording')
                    await recorder.restart()
                    break;
                case "ArrowRight":
                    console.log('→ Save chunk and restart recording')
                    confirmed_chunks.push(await recorder.restart())

                    break;
                default:
                    break;
            }
        }
    }




    async function onclick(event) {
        if (recording.value) {
            recording.value = false

            stream.getTracks().forEach(track => track.stop());
            recognition.stop();

            console.log(chunks, event)

            const chunk = await recorder.stop()
            confirmed_chunks.push(chunk)
            const buffer = await get_none_silent_buffer(confirmed_chunks, 3, 1, 0, 0);
            await exportAudio(buffer);
            confirmed_chunks = []
        } else {

            recording.value = true
            // NOTE: one application can only have one AudioContext

            const audioContext = get_audioContext()
            await use_speech_recognition()
            analyser = audioContext.createAnalyser();
            analyser.fftSize = fftSize;
            buffer_length = analyser.frequencyBinCount
            data_array = new Uint8Array(buffer_length)
            data_time = new Uint8Array(fftSize)

            // const iphone = (await getMicrophones()).find(d => d.label.includes('Rock12'))

            stream = await navigator.mediaDevices.getUserMedia(
                {
                    audio:
                        {
                            echoCancellation: true,
                            echoCancellationType: { ideal: " system " },
                            channelCount: 1,
                            // sampleRate: { ideal: AUDIO_SAMPLE_RATE },
                            noiseSuppression: true,
                            autoGainControl: true,
                            googEchoCancellation: true,
                            googAutoGainControl: true,
                            googExperimentalAutoGainControl: true,
                            googNoiseSuppression: true,
                            googExperimentalNoiseSuppression: true,
                            googHighpassFilter: true,
                            googTypingNoiseDetection: true,
                            googBeamforming: false,
                            googArrayGeometry: false,
                            googAudioMirroring: true,
                            googNoiseReduction: true,
                            mozNoiseSuppression: true,
                            mozAutoGainControl: true,
                            latency: 0.01,
                            deviceId: '516723281849c40bdd472e3035f0d53f8eb1da6da849ffd036d08f280a50c475'
                        }
                        // {deviceId: iphone.deviceId}
                })
            const source = audioContext.createMediaStreamSource(stream)
            source.connect(analyser)

            const lowpassFilter = audioContext.createBiquadFilter();
            lowpassFilter.type = 'lowpass';
            lowpassFilter.frequency.value = 3000; // Lowpass at 200Hz
            lowpassFilter.Q.value = 0.7;

            const highpassFilter = audioContext.createBiquadFilter();
            highpassFilter.type = 'highpass';
            highpassFilter.frequency.value = 200; // Highpass at 3000Hz
            highpassFilter.Q.value = 0.7;

            // Step 4: Connect the nodes: Microphone -> Lowpass -> Highpass -> Destination
            source.connect(lowpassFilter);
            lowpassFilter.connect(highpassFilter);
            // highpassFilter.connect(audioContext.destination);

            // Step 5: Use the MediaStream from the processed audio for recording
            const destinationStream = audioContext.createMediaStreamDestination();
            highpassFilter.connect(destinationStream);

            recorder = new MediaRecorderWrapper(destinationStream.stream);
            recorder.start();

            animate()
        }




    }


    return (
        <div style="display: flex; flex-direction: column; height: 100%; width: 100%; padding: 1rem; gap: 1rem;">
            <div style="display: flex; justify-content: center; gap: 30px; padding: 1rem;">

                <div>
                    <For _each={languages}>{(lang, i) => {
                        return <span class={{language: true, selected: computed(() => language.value === lang)}} onclick={() => language.value = lang}>{get_lang_flag(lang)}</span>
                    }}</For>
                </div>
                <span onclick={onclick} class={{recording}}>
                    <IconMic/>

                </span>

                <span onclick={on_open_camera} >
                    <IconCamera/>
                </span>

                {/*<select name="microphone" >*/}
                {/*    <For _each={microphones}>{(microphone, i) => {*/}
                {/*        return <option value={microphone.deviceId} selected={microphone.deviceId === 'deault'}>{microphone.label}</option>*/}
                {/*    }}</For>*/}
                {/*</select>*/}
            </div>

            <div _ref={$transcript} style="flex: 1">
                <For _each={transripts}>{(transcript, i) => {
                    return <p>{transcript}</p>
                }}</For>
            </div>
            <Show when={taking_photo}>
                <video _ref={$video} style="position: fixed; top: 0; left: 0; width: 80%; height: 80%;"
                       ></video>
                <span onclick={take_photo} class="camera">
                    <IconCamera/>
                </span>
            </Show>
            {/*<canvas _ref={$camera_canvas} class="audio_visualizer" style="top: 0"></canvas>*/}

            <canvas _ref={$canvas} class="audio_visualizer"></canvas>
        </div>)
}