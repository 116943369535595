import {_create, create_element, create_or_render, Render} from "./nano.jsx";
import {is_ref, ref, not_ref, on_mounted, untrack, watch} from "./reactive.jsx";

console.log({type: "tracer"});

export default function Visible(props, children=[]) {
    let {when, fallback, $parent, description} = props

    // console.log(when, fallback, $parent, children)
    let values = []
    const $fallback = fallback && untrack(() => {
        return _create(...fallback)
    });
    let fallback_value = $fallback && window.getComputedStyle($fallback).display
    const $children = []

    for (let i = 0; i < children.length; i += 1) {
        const child = children[i]
        const $child = child instanceof Element ? child : create_or_render(child);
        $parent.append($child)
        $children.push($child)
        values.push(window.getComputedStyle($child).display)
    }
    if ($fallback) {
        $parent.append($fallback)
    }
    if (not_ref(when)) {
        when = ref(when)
    }

    watch(when, (new_value, old_value) => {
        console.log(description, new_value, old_value)
        if (new_value) {
            $children.forEach(($child, i) => {
                $child.style['visibility'] = values[i]
            })
            if ($fallback) {
                $fallback.style['visibility'] = 'hidden'
            }
        }
        else {
            $children.forEach($child => {
                $child.style['visibility'] = 'hidden'
            })
            if ($fallback) {
                $fallback.style['visibility'] = fallback_value
            }
        }
    })

    return $children[0]
}