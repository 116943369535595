

import api from "./api.jsx";
import {PAGE_DEBUG} from "./config.js";
import {ref_render, Render} from "nano/nano.jsx";
import {$current_dom, $next_dom, $right_dom, meta} from "./store.jsx";
import Game from "./game/GameAlgorithm.js";
import Story from "./story/StoryAlgorithm.js";
import Vocabulary from './vocabulary/VocabularyAlgorithm.js'
import Welcome from "./page/Welcome.jsx";
import Word from "./word/WordAlgorithm.js";
import Article from "./article/ArticleAlgorithm.js";
import Recorder from './recorder/RecorderAlgorithm.js'
import Music from './music/MusicAlgorithm.js'
import Match from './match/MatchAlgorithm.js'
import Editor from './editor/EditorAlgorithm.js'

import Heritage from './heritage/Heritage.jsx'
import Options from "./page/Options.jsx";
import Translator from './page/Translator.jsx'
import Photo from "./page/Photo.jsx";

import Page from "./word/WordPage.jsx";

console.log({type: "tracer"})
export default function Algorithm(props={}, children=[]) {
    let {
        init_mode = 'ReviewWord',
        // init_mode = 'Game',
        // init_mode = 'Article',
        // init_mode = "MatchWord",
        // init_mode = 'Welcome',
        swipe,
        on_finish,
        remove
    } = props

    const me = {
        swipe,
        sequence: 0,
        init,
        update,
        update_middle,
        reupdate_middle,
        // reupdate,
        peek_update,
        Mode: undefined,
        current_mode: undefined,
        next_mode: undefined,
        get_Page,
        on_finish() {
            props.on_finish();
        },
        basket: undefined,
        word_index: 0,
        match_index: 0,
        remove,
    }

    let mode_index = 0

    // let Modes = [
    //     {
    //         mode: Welcome,
    //         singleton: true
    //     },
    //     {
    //         mode: Article,
    //         condition: () => me.basket.length < 10
    //     },
    //     {
    //         mode: ReviewWord,
    //     },
    //     {
    //         mode: MatchWord,
    //     },
    //     {
    //         mode: Game,
    //     },
    //     {
    //         mode: Article,
    //     },
    // ]

    async function check_update_basket() {
        if (!me.basket) {
            const response = await api.get('/api/basket')
            console.log(response)
            me.basket = Array.isArray(response) && response.slice(0, 2)
        }
    }


    const Modes = {Welcome, Word, Article, Story, Vocabulary, Recorder, Music, Match, Editor, Game}
    const Pages = {Heritage, Options, Photo, Translator}

    function get_Page(name) {
        return Pages[name]
    }
    function next_mode() {

        const page = meta.value.pages[mode_index]
        mode_index = (mode_index + 1) % meta.value.pages.length
        const Mode = Modes[page.name]
        if (Mode) {
            return Mode(page, me)
        } else {
            console.error(`${page.name} not defined yet`)
        }
    }
    function update_mode() {
        if (me.mode.finished) {
            me.mode = next_mode()
        }
    }

    async function init($current_dom) {
        await check_update_basket()
        // me.Mode = Modes[mode_index]
        me.mode = next_mode()

        if (me.mode.init) {
            await me.mode.init($current_dom)
        } else {
            await me.mode.update($current_dom)
        }
        // $current_dom.value?.pre_hook && $current_dom.value?.pre_hook()
    }

    async function update($dom, name, contexts = {}) {
        const Page = Pages[name]
        $dom.value = Render(() => Page({}))
    }

    async function update_middle($dom = $next_dom) {
        update_mode()

        const result = await me.mode.update($dom)
        if (PAGE_DEBUG) {
            const $page_current = document.getElementById('page_current')
            const $page_next = document.getElementById('page_next')
            const $page_right = document.getElementById('page_right')
            $page_current.innerText = $current_dom.value?.NAME || ''
            $page_next.innerText = $next_dom.value?.NAME || ''
            $page_right.innerText = $right_dom.value?.NAME || ''
        }
        return result
    }

    async function reupdate_middle() {
        if (me.mode.reupdate) {
            const updated = await me.mode.reupdate($current_dom, $next_dom)
            if (updated) {
                $current_dom.value.pre_hook && $current_dom.value.pre_hook({})
                await update_middle()
            }
            if (PAGE_DEBUG) {
                const $page_current = document.getElementById('page_current')
                const $page_next = document.getElementById('page_next')
                const $page_right = document.getElementById('page_right')
                $page_current.innerText = $current_dom.value?.NAME || ''
                $page_next.innerText = $next_dom.value?.NAME || ''
                $page_right.innerText = $right_dom.value?.NAME || ''
            }
            return updated
        }
    }

    async function peek_update($dom) {
        const mode = next_mode()

        const result = await mode.update($dom)
        const hook = $dom.value.hook
        $dom.value.hook = () => {
            me.mode = mode
            mode_index += 1

            hook && hook()
        }
        return result
    }

    return me
}