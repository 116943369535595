import PromptBar from "../components/PromptBar.jsx";
import {on_mounted, ref} from "nano/reactive.jsx"
import For from 'nano/For.jsx'
import Show from 'nano/Show.jsx'
import './Vocabulary.css'
import {entry_language, fluent_language, get_translation} from "../store.jsx";
import api from '../api.jsx'
import {playByText} from "../audio.js";
export default function VocabularyPage(props = {}, children) {
    const {
        use_prompt=true
    } = props;
    const show_prompt = ref(use_prompt)

    const vocabulary = ref([])

    async function get_vocabulary(topic) {
        const resp = await api.get('/api/vocabulary', {topic, language: entry_language.value})
        console.log(resp)
        vocabulary.value = resp.data
        // show_prompt.value = false
    }

    on_mounted(get_vocabulary)
    let timeout_tooltip = undefined
    function onclick(event) {
        const {target, currentTarget} = event
        console.log(target, currentTarget)
        const text = target.innerText
        if (timeout_tooltip) {
            clearTimeout(timeout_tooltip)
        }

        function show_tooltip(resp) {
            const {data} = resp
            console.log(data)
            const translation = data[fluent_language.value]
            if (translation) {
                const rect = target.getBoundingClientRect()
                const $tooltip = document.getElementById('tooltip')
                const font_size = Number(window.getComputedStyle(target).fontSize.split('.').at(0))
                const right_most = (target.parentElement.getBoundingClientRect().right - rect.right) < 5
                const width = right_most ? rect.width : (rect.width - font_size)
                const left = `${rect.left + width / 2}px`
                const top = `${rect.top + rect.height + 5}px`
                $tooltip.style.left = left
                $tooltip.style.top = top
                $tooltip.innerText = translation

                timeout_tooltip = setTimeout(() => {
                    $tooltip.style.left = ''
                    $tooltip.innerText = ''
                }, 3000)

            }
        }
        get_translation(text, entry_language.value).then(show_tooltip)
        // play(text, entry_language.value)
        playByText(entry_language.value, text)


    }

    return (
        <div style="width: 100%; height: 100%; padding: 20px 10px; display: flex; flex-direction: column;">
            <div style="width: 100%; margin-bottom: 3rem; flex: 1; display: flex; flex-wrap: wrap; gap: 5px;
    justify-content: center;
    align-items: center;">
                <For _each={vocabulary}>{(word, i) => {
                    return <div class="word" onclick={onclick}>{word}</div>
                }}</For>
            </div>
            <Show when={show_prompt}>
                <PromptBar on_finish={get_vocabulary} show={show_prompt}></PromptBar>
            </Show>
        </div>)
}