import {computed, ref} from "nano/reactive.jsx";
import {IndexedDB} from "nano/lib/IndexedDB.js";

import api from "./api.jsx";
import {number_matches, sandbox, theme, word_review_batch_size} from "./config.js";
import Dialog from "./dialog/Dialog.jsx";

console.log({type: 'tracer'})
export const deferred_install = ref();
export const installed_type = ref("browser");
export const offline = ref()
export const $token = ref()
export const $app_language = ref('english')
export const $active_learning_language = ref('swedish')
export const $languages = ref(['swedish', 'english'])
export const $native_languages = ref(['english'])
export const $passive_learning_languages = ref(['english'])
export let audioContext = undefined
export function get_audioContext() {
    if (audioContext) {
        return audioContext
    } else {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
}

export const app_theme = ref('dark')
export const page_theme = ref(theme)
export const position = ref(localStorage.getItem('position') || 'middle')
export const current_progress  = ref(1, undefined, 'current_progress')  // for UI progress bar
export const next_progress  = ref(1, undefined, 'next_progress')        // for get progress for save it
export const progress_total = ref(word_review_batch_size + number_matches + 1)
export const progress_percent = computed(() => current_progress.value / progress_total.value * 100)
export const onvisibilitychanges = new Set()

window.onpagehide= (event) => {
    console.log('Page is being hidden');
    // Save state, pause activities, etc.
};

window.onpageshow= (event) => {
    console.log('Page is being shown again');
    // Restore state, resume activities, etc.
};

export const meta = ref({});
window.meta = meta

export const header = ref(false)
export const foreground = ref(false)


export const basket = ref([])
export function update_basket(array) {
    const value = basket.value
    array.forEach((v, i) => {
        value[i] = v
    })
}

export const batch = ref([])
export const word_index = ref(0)
export const $current_dom = ref(undefined, undefined, '$current_dom')
export const $right_dom = ref(undefined, undefined, '$right_dom')
export const $left_dom = ref(undefined, undefined, '$left_dom')
export const $next_dom = ref(undefined, undefined, '$next_dom')
export const $prev_dom = ref(undefined, undefined, '$prev_dom')
// export const $prev2_dom = ref(undefined, undefined, '$prev2_dom')

export const prev_page_generators = []
export const next_page_generators = []



window.basket = basket
window.$current_dom = $current_dom
window.$next_dom = $next_dom
window.$prev_dom = $prev_dom
window.prev_page_generators = prev_page_generators
window.next_page_generators = next_page_generators

export async function get_translation(text, language) {
    text = text.trim().toLowerCase()
    const response = await api.get('/api/translation', {text, language})
    if (response?.ok) {
        const {data} = response
        response.language = data.language || data.detected
        response.corrected = data[response.language]
        response.typo = response.corrected !== text

        response.inserted = basket.upsert(data, (d) => {
            // basket has hole if some words has been removed
            return d && d.id === data.id
        })
    }
    return response
}

// innerHeight might exclude the area occupied by the soft keyboard
// while there is NO easy way to get height include soft keyboard
// so the initial_height can be used to have the initialHeight
export const initial_height = ref(Math.max(window.innerHeight, window.innerWidth ))
export const initial_width = ref(Math.min(window.innerHeight, window.innerWidth ))

export let algorithm = ref({})

export const db = IndexedDB("translingo", [
    "vocabulary",
    "basket", // the words get from reading articles or other places
    // "learning",
    "progress",
    "mastered",
    "meta",
    "arabic",
    "english",
    "french",
    "german",
    "japanese",
    "russian",
    "spanish",
    "swedish",
    "cache"
], {sandbox});

export function log_flow() {
    const current = $current_dom.value && $current_dom.value.dataset['name']
    const prev = $prev_dom.value && $prev_dom.value.dataset['name']
    const next = $next_dom.value && $next_dom.value.dataset['name']

    const prevs = prev_page_generators.map(({name, generator}) => name).join('; ')
    const nexts = next_page_generators.map(({name, generator}) => name).join('; ')
    console.log({prevs, prev, current, next, nexts})
}
export async function report(event) {

    // enable_report.value = false
    let $element = undefined
    switch (position.value) {
        case "left":
            $element = $left_dom.value
            break;
        case "middle":
            $element = $current_dom.value
            break;
        case "right":
            $element = $right_dom.value
            break;
        default:
            throw {message: `wrong ${position.value}`}
    }

    try {

        const dataUrl = await toPng($element);
        const response = await fetch("/api/report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({image: dataUrl, data: window.data})
        });

        const dialog = Dialog({},
            <form style="display: flex; flex-direction: column; gap: 15px;">
                <h1>Thanks</h1>
                <p>for reporting</p>
                <p>We will handle it asap</p>
                <button onclick={close}>Close</button>
            </form>
        );

        function close(event) {
            dialog.close()
        }

        // if (response.ok) {
        //     alert("Image uploaded successfully!");
        // } else {
        //     alert("Failed to upload image");
        // }
    } catch (error) {
        console.error("Error generating or uploading PNG:", error);
    }
}

window.db = db;