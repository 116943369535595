import api from "./api.jsx";
import {db} from "./store.jsx";
import {get_yyyymmdd, get_yyyymmdd_hhmmss} from "./util.js";

console.log({type: "tracer"});

export async function get_word(language, text) {
    try {
        const cache = await db.get(language, text) || {};
        let word = cache[text];
        if (!word) {
            word = await api.get("/api/word", {language, text});
            await db.put(language, text, word);
        }
        return word;
    } catch (error) {
        return `not able to fetch, might be offline`;
    }
}

export async function get_words(language, texts) {
    try {
        const words = await api.get("/api/words", {language, texts: JSON.stringify(texts)});

        return words;
    } catch (error) {
        return `not able to fetch, might be offline`;
    }
}

export async function get(language, type = "vocabulary", force) {
    try {
        const cache = await db.get(type, language);
        if (force || !cache) {
            const words = await api.get(`/api/${type}`, {language});
            await db.put(type, language, words);

            return words;
        } else {
            return cache || [];
        }
    } catch (error) {
        return `not able to fetch, might be offline`;
    }
}

async function get_basket(language) {
    try {
        const cache_data = await db.get("basket", language) || [];
        const server_data = await api.get(`/api/basket`, {language}) || [];
        if (cache_data.length > server_data.length) {
            const keys = new Map(cache_data.map((word, index) => [word[language].text, index]));
            server_data.forEach(word => {
                const key = word[language].text;
                if (keys.has(key)) {
                    // merge word
                    const exist_word = server_data[keys[key]];

                } else {
                    cache_data.push(word);
                }
            });
            return cache_data;
        } else {
            const keys = new Map(server_data.map((word, index) => [word[language].text, index]));
            cache_data.forEach(word => {
                const key = word[language].text;

                if (keys.has(key)) {
                    // merge word
                    const exist_word = server_data[keys[key]];

                } else {
                    server_data.push(word);
                }
            });
            return server_data;
        }
    } catch (error) {
        return `not able to fetch, might be offline`;
    }
}


const DAY = 24 * 60 * 60 * 1000;
export default async function SpaceRepetitionAlgorithm(db, language, level,
                                                       intervals = [0, 0, 1 * DAY, 2 * DAY, 4 * DAY, 7 * DAY, 14 * DAY, 30 * DAY, 60 * DAY]) {
    const batch_size = 20;
    const vocabulary = await get(language, "vocabulary");
    const basket = await get_basket(language);

    function get_key(word) {
        return word[language].text;
    }

    const basket_map = new Map(basket.map(word => [get_key(word), word]));
    // let basket_keys = Object.keys(basket);
    let basket_index = 0;

    const meta = await db.get("meta", language) || {index: 0, batch_index: 0};
    meta.index = meta.index || 0;
    meta.batch_index = meta.batch_index || 0;

    // ongoing learning words, goes from 0 => N
    // const learning = await db.get("learning", language) || {};
    // const learning_keys = Object.keys(learning).filter(text => !basket.includes(text));
    // the word in the basket will add to the learning queue
    // const learning_length = learning_keys.length;

    // the word already in basket shall be removed to avoid duplicate

    let learning_index = 0;
    const mastered = await db.get("mastered", language) || {};

    const max_stages = intervals.length - 1;

    // function basket_empty() {
    //     return basket.length === 0;
    // }

    async function level_up_vocabulary() {
        meta.index += 200;
        await db.put("meta", language, meta);

        // when level up all the previous learning make no sense (too simple)
        // clear the learning queue
        // learning.length = 0;
        // await db.put("learning", language, learning);
    }


    async function new_from_vocabulary(index) {

        let i = index;
        while (i < vocabulary.length) {
            const text = vocabulary[i];
            i += 1;
            if (mastered.hasOwnProperty(text)) {
                // it's already mastered
                // then we go to next word
                // continue;
            } else {
                return [i, text];
            }
        }
        return [i];
    }

    async function add_to_basket(word) {
        const key = get_key(word);
        if (basket_map.has(key)) {
            // already in basket
        } else {
            basket.push(word);
            basket_map.set(key, word);
            await db.put("basket", language, basket);
        }
        return word;
    }

    async function remove_from_basket(text) {
        console.log({type: "remove from basket", text});
        mastered[text] = {time: get_yyyymmdd_hhmmss()};

        basket_map.delete(text);
        basket.some((word, i) => {
            const key = get_key(word);
            if (key === text) {
                basket.splice(i, 1);
                return true;
            }
        });

        await db.put("basket", language, basket);
        // await db.put("learning", language, learning);
        // await db.put("mastered", language, mastered);
    }

    async function next_batch_in_vocabulary(no_rows = batch_size) {
        const texts = [];

        let result = [meta.batch_index];
        try {
            while (texts.length < no_rows) {
                result = await new_from_vocabulary(result[0]);
                texts.push(result[1]);
            }
        } catch (error) {
            console.log({result, error, texts});
        }

        meta.batch_index = result[0];
        await db.put("meta", language, meta);
        return get_words(language, texts);
    }

    async function need_review(word) {
        const text = word[language].text;
        const due = get_yyyymmdd();
        const progress = await db.get_put_if_not_exist(language, text, {due, stage: 0});

        const review_enough = progress.stage >= max_stages;
        const notdue = progress.due > due;
        const mastered = progress.mastered;
        console.log({english: word.english, review_enough, notdue, due, mastered});
        if (review_enough || notdue || mastered) {
            return undefined;
        } else {
            progress.stage = (progress.stage || 0) + 1;
            const interval = intervals[progress.stage];
            progress.due = (progress.due || due) + interval;
            db.put(language, text, progress);
            return word;
        }
    }

    let previous = undefined;
    const history = [];

    async function next_in_basket() {

        if (basket && basket.length > 0) {
            const length = basket.length;
            let i = basket_index % length;
            do {
                const word = basket[i];
                if (!word) {
                    return undefined;
                }
                if (await need_review(word)) {
                    history.push(word.english);
                    basket_index = (i + 1) % length;
                    const current = word[language].text;
                    console.log(history);
                    previous = current;
                    return word;
                }
                i = (i + 1) % length;
            } while (i !== basket_index);

            console.log("every word in basket has been reviewed");
        }
        return undefined;
    }


    async function commit() {
        await db.put("meta", language, meta);
        await db.put("basket", language, basket);
    }

    let game_basket_index = 0;

    async function next() {
        // go through basket first
        if (game_basket_index < basket.length) {
            const word = basket[game_basket_index];
            game_basket_index += 1;
            return word;
        }
        // get new_from_vocabulary
        else {
            const [index, text] = await new_from_vocabulary(meta.index);
            meta.index = index;
            await get_word(language, text);
            // already learn enough stages the current word
            // assume it mastered as well
        }
    }

    return {
        add_to_basket,
        next_in_basket,
        // basket_empty,
        level_up_vocabulary,
        next_batch_in_vocabulary,
        commit,
        next,
        remove_from_basket,
    };
}

function test() {
    const basket = [
        {
            "swedish": {
                "text": "skiljer",
                "examples": [
                    "det skiljer sig"
                ]
            },
            "english": "differs",
            "chinese": "不同"
        },
        {
            "swedish": {
                "text": "lust",
                "examples": [
                    "har ingen lust"
                ]
            },
            "english": "desire",
            "chinese": "欲望"
        },
        {
            "swedish": {
                "text": "beräknas",
                "examples": [
                    "beräknas vara klart"
                ]
            },
            "english": "is calculated",
            "chinese": "计算"
        },
        {
            "swedish": {
                "text": "syfte",
                "examples": [
                    "syfte med mötet"
                ]
            },
            "english": "purpose",
            "chinese": "目的"
        },
        {
            "swedish": {
                "text": "skott",
                "examples": [
                    "ett skott i mörkret"
                ]
            },
            "english": "shot",
            "chinese": "射击"
        },
        {
            "swedish": {
                "text": "rättigheter",
                "examples": [
                    "mänskliga rättigheter"
                ]
            },
            "english": "rights",
            "chinese": "权利"
        }
    ];

}