import {ref_render} from "nano/nano.jsx";

import MusicPage from "./MusicPage.jsx";

console.log({type: "tracer"})

export default function MusicAlgorithm(context) {
    const props = {data: context.data}
    const me = {update}
    async function update($dom, $right_dom) {
        await ref_render($dom, MusicPage, {props})
    }
    return me
}