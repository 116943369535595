export default function Keyboard() {
    const me = {
        left: {pressed: false},
        right: {pressed: false},
        up: {pressed: false},
        down: {pressed: false},
        space: {pressed: false},
        onkeyup,
        onkeydown
    };


    function onkeydown(event) {
        const {key, keyCode} = event;
        console.log(key, keyCode);
        switch (keyCode) {
            case 32:
                me.space.pressed = true;
                break;
            case 37: // ArrowLeft
            case 65: // a, left
                me.left.pressed = true;
                break;
            case 40: // ArrowDown
            case 83: // s, down
                me.down.pressed = true;
                break;
            case 39: // ArrowRight
            case 68: // d, right
                me.right.pressed = true;
                break;
            case 38: // ArrowUp
            case 87: // w, up
                me.up.pressed = true;
                break;
            default:
        }
    }

    function onkeyup(event) {
        const {key, keyCode} = event;
        console.log(key, keyCode);
        switch (keyCode) {
            case 32:
                me.space.pressed = false;
                break;
            case 37: // ArrowLeft
            case 65: // a, left
                me.left.pressed = false;
                break;
            case 40: // ArrowDown
            case 83: // s, down
                me.down.pressed = false;
                break;
            case 39: // ArrowRight
            case 68: // d, right
                me.right.pressed = false;
                break;
            case 38: // ArrowUp
            case 87: // w, up
                me.up.pressed = false;
                break;
            default:
        }
    }

    return me;
}
