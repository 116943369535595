import {ref} from 'nano/reactive.jsx'

function get_value(name, default_value) {
    const value = localStorage.getItem(name);
    if (value) {
        const type = typeof default_value;
        switch (type) {
            case "string":
                return value;
            case "boolean":
                return value.toLowerCase() === "true";
            case "number":
                return +value;
            default:
                return value;
        }
    } else {
        return default_value;
    }
}

export const app = 'translingo'
const {hash, href} = window.location
export const author = href.includes('author') || localStorage.getItem('author')
export const test = 0;
export const PAGE_DEBUG = false
export const dev = /(localhost)|(192)/.test(window.location.hostname) || localStorage.getItem("dev");
export const theme = ref('light')
export const max_history = 10
export const sandbox = false;
export const max_translate_words = 5;
export const max_translate_characters = 140;

// in 7(word_review_batch_size)
// random pick 5(per_page_words)
// repeat 5(number_matches) times
export const word_review_batch_size = 7
export const number_matches = 5;
export const per_page_words = 5 // dev ? 2 : 5;

export const num_correction_promote_stage = 2
export const per_game_words = 5
export const game_words_interval = dev ? 2 : 10;

export const max_game_time = get_value("max_game_time", 3 * 60 * 1000); // testing 10 seconds :-)
export const show_log = get_value("show_log", false);
export const change_to_disabled_delay = 400;
export const goto_next_page_delay = change_to_disabled_delay + 300;
export const batch_per_level = 3;
export const MAX_WORDS = 5
export const MAX_BASKET_SIZE = 10
export const MAX_ARTICLE = 2

export const word_match_batch_size = word_review_batch_size
export const enable_report = ref(true)