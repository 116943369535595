import {Render} from "nano/nano.jsx";
import "./dialog.css";

console.log({Dialog, type: "tracer"});
export default function Dialog(props, children) {
    const {on_finish = () => {}, style = {}} = props;
    const dialog = Render(() => {
        return (
            <dialog onclose={onclose} class="ndialog" onclick={onclick} style={style}>
                {children}
            </dialog>
        );
    });

    function onclick(event) {
        const {clientX, clientY} = event;
        const {left, top, right, bottom} = dialog.getBoundingClientRect();
        if (clientX < left || clientX > right || clientY < top || clientY > bottom) {
            onclose(event);
        }
    }

    function onclose(event) {
        console.log(event);

        dialog.remove();
        on_finish();
    }

    // const portal = document.getElementById("portal");
    // portal.appendChild(dialog);
    document.body.appendChild(dialog);
    dialog.showModal();
    return dialog
}