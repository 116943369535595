export * from "d3-array";
// export * from "d3-axis";
// export * from "d3-brush";
// export * from "d3-chord";
export * from "d3-color";
// export * from "d3-contour";
// export * from "d3-delaunay";
// export * from "d3-dispatch";
// export * from "d3-drag";
export * from "d3-dsv";
// export * from "d3-ease";
// export * from "d3-fetch";
export * from "d3-force";
export * from "d3-format";
export * from "d3-geo";
export * from "d3-hierarchy";
export * from "d3-interpolate";
export * from "d3-path";
import {path} from "d3-path";

export * from "d3-polygon";
export * from "d3-quadtree";
export * from "d3-random";
export * from "d3-scale";
import * as d3_scale from "d3-scale";

export * from "d3-scale-chromatic";
// export * from "d3-selection";
import * as shape from "d3-shape";

export * from "d3-shape";
export * from "d3-time";
export * from "d3-time-format";
export * from "d3-timer";
// export * from "d3-transition";
// export * from "d3-zoom";


path.prototype.toString = function () {
    // Ref: https://github.com/d3/d3-path/issues/10
    //
    return this._.replace(/\d+\.\d+/g, (s) => parseFloat(s).toFixed(0));
};

export const scale = (function () {
    const all_scales = {
        quantitative: {
            "default": d3_scale.scaleLinear,
            "linear": d3_scale.scaleLinear,
            "log": d3_scale.scaleLog,
            "pow": d3_scale.scalePow,
            "sqrt": d3_scale.scaleSqrt,
            "symlog": d3_scale.scaleSymlog,
            "time": d3_scale.scaleTime,
            "utc": d3_scale.scaleUtc,

        },
        "temporal": {
            "default": d3_scale.scaleUtc,
            "time": d3_scale.scaleTime,
            "utc": d3_scale.scaleUtc,
        },
        "ordinal": {
            "default": d3_scale.scaleOrdinal,
            "ordinal": d3_scale.scaleOrdinal,
            "band": () => d3_scale.scaleBand().paddingInner(0.1),
            "point": d3_scale.scalePoint,
        },
        color: {
            // 'categorical': d3_scale.scale
            "default": d3_scale.scaleQuantize,
            "quantize": d3_scale.scaleQuantize,
            "quantile": d3_scale.scaleQuantile,
            "sequential": d3_scale.scaleSequential,
            "sequential-log": d3_scale.scaleSequentialLog,
            "threshold": d3_scale.scaleThreshold,

        }
    };
    const default_scale = d3_scale.scaleIdentity;
    return (category, type) => {
        const category_scale = all_scales[category];
        if (category_scale) {
            return (category_scale[type] || category_scale["default"])();
        } else {
            return default_scale();
        }
    };
}());

export const curve = (function () {
    const curves = new Map([
        ["basis", shape.curveBasis],
        ["basis-closed", shape.curveBasisClosed],
        ["basis-open", shape.curveBasisOpen],
        ["bundle", shape.curveBundle],
        ["bump-x", shape.curveBumpX],
        ["bump-y", shape.curveBumpY],
        ["cardinal", shape.curveCardinal],
        ["cardinal-closed", shape.curveCardinalClosed],
        ["cardinal-open", shape.curveCardinalOpen],
        ["catmull-rom", shape.curveCatmullRom],
        ["catmull-rom-closed", shape.curveCatmullRomClosed],
        ["catmull-rom-open", shape.curveCatmullRomOpen],
        ["linear", shape.curveLinear],
        ["linear-closed", shape.curveLinearClosed],
        ["monotone-x", shape.curveMonotoneX],
        ["monotone-y", shape.curveMonotoneY],
        ["natural", shape.curveNatural],
        ["step", shape.curveStep],
        ["step-after", shape.curveStepAfter],
        ["step-before", shape.curveStepBefore]
    ]);
    return name => curves.get(name);
}());