import "./Article.css";
import {ref_render} from "nano/nano.jsx";
import {MAX_ARTICLE, MAX_BASKET_SIZE, MAX_WORDS} from "../config.js";
import {ref} from "nano/reactive.jsx";
import Page from "./ArticlePage.jsx";
import {basket, current_progress, progress_total, word_index} from "../store.jsx";


export default function Article(props) {

    let n_articles = 0
    let n_words = 0
    let status_showed = false
    let prompt_showed = false
    const navigate = {
        right: 'ReviewWord',
        next: 'ReviewWord',
    }
    const prompt = ref()
    const me = {
        name: 'Article',
        get_generator,
        goto_next_mode: false,
        revertable: true
    }

    let next_progress = 1



    async function get_generator() {
        const progress = next_progress
        next_progress += 1
        if (progress >= MAX_ARTICLE) {
            me.goto_next_mode = 'Word'
            // reset word_index
            word_index.value = 0
        }


        return {name: 'Article', generator: article_generator}

        function pre_hook(context) {
            progress_total.value = MAX_ARTICLE
            current_progress.value = progress
        }

        async function article_generator($dom) {
            await ref_render($dom, Page, {
                pre_hook,
                dataset: {
                    name: `article_${n_articles}`,
                    component: 'Article'
                }})
            n_articles += 1
        }
    }

    return me
}
