import "./Word.css";
import {ref_render} from "nano/nano.jsx";
import {ref} from 'nano/reactive.jsx'

import Page from "./WordPage.jsx";
import {basket, algorithm, page_theme, next_progress, current_progress, batch, word_index, header} from "../store.jsx";
import {word_review_batch_size} from "../config.js";
import Summary from "./WordSummary.jsx";
import api from "../api.jsx";

console.log({type: "tracer"})
export default function WordAlgorithm(props) {
    let last_page = undefined

    const me = {
        name: 'Word',
        get_generator,
        goto_next_mode: false, // string for named mode
    }
    const navigate = {
        right: 'ReviewWord',
        next: 'ReviewWord',
    }
    batch.value = [] // clear batch, as there is no review has been done yet

    function get_next_word() {
        let index = word_index.value
        let len = basket.length
        // as the basket will have whole due to removal
        // loop it to get next not removed word
        do {
            const word = basket.value[index]
            if (word) {
                word_index.value = index + 1
                return [index, word]
            }
            index = index + 1
        } while (index < len) // (index !== word_index.value)

        return [undefined, undefined]
    }

    async function get_generator() {
        let goto_next_mode = false
        const all_basket_reviewed = basket.value.length <= word_index.value


        if (all_basket_reviewed) {
            const message = {
                limit: meta.value.limit,
                offset: meta.value.offset
            }
            const response = await api.get("/api/basket", message);
            const has_more_word_in_server = response.rows && response.rows.length > 0
            if (has_more_word_in_server) {
                meta.value.limit = response.limit || meta.value.limit
                meta.value.offset = response.offset || meta.value.offset
                basket.value.push(...response.rows)
            } else {
                // all words in basket has been reviewed
                goto_next_mode = "Article"
            }
        }

        const [my_word_index, word] = get_next_word()

        if (word) {
            batch.push(word)
            goto_next_mode = batch.length && (batch.length % word_review_batch_size) === 0
            // add index into the wod in basket
            basket.value.forEach((w, _index) => {
                if (w) {
                    w._index = _index
                    w._corrected = w._corrected || 0
                }
            })
        } else {
            goto_next_mode = "Article"
        }


        const progress = next_progress.value
        next_progress.value = next_progress.value + 1

        return {name: word?.text || 'summary', generator: word_generator}
        async function word_generator($dom) {
            function pre_hook(context) {
                page_theme.value = 'light'
                algorithm.value.swipe.use(true)

                current_progress.value = progress
                me.goto_next_mode = goto_next_mode
                header.value = true
                // console.log({type: "pre", text: word?.text, progress})
            }

            function post_hook(context) {
            }

            if (word) {
                const contexts = {
                    props: {
                        word,
                        word_index: my_word_index,
                    },
                    pre_hook,
                    post_hook,

                    navigate,
                    total: word_review_batch_size,
                    dataset: {
                        name: word.text,
                        component: 'Word'
                    }
                }
                await ref_render($dom, Page, contexts)
                last_page = 'word'
            } else if (last_page === 'summary') {
                // already got summary page, not repeating add again
            } else {
                await ref_render($dom, Summary, {
                    props: {
                        type: 'empty'
                    },
                    pre_hook,
                    reupdate: true,
                    dataset: {
                        name: `__summary__`,
                        component: 'Word'
                    }
                })
                last_page = 'summary'
            }
        }
    }

    return me
}
