import "./Article.css";
import For from "nano/For.jsx";
import Show from "nano/Show.jsx";
import {$active_learning_language, $app_language, get_translation} from "../store.jsx";
import Spanize from "nano/components/Spanize.jsx";
import {computed, on_mounted, ref} from "nano/reactive.jsx";
import safe_fetch from "nano/lib/safe_fetch.js";
import api from "../api.jsx";
import {playByText} from "../audio.js";
import PromptBar from "../components/PromptBar.jsx";

console.log({type: "tracer"})
// the browser built-in tts engine does not work well for read a whole paragraph
// while only works well for a single word
// TODO, for reading whole paragraph, will need to generate audio with better tool
function Chapter(props = {}, children = []) {
    const {chapter, chapter_ruby, language, clazz} = props

    if (chapter_ruby) {
        return (
            <section class="chapter">
                <p class="paragraph_header"><b><Spanize ruby={chapter_ruby.title} clazz={clazz} language={language}/></b></p>
                <p class="paragraph">
                    <For _each={chapter_ruby.sentences}>{(sentence, i) => {
                        return <Spanize ruby={sentence} clazz={clazz}/>
                    }}</For>
                </p>
            </section>
        )
    } else {
        return (
            <section class="chapter">
                <p class="paragraph_header"><b><Spanize text={chapter.title} clazz={clazz} language={language}/></b></p>
                <p class="paragraph">
                    <Spanize text={chapter.sentences.join('')} clazz={clazz} language={language}/>
                </p>
            </section>
        )
    }
}

export default function ArticlePage(props = {}, children = []) {
    const {
        use_prompt=false
    } = props;

    // console.log(new Error().stack);

    const [$title, $chapters, $title_ruby, $chapters_ruby] = [ref(''), ref([]), ref(), ref()]
    const clazz = 'readable'

    if (use_prompt) {
        $title.value = 'Your Article'
    } else {
        on_mounted(get_article)
    }
    const show_prompt = ref(use_prompt)

    async function get_article(topic) {
        const language = $active_learning_language.value
        const resp = await safe_fetch('/api/article', {}, {topic, language})
        console.log(resp)

        $title.value = resp.title
        $chapters.value = resp.chapters
        $title_ruby.value = resp.title_ruby
        $chapters_ruby.value = resp.chapters_ruby

        show_prompt.value = false
    }


    // async function on_speak(event) {
    //     event.stopPropagation()
    //     const {target, currentTarget} = event
    //     console.log(target, currentTarget)
    //
    //     await play_object($chapters.value)
    // }
    let timeout_tooltip = undefined
    function onclick(event) {
        const {target, currentTarget} = event
        console.log(target, currentTarget)
        if (target.classList.contains('readable') || target.nodeName === 'RUBY') {

            const text = target.textContent.replaceAll(/\(.*?\)/g, '')
            if (timeout_tooltip) {
                clearTimeout(timeout_tooltip)
            }

            function show_tooltip(resp) {
                const {data} = resp
                console.log(data)
                const translation = data[$app_language.value]
                if (translation) {
                    const rect = target.getBoundingClientRect()
                    const $tooltip = document.getElementById('tooltip')
                    const font_size = Number(window.getComputedStyle(target).fontSize.split('.').at(0))
                    const right_most = (target.parentElement.getBoundingClientRect().right - rect.right) < 5
                    const width = right_most ? rect.width : (rect.width - font_size)
                    const left = `${rect.left + width / 2}px`
                    const top = `${rect.top + rect.height + 5}px`
                    $tooltip.style.left = left
                    $tooltip.style.top = top
                    $tooltip.innerText = translation

                    timeout_tooltip = setTimeout(() => {
                        $tooltip.style.left = ''
                        $tooltip.innerText = ''
                    }, 3000)

                }
            }

            get_translation(text, $active_learning_language.value).then(show_tooltip)
            // play(text, entry_language.value)
            playByText($active_learning_language.value, text)
        }
    }


    const style = {
        "display": "flex",
        "flex-direction": "column",
        // this will cause the scrollHeight not able to accomdate all children, so not able to scroll all the way to top
        /* justify-content: center; */
        "align-items": "center",
    }
    return (
        <div class={`page article ${$active_learning_language.value}`} style={style} onclick={onclick}>
            {/* SpeechSynthesisUtterance for paragraph is TERRIBLE. TODO Replace to other TTS*/}
            {/*<div class="speak_button" onclick={on_speak} >*/}
            {/*    <IconVolume2/>*/}
            {/*</div>*/}
            <section class="title">
                {/*<h1><Spanize text={$title}  clazz={clazz}/></h1>*/}
                <h2><Spanize text={$title} ruby={$title_ruby} clazz={clazz}  language={$active_learning_language.value}/></h2>
            </section>
            <Show when={$chapters_ruby}>
                <div>
                    <For _each={$chapters_ruby}>{(chapter_ruby, i) => {
                        return <Chapter chapter_ruby={chapter_ruby} clazz={clazz}  language={$active_learning_language.value}></Chapter>
                    }}</For>
                </div>
            </Show>
            <Show when={computed(() => !$chapters_ruby.value)}>
                <div>
                    <For _each={$chapters}>{(chapter, i) => {
                        return <Chapter chapter={chapter} clazz={clazz}  language={$active_learning_language.value}></Chapter>
                    }}</For>
                </div>
            </Show>

            <Show when={show_prompt}>
                <PromptBar on_finish={get_article} show={show_prompt}></PromptBar>
            </Show>
        </div>
    );
}