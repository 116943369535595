console.log("API");



function safe_params(obj) {
    const params = new URLSearchParams();
    Object.entries(obj).forEach(function set_props_if_has_value([name, value]) {
        if (value) {
            params.set(name, value);
        }
    });
    if (!obj.hasOwnProperty("token")) {
        params.set("token", localStorage.getItem("token") || "");
    }
    return params;
}


function invoke(method) {
    // cache_strategy: [network_only, stale_revalidate]
    return async function _fetch(url, params = {}, cache_strategy='network_only') {

        const full_url = `${url}?${safe_params(params).toString()}`;
        async function fetch_raw() {
            const response = await fetch(full_url, {
                method,
                headers: {"Accept-Encoding": "gzip, deflate, br"}
            });
            const json = await response.json();
            console.log(url, json);
            return json;
        }
        try {
            if (cache_strategy === "stale_revalidate") {
                const cache_result = await db.get("cache", url)

                if (cache_result) {
                    // already in the cache, in the background, try fetch again and update cache
                    // and return first for fast response
                    fetch_raw().then(async json => {
                        await db.put('cache', url, json)
                    }).catch(console.error)
                    return cache_result
                } else {
                    const json = await fetch_raw()
                    await db.put('cache', url, json)
                    return json
                }
            } else {
                return await fetch_raw();
            }
        } catch (error) {
            console.log(url, params, error);
        }
    };
}

export default {
    get: invoke("get"),
    post: invoke("post"),
    put: invoke("put"),
    delete: invoke("delete"),
}
