import For from "nano/For.jsx";
import {computed, ref, watch1} from "nano/reactive.jsx";

import "./Translator.css";
import IconMessageSend from "solidjs-feather/IconMessageSend.jsx";
import api from "../api.jsx";
import {play} from "../audio.js";
import {get_lang_flag} from "nano/lib/unicode.js";
import {$current_dom, $left_dom, $next_dom, $right_dom, basket, header} from "../store.jsx";
import {language_names} from "../../common/language.js";

export const max_translate_words = 5;
export const max_translate_characters = 140;

const NAME = 'Translator'
console.log({type: 'tracer'})


export default function Translator(props={}, children=[]) {
    const chats = ref([
        {
            from: 'user',
            messages: [
                {text: 'Hello'},
                {text: `It's me`}
            ]
        },
        {
            from: 'bot',
            messages: [
                {text: "Hej Hej"},
                {text: `How are you?`}
            ]
        }
    ])

    const $translator_content = ref()
    const exceed_number_of_characters = "Max 140 characters";
    const exceed_number_of_words = "Max 5 words";
    const message = ref("");
    const trimmed_message = computed(() => message.value.toLowerCase().trim());
    const words = computed(() => {
        return trimmed_message.value.split(/[\s\t,:;\\.-=]+/).map(
            word => word.trim()).filter(
            word => word !== "");
    });
    const message_bar_warning = computed(() => {
        if (trimmed_message.value.length > max_translate_characters) {
            return exceed_number_of_characters;
        }
        if (words.value.length > max_translate_words) {
            return exceed_number_of_words;
        }
        return "";
    });
    const warning = computed(() => message_bar_warning.value !== "");
    const style = {
        display: computed(() => message.value ? "flex" : "none"),
        opacity: computed(() => message.value ? 1 : 0),
    };

    function play_sound(message) {
        if (message.language) {
            return play(message.text, message.language.value || message.language);
        } else {
            return ref(false);
        }
    }

    function Chat(props, children) {
        const {from, messages} = props
        const last_message = messages.length - 1;
        return (
            <div class={{chat: true, [from]: true}}>
                <For _each={messages}>{(message, i) => {
                    if (message.type === "processing") {
                        return (
                            <div class={{message: true, processing: true}}>
                                <span style={{"--i": 0}}>•</span>
                                <span style={{"--i": 1}}>•</span>
                                <span style={{"--i": 2}}>•</span>
                            </div>
                        );
                    } else {
                        function ontouchstart(event) {
                            event.preventDefault();
                            // onclick(event)
                            play_sound(message);
                        }

                        function onclick(event) {
                            if (message.icon) {
                                play_sound(message);
                            }
                        }

                        return (
                            <div class={{message: true, last: i === last_message}}
                                 data-action="speak"
                                 data-language={message.language}
                                 data-text={message.text}
                                 onclick={onclick}
                            >
                                <span class="language_icon" style={{display: message.icon ? "inline" : "none"}}>{message.icon || ""} </span>
                                <span>{message.text}</span>
                            </div>
                        );
                    }
                }}</For>
            </div>
        )
    }

    async function onkeydown(event) {
        const {which, key, keyCode, ctrlKey, shiftKey, altKey, target: $element} = event;
        // console.log({
        //     which,
        //     key, keyCode, ctrlKey, shiftKey, altKey
        // });


        if (key === "Enter") {
            await send(event);
        }
    }
    async function send(event) {
        const text = words.value.join(" ");
        const source_language_flag  = ref()

        const message_sent = {text, language: ref(), icon: ref()};
        const messages = ref([{text: "...", type: "processing"}]);
        chats.value = [
            ...chats.value,
            {from: 'user', messages: [message_sent]},
            {
                from: "bot",
                messages
            }
            ]
        message.value = ''
        scroll_to_bottom()
        const response =  await api.get('/api/translation', {text})


        if (response?.ok) {
            const {data} = response
            const language = data.language
            message_sent.language.value = language
            message_sent.icon.value = `${get_lang_flag(language)} `
            const inserted = basket.upsert(data, (d) => d.id === data.id)
            messages.value = row_to_messages(data)
            scroll_to_bottom()
            if (inserted) {
                await algo.reupdate_middle($current_dom)
            }
            console.log(data)
        } else {
        //     TODO handle error
        }
    }

    function row_to_messages(data) {
        const {language} = data
        return Object.entries(data).filter(([lang, _text]) => {
            return lang !== language && language_names.includes(lang)
        }).map(([language, text]) => {
            return {text, language, icon: get_lang_flag(language)}
        })
    }

    setTimeout(() => {
        watch1(meta, async (new_value, old_value) => {
            const {recent_translations} = new_value
            if (recent_translations && recent_translations.length > 0) {
                recent_translations.forEach(data => {
                    const {text, language} = data
                    const from_user = {
                        from: 'user',
                        messages: [{text, language, icon: get_lang_flag(language)}]
                    }


                    const bot_messages = row_to_messages(data)
                    const from_bot = {from: 'bot', messages: bot_messages}
                    chats.value.push(from_user, from_bot)
                })
                chats.trigger()
            }
        })
    }, 300)


    function scroll_to_bottom(event) {
        const $element = $translator_content.value
        if ($element) {
            setTimeout(() => {
                console.log('scroll', $element.scrollHeight)
                // $parent.scrollTop = $parent.scrollHeight
                $element.scrollTo({
                    top: $element.scrollHeight,
                    behavior: "smooth",
                })
            }, 100)
        }
    }

    return (
        <div class="page translator">
            {/*<Header></Header>*/}
            {/*<div class="header_placeholder"></div>*/}
            <div class="translator_content" _ref={$translator_content}>
                <For _each={chats} _scroll_bottom="true">{(chat, index) => {
                    const result = <Chat from={chat.from} messages={chat.messages}></Chat>
                    return result;
                }}</For>
            </div>
            <div class="message_bar">
                <div class="message_sending">
                    <input type="text" model={message}
                           class={{message_sending_input: true, warning}}
                           onkeydown={onkeydown} id="message_input"
                           autocomplete="off"
                           spellcheck={false}/>
                    <span class="message_sending_icon" style={style} onclick={send}><IconMessageSend/></span>
                </div>
                <div class="message_bar_warning">{message_bar_warning}</div>
            </div>
        </div>
    )
}