import {per_game_words, per_page_words, word_match_batch_size} from "../config.js";
import {algorithm, page_theme, get_audioContext, basket, current_progress, header, next_progress} from "../store.jsx";
import {ref_render} from "nano/nano.jsx";
import Game from './Game.jsx'

import {AsyncAudio, AsyncAudioDecoded} from "nano/lib/Async.js";

console.log({type: "tracer"})

let game_index = 0

// using Audio will make canvas rendering call jittering
// most likely due to gc pause
// Solution 1: using native ios api play audio
// Solution 2: using DOM node instead of canvas
const audio = AsyncAudio("/Prototype.aac");

// const audio = AsyncAudioDecoded(audioContext, "/Prototype.aac");

export default function GameAlgorithm(page) {

    let prev_header
    const me = {
        name: 'Game',
        get_generator,
        goto_next_mode: true}
    //
    // async function init($current) {
    //     return await update_dom($current)
    // }
    //
    // async function update_dom($dom, page, hook, ) {
    //     $dom.value = await async_render(page)
    //     $dom.value.hook = hook
    //     $dom.value.navigate = navigate
    // }
    //

    // const audio = AsyncAudio("/Prototype.aac");


    const navigate = {
        right: 'Game',
        next: 'Game'
    }

    async function get_generator() {
        let initial_run = true
        // basket has holes due to deletion
        // const words = basket.value.slice(game_index, game_index + per_game_words).filter(v => v)
        const words = basket.value.filter(v => v)
        const props = {words, pointer: algorithm.pointer, audio}

        return {name: 'game', generator: game_generator}
        async function game_generator($dom) {
            const post_hook = () => {
                if (initial_run) {
                    game_index = game_index + per_game_words
                }
                me.goto_next_mode = true
                header.value = prev_header
                algorithm.value.swipe.use(true)
            }
            const pre_hook = () => {
                prev_header = header.value
                header.value = false
                page_theme.value = 'dark'

                // clear the progress
                current_progress.value = 1
                next_progress.value = 1
            }

            const contexts = {props, post_hook, pre_hook, navigate}

            await ref_render($dom, Game, contexts)
            return false
        }
    }
    return me
}
