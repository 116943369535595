import For from "nano/For.jsx";
import "./MatchWord.css";
import {clone_object, shuffler} from "nano/lib/_.js";
import {per_page_words} from "../config.js";
import {computed, ref} from 'nano/reactive.jsx'
import {algorithm, basket} from "../store.jsx";
import {adjust_element_size} from 'nano/dom.jsx'
import IconArrowUp from "solidjs-feather/IconArrowUp.jsx";
console.log(MatchWordPage.name, {type: 'tracer'})

const samples = [
    {
        english: 'hello',
        swedish: 'hej'
    },
    {
        english: 'good morning',
        swedish: 'god morgon'
    },
    {
        english: 'how are you doing',
        swedish: 'hur mår du'
    },
    {
        english: 'bye bye',
        swedish: 'hej då'
    },
    {
        english: 'have a good',
        swedish: 'har en bra dag'
    },
]


export default function MatchWordPage(props={}, children=[]) {
    const {words=samples} = props

    const left_words = shuffler()(clone_object(words));
    const right_words = shuffler()(clone_object(words));
    let $left_selected = ref()
    let $right_selected = ref()
    let $left_matched = ref()
    let $right_matched = ref()

    function all_corrected() {
        for (let i = left_words.length - 1; i >= 0; i -= 1) {
            if (!left_words[i].matched) {
                return false;
            }
        }
        return true;
    }

    function onclicker($selected, word) {
        return function (event) {
            // const word = event.currentTarget
            if ($selected.value === word) {
                $selected.value = undefined
            } else {
                $selected.value = word
                const both_selected = $left_selected.value && $right_selected.value 
                if (both_selected) {
                    const correct = $left_selected.value.english === $right_selected.value.english
                    const left_index = $left_selected.value._index
                    const right_index = $right_selected.value._index
                    
                    if (correct) {
                        const $left = $left_selected.value.$ref.value
                        const $right = $right_selected.value.$ref.value
                        $left_selected.value.matched = true
                        $right_selected.value.matched = true
                        $left_selected.value = undefined
                        $right_selected.value = undefined

                        $left.classList.add('matched')
                        $right.classList.add('matched')
                        basket.value[left_index]._corrected += 1
                        if (navigator.vibrate) {
                            navigator.vibrate(100)
                        }
                        setTimeout(() => {
                            $left.classList.remove('matched')
                            $right.classList.remove('matched')
                            $left.classList.add('disabled')
                            $right.classList.add('disabled')
                            $left.onclick = undefined
                            $right.onclick = undefined
                            if (all_corrected()) {
                                setTimeout(() => {
                                    algorithm.value.onswipeup(event)
                                }, 500)
                            }
                        }, 400)
                    } else {
                        basket.value[left_index]._corrected -= 1
                        basket.value[right_index]._corrected -= 1
                    }
                }
            }
        }
    }

    const padding = 19.2
    const gap = 19.2
    const width = (innerWidth - 2 * padding + gap) / 2
    const max_font_size = 24;
    const adjust_size = adjust_element_size({max_font_size, width})
    async function on_continue(event) {
        algorithm.value.onswipeup(event)
    }

    const left_language = $active_learning_language.value
    const right_language = $app_language.value

    return (
        <div class="page with_header_footer">
            {/*<div class="header">*/}
            {/*    /!*<div><progress value={progress} max={total}></progress></div>*!/*/}
            {/*    <h3>MATCH WORD</h3>*/}
            {/*    <div>Tap to match pairs</div>*/}
            {/*</div>*/}
            <div class="match">
                <div class={`pane left ${left_language}`}>
                    <For _each={left_words}>{(word, index) => {
                        const selected = computed(() => $left_selected.value === word)
                        word.$ref = ref()
                        return <div  _ref={word.$ref} onclick={onclicker($left_selected, word)}
                                     on_mounted={adjust_size}
                                     class={{word: true, selected}}>{word[left_language]}</div>
                    }}</For>
                </div>
                <div class={`pane right ${right_language}`}>
                    <For _each={right_words}>{(word, index) => {
                        const selected = computed(() => $right_selected.value === word)
                        word.$ref = ref()

                        return (
                                <div  _ref={word.$ref} onclick={onclicker($right_selected, word)}
                                      on_mounted={adjust_size}
                                      class={{word: true, selected}}>{word[right_language]}</div>
                        )
                    }}</For>
                </div>
            </div>
            <div class="footer">
                <button class="continue_button" style="flex: 1; "
                        onclick={on_continue}>
                    <span style="font-size: 1.3rem; position: relative; top: 3px;"><IconArrowUp/></span>
                    <span></span><span>CONTINUE</span>
                </button>
            </div>
        </div>
    )
}