

import {word_review_batch_size} from "./config.js";
import {ref_render, Render} from "nano/nano.jsx";
import {$current_dom, $next_dom, $right_dom, meta, prev_page_generators} from "./store.jsx";
import Game from "./game/GameAlgorithm.js";
import Story from "./story/StoryAlgorithm.js";
import Vocabulary from './vocabulary/VocabularyAlgorithm.js'
import Welcome from "./page/Welcome.jsx";
import Word from "./word/WordAlgorithm.js";
import Article from "./article/ArticleAlgorithm.js";
import Recorder from './recorder/RecorderAlgorithm.js'
import Music from './music/MusicAlgorithm.js'
import Match from './match/MatchAlgorithm.js'
import Editor from './editor/EditorAlgorithm.js'
import Wordnet from './wordnet/WordnetAlgorithm.js'

import Heritage from './heritage/Heritage.jsx'
import Options from "./page/Options.jsx";
import Translator from './page/Translator.jsx'
import Photo from "./page/Photo.jsx";
import WordValidator from "./page/WordValidator.jsx";

console.log({type: "tracer"})
export default function Algorithm(props={}, children=[]) {
    const me = {
        ...props,
        sequence: 0,
        update_page,
        reupdate_if_needed,
        init_update,
        peak_update_next,
        Mode: undefined,
        current_mode: undefined,
        next_mode: undefined,
        get_Page,
        mode_index: 0
    }

    const Modes = {Welcome, Word, Wordnet, Article, Story, Vocabulary, Recorder, Match, Editor, Game}
    const Pages = {Heritage, Options, Photo, Translator, WordValidator}

    function get_Page(name) {
        return Pages[name]
    }
    function get_next_mode() {

        const page = meta.value.pages[me.mode_index]
        me.mode_index = (me.mode_index + 1) % meta.value.pages.length
        const Mode = Modes[page.name]
        if (Mode) {
            return Mode(page, me)
        } else {
            console.error(`${page.name} not defined yet`)
        }
    }

    function push_prev(new_current) {
        if (prev_page_generators.length > word_review_batch_size) {
            prev_page_generators.shift()
        }
        prev_page_generators.push(new_current)
    }

    async function init_update() {
        me.mode = get_next_mode()
        const new_current = await me.mode.get_generator()
        await new_current.generator($current_dom)
        push_prev(new_current)

        const pre_hook= $current_dom.value.pre_hook
        if (pre_hook) {
            pre_hook()
        }

        // it's the first $next_dom
        await peak_update_next()
    }

    async function peak_update_next(revert) {
        if (me.next_mode) {
            if (revert && me.next_mode.revertable) {
                me.next_mode = me.mode
            }
        }
        else {
            me.next_mode = me.mode
        }

        if (me.next_mode && me.next_mode.goto_next_mode) {
            if (typeof me.next_mode.goto_next_mode === "string") {
                // named next mode
                const name = me.next_mode.goto_next_mode
                const Mode = Modes[name]
                if (Mode) {
                    me.next_mode = Mode({name}, me)
                } else {
                    console.error(`${name} not defined yet`)
                }
            } else {
                // sequential next mode
                me.next_mode = get_next_mode()
            }
        }
        const new_next = await me.next_mode.get_generator()
        await new_next.generator($next_dom)

        push_prev(new_next)
    }

    async function reupdate_if_needed() {
        if ($current_dom.value?.reupdate) {

            const new_current = await me.mode.get_generator()
            await new_current.generator($current_dom)
            push_prev(new_current)

            me.mode.goto_next_mode = false

            await peak_update_next(true)
        } else if ($next_dom.value?.reupdate) {
            await peak_update_next(true)
        }
    }

    async function update_page($dom, name, contexts = {}) {
        const Page = Pages[name]
        $dom.value = Render(() => Page(contexts.props || {}))
    }

    return me
}