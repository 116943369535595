import For from "../For.jsx";
import {computed} from "../reactive.jsx";

const NAME = "Spanize";
console.log(NAME, {type: "tracer"});
// const regex = /([ \t\n\r\f\v!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
const regex = /([ \t\n\r\f\v!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
const symbol_set = new Set('.,;:?!'.split(''))
const symbol = /[.,;:?!]/;

// "hello,world" =>
// <span>
//      <span>hello</span>
//      <span>, </span>
//      <span>world</span>
// </span>
export default function Spanize(props = {}, children=[]) {
    const {text = ''} = props
    // Regular expression to match spaces and symbols

    const words = computed(() => {
        const v = (text || {}).hasOwnProperty('value') ? text.value : text
        const parts =  (v || '').split(regex).filter(part => part.trim())
        // merge symbol to preview word
        for (let i = parts.length - 1; i > 1; i -= 1) {
            const part = parts[i]
            if (symbol_set.has(part)) {
                parts.splice(i, 1)
                parts[i - 1] = parts[i - 1] + part
            }
        }

        return parts.map(word => symbol_set.has(word) ? `${word} ` : word)
    });
    const last_index = words.length - 1


    function need_space(word, i) {
        if (i >= last_index) {
            return false
        } else {
            const next = words[i + 1]
            return !symbol.test(next)
        }
    }

    return (
        <span>
            <For _each={words}>{(word, i) => {
                return <span>{word} </span>
                // return need_space(word, i) ? <span>{word} </span> : <span>{word}</span>
            }}</For>
        </span>)
}