import For from "nano/For.jsx";
import Show from "nano/Show.jsx";
import If from "nano/If.jsx";
import "./Word.css";
import {get_lang_flag} from "nano/lib/unicode.js";
import {language_names} from "../../common/language.js";
import {play, playByText} from "../audio.js";
import {adjust_element_size} from 'nano/dom.jsx'
import {computed, on_mounted, ref} from 'nano/reactive.jsx'
import api from '../api.jsx'
import IconRefreshCw from "solidjs-feather/IconRefreshCw.jsx";
import {$languages, algorithm, basket, batch, current_progress} from "../store.jsx";
import Footer from "../components/Footer.jsx";

const NAME = "ReviewWord";
console.log(NAME, {type: "tracer"});

export default function ReviewWordPage(props = {}, children = []) {
    const {
        word = {
            id: 23,
            language: 'english',
            translations: {
                "english": "Hello",
                "chinese": "你好",
                "swedish": "Hej Hej",
            }
        },
        grid= 3 * 3 - 1,
        word_index,
    } = props;

    const LINE_LENGTH = 20
    const gap = computed(() => {
        const lines = $languages.value.map(lang => word[lang] % LINE_LENGTH + 1)
        const total_lines = lines.reduce((acc, curr) => acc + curr, 0)
        return `${Math.max(40 - total_lines * 0.5, 5)}px`
    })
    const max_font_size = Math.max(28 - $languages.value.length * 0.7, 16)

    // on_mounted( async () => {
    //     word.stage = 2
    //     await api.put('/api/basket', {word: to_base64(word), promoted: true})
    // })


    const {detail = {}} = word

    const image = ref(word.images?.at && word.images?.at(0))
    const src = computed(() => image.value?.filename && `/images/${image.value?.filename}`)
    const has_image = computed(() => {
        const filename = image?.value?.filename || image.value
        return src.value && filename && !filename.includes('no_image')
    })
    let all_images = undefined
    const show_refresh = ref(false)
    let index = 0
    const images = ref([])
    function refresh(event) {
        const imgs = all_images.slice(Math.min(all_images.length - grid, index) , index+grid);
        if (imgs.length > 0) {
            images.value = [...imgs, '/no_image.png']
        }
        index = (index + grid ) % all_images.length
        // if (index > all_images.length) {
        //     show_refresh.value = false
        // }
    }

    on_mounted(async () => {
        if (image.value) {

        } else {
            try {
                const resp = await api.get("/api/pixabay", {text: word.english});
                all_images = resp.hits // .map(hit => hit.webformatURL);
                const imgs = all_images.slice(0, grid);
                if (imgs.length > 0) {
                    images.value = [...imgs, {webformatURL: '/no_image.png'}]
                }
                index = grid;
                show_refresh.value = all_images.length > grid;
                // images.value = hits.map(hit => hit.webformatURL)
                console.log(images.value);

            } catch (error) {
                console.error(error, word.english);
            }
        }
    })
    // const img = `/images/${word.english.replaceAll(' ', '_')}.jpg`
    // const style = `background-image: url(/images/${word.english.replaceAll(' ', '_')}.jpg)`

    // there are more items than translation
    const items = computed(() => Object.entries(word).filter(([lang, text]) => $languages.value.includes(lang)))

    if (detail.pronunciation) {

    }

    async function set_image(event, _image, i) {
        const url = _image.webformatURL
        image.value = {}
        if (i === images.value.length - 1) {
            image.value = await api.post('/api/translation/image', {id: word.id, name: 'no_image.jpg', text: word.english})

        } else {
            image.value = await api.post('/api/translation/image', {id: word.id, url, text: word.english})
        }
        show_refresh.value = false
        word.images = [image.value]
    }

    async function remove(event) {
        basket.value[word_index] = undefined
        prev_page_generators.splice(prev_page_generators.length - 2, 1)
        algorithm.value.remove(event)
        batch.pop()
        console.log(batch)

        await api.delete('/api/basket/', {id: word.id, text: word.text, language: word.language})
    }

    async function on_continue(event) {
        algorithm.value.onswipeup(event)
    }



    return (
        <div class="page with_header_footer" >
            <If when={has_image} description={`image-${word.text}`}>
                <div class="image_container" style="width: 100%; height: min(300px, 30vh)">
                    <img src={src}></img>
                </div>
            </If>

            <Show when={computed(() => !image.value && images.value.length > 0)}>
                <div>
                    <div class="grid_3x3" style="width: 100%; height: 300px; gap: 2px">
                        <For _each={images}>{(image, i) => {
                            return (
                                <div class="image_container" onclick={(event) => set_image(event, image, i)}>
                                    <img src={image.previewURL || image.webformatURL} ></img>
                                </div>)
                        }}</For>
                    </div>
                    <div style="display: flex; justify-content: center; padding-top: 10px;">
                        <Show when={show_refresh}>
                            <span onclick={refresh} class="icon_label">
                                <IconRefreshCw/>
                                {/*<span>Change Batch</span>*/}
                            </span>
                        </Show>
                    </div>
                </div>
            </Show>

            <div class="review_word"  style={{gap}}>
                <For _each={items}>{(item, index) => {
                    const [language, text] = item;
                    const flag = `${get_lang_flag(language)} `;
                    function onclick(event) {
                        play(text, language)
                        // playByText(language, text)
                    }
                    const ruby = word[`${language}_ruby`]
                    if (ruby) {
                        return (
                            <div onclick={onclick} on_mounted={adjust_element_size({max_font_size})}>
                                <span>{flag}</span>
                                <span _html={ruby} class={`${language}`}></span>
                            </div>);
                    } else {
                        return (
                            <div onclick={onclick} on_mounted={adjust_element_size({max_font_size})}>
                                <span>{flag}</span>
                                <span class={`${language}`}>{text}</span>
                            </div>);
                    }
                }}</For>
            </div>

            <Footer remove={remove} on_continue={on_continue}/>
        </div>
    );
}