import {async_wrap} from "./lib/Async.js";

let context = [];
let current_effect = undefined
console.log("reactive");

function cleanup(observer) {
    // console.log("cleanup");
    for (const fn of observer.cleanups) {
        fn();
    }

    for (const dep of observer.dependencies) {
        dep.delete(observer);
    }
    observer.dependencies.clear();
}

function subscribe(observer, subscriptions) {
    // console.log("subscribe");
    subscriptions.add(observer);
    observer.dependencies.add(subscriptions);

    if (observer.set_remover) {
        observer.set_remover(() => {
            subscriptions.delete(observer)
            observer.dependencies.delete(subscriptions)
        })
    }
}

export function is_ref(v) {
    return typeof v === "object" && v.__type === "ref";
}

export function not_ref(v) {
    return !is_ref(v)
}

export function ref(value, $el, name) {
    const subscriptions = new Set();

    function remove(item, index) {
        if (Array.isArray(value)) {
            // check d != v, if they are the same reference
            const new_value = value.filter((d, i) => item !== d);
            console.log({item, index, value, new_value});
            target.value = new_value;
        } else {
            throw {error: "append value to ref only make sense when it's array", value, v};
        }
    }

    function append(item_value) {
        if (Array.isArray(value)) {
            target.value = [...target.value, item_value];
        } else {
            throw {error: "append value to ref only make sense when it's array", value, v: item_value};
        }
    }

    function push(item_value) {
        if (Array.isArray(value)) {
            target.value.push(item_value)
            trigger()
        } else {
            throw {error: "append value to ref only make sense when it's array", value, v: item_value};
        }
    }

    // append a raw value as ref
    function append_raw(v) {
        append(ref(v));
    }

    function set(k, v) {
        if (typeof value === "object") {
            target.value = {...target.value, [k]: v};
            trigger()
        } else {
            throw {error: "append value to ref only make sense when it's array", value, k, v};
        }
    }

    // set a raw value, will set as ref if not exist
    function set_raw(k, v) {
        if (typeof value === "object") {
            const current = value[k];
            if (is_ref(current)) {
                current.value = v;
            } else {
                target.value = {...target.value, [k]: ref(v)};
            }
        } else {
            throw {error: "append value to ref only make sense when it's array", value, k, v};
        }
    }

    function trigger() {
        for (const sub of [...subscriptions]) {
            sub.execute();
        }
    }

    const target = {
        get value() {
            const observer = context[context.length - 1];
            if (observer) {
                subscribe(observer, subscriptions);
            }
            return value;
        },

        set value(newValue) {
            value = newValue;
            trigger()
        },
        trigger,
        push,
        pop() {
            return value.pop()
        },
        get length() {
            return value.length
        },
        insert(child, i=0) {
            value.splice(i, 0, child)
            trigger()
        },
        upsert(obj, fn) {
            const i = value.findIndex(fn)
            let inserted = false
            if (i === -1) {
                value.push(obj)
                inserted = true
            } else {
                value[i] = obj
            }
            trigger()
            return inserted
        },
        filter(fn) {
            value = value.filter(fn)
            trigger()
        },
        insert_after(child, i=0) {
            value.splice(i + 1, 0, child)
            trigger()
        },
        // append,
        // append_raw,
        set,
        // set_raw,
        clone() {
            return computed(() => target.value);
        },
        remove,
        init: value,
        __type: "ref"
    };

    if ($el) {
        target.$el = $el;
    }
    return target;
}

// set_remover is used to run the effect only once or remove in other situations
export function effect(fn, name, set_remover) {
    const effect = {
        execute() {
            current_effect = effect
            cleanup(effect, name);
            context.push(effect);
            fn(name);
            context.pop();
        },
        set_remover,
        dependencies: new Set(),
        cleanups: []
    };
    effect.execute();
}

// run fn but not track for reactive effect
export function untrack(fn) {
    const prev_context = context;
    context = [];
    const result = fn();
    context = prev_context;
    return result;
}

export function watch(r, fn) {
    let old_value;
    effect(() => {
        // console.log({new_value: r.value, old_value});
        try {
            const new_value = r.value
            untrack(() => fn(new_value, old_value));
            old_value = new_value;
        } catch (error) {
            console.log(r, fn, error);
        }
        // old_value = r.value;
    });
}

// run only when the value has been updated and the new_value is not undefined
export function watch0(r, fn) {
    let old_value;
    effect(() => {
        // console.log({new_value: r.value, old_value});
        try {
            const new_value = r.value
            if (new_value !== undefined && new_value !== old_value) {
                untrack(() => fn(new_value, old_value));
                old_value = new_value;
            }
        } catch (error) {
            console.log(r, fn, error);
        }
        // old_value = r.value;
    });
}

// watch the ref, run once the value is changed from undefined to some value
export function watch1(r, fn) {
    let old_value;
    let remover
    effect(() => {
        // console.log({new_value: r.value, old_value});
        try {
            const new_value = r.value

            if (new_value !== undefined && new_value !== old_value) {
                untrack(() => fn(new_value, old_value));

                remover()
            }
        } catch (error) {
            console.log(r, fn, error);
        }
    }, '', (_remover) => {
        remover = _remover
    });
}

export function computed(fn, name) {
    const v = ref();
    effect(() => {
        v.value = fn();
    }, name);

    return v;
}

// run computed only if the ref has value which is not undefined
export function computed0(rs, fn, name) {
    const v = ref();
    effect(() => {
        if (rs.every(r => r.value !== undefined)) {
            v.value = fn();
        }
    }, name);

    return v;
}

// TODO check, might not be useful
// export function clone(r) {
//     return computed(() => r.value);
// }

export function auto_clear_ref(v, clear_after_ms = 30 * 1000) {
    const r = ref(v);
    watch(r, function clear(new_value, old_value) {
        setTimeout(() => {
            r.value = undefined;
        }, clear_after_ms);
    });
    return r;
}

export function swap_ref(ref1, ref2) {
    const v = ref1.value
    ref1.value = ref2.value
    ref2.value= v
}

// execute fn during on_mount time
// fn can be either sync/async function
//
// const data = on_mounted(async () => {
//      return await fetch('/api/')
// })
export function after_mounted(fn, default_value) {
    const r = ref(default_value)
    requestAnimationFrame(async () => {
        r.value = await fn()
    })

    return r
}

// after mounted twice using requestAnimationFrame
export function after_mounted2(fn, default_value) {
    const r = ref(default_value)
    requestAnimationFrame(() =>
        requestAnimationFrame(async () => {
            r.value = await fn()
        })
    )

    return r
}

export function on_mounted(fn, default_value) {
    const r = ref(default_value)
    try {
        async_wrap(fn).then(resp => {
            r.value = resp
        })
    } catch (error) {
        console.error(error, fn)
    }
    return r
}

// TODO to be verified, if this works
export function on_cleanup(fn) {
    current_effect.cleanups.push(fn)
}

export function promise_to_ref(promise, default_value) {
    const r = ref(default_value)
    promise.then(result => {
        r.value = result
    }).catch(error => {
        console.error(error)
    })
    return r
}
//
// const r = ref(1)
// effect1(() => {
//     console.log(`${r.value}`)
// })
//
// r.value = 2
// r.value = 3

