import api from '../api.jsx'

export const generation_names = {
    1: '千', 2: '德',3: '茂',4: '瑞',5: '志',
    6: '雍', 7: '駢',8: '奕',9: '春',10: '和',
    11: '順', 12: '積',13: '裕',14: '滋',15: '森',
    16: '煥', 17: '培', 18: '鎮', 19: '浩', 20: '楷',
    21: '煜', 22: '基', 23: '錦', 24: '清', 25: '松', 26: '熙',27:'塾'}
// 清
const generation_to_id = Object.fromEntries(
    Object.entries(generation_names).map(([key, value]) => [value, key]))
const generations = Object.entries(generation_names).map(d => `${d[0]}${d[1]}`)
export function get_generation(generation, type) {
    const n = +(generation_to_id[generation])
    const ng = type === 'parent' ? n - 1 : n + 1
    return generation_names[ng]
}

export function next_generation(gen) {
    const i = generations.indexOf(gen)
    return generations[i + 1]
}

// g = Object.values(generations).flat()
// JSON.stringify(g.map(({id, name, generation, children=[], genetic_father=''}) => ({id, name, generation, children, genetic_father})))

async function Generations(collapses) {
    // const all_people = {}
    // const generations = {g13, g14, g15, g16, g17, g18, g19, g20, g21, g22, g23}
    // window.generations = JSON.parse(JSON.stringify(generations))
    // Object.values(generations).forEach(generation => generation.forEach(d => {
    //     if (all_people[d.id]) {
    //         console.log(all_people[d.id], d)
    //     } else {
    //         all_people[d.id] = d
    //     }
    // }))

    const resp = await api.get('/api/heritage', {}, 'stale_revalidate')
    const data = resp['data']



    const all_people_raw_data = JSON.stringify(Object.fromEntries(data.map(d => [d.id, d])))
    let all_people2 = JSON.parse(all_people_raw_data)
    let all_people // = get_new_dataset(collapses)

    function get_new_dataset(collapses = {}) {
        let all_people = JSON.parse(all_people_raw_data)
        Object.keys(collapses).forEach(id => delete all_people[id])
        Object.values(all_people).forEach(parent => {
            (parent.children || []).forEach(({id, name})=> {
                const child = all_people[id]
                if (child) {
                    child.parent = parent
                }
            })
        })
        return all_people
    }
    function has(id) {
        return all_people[id]
    }


    function fill_descendants(current, n_generation, parent, index, max_descents) {

        if (n_generation > 0 && current) {
            try {
                const children = current['children'] || []
                const filled = []

                for (let i = 0; i < children.length; i += 1) {
                    const {id, name, type} = children[i]

                    const child = all_people[id]
                    if (child && type !== 'adopted_out' && type !== 'shared_in') {
                        fill_descendants(child, n_generation - 1, current, i)
                        filled.push(child)
                    } else {
                        filled.push(children[i])
                        // console.log('no child data yet', id, current)
                    }
                }
                current.children = filled

            } catch (error) {
                console.error(current)
            }
        } else if (current && parent) {
            parent.children[index] = current
            // console.log(current)
        } else {
            // console.log(`finished trace`)
        }
    }

    function get(id, n=1, collapses) {
        all_people = get_new_dataset(collapses)
        const ascendants = []

        const descendants = all_people[id]
        fill_descendants(descendants, n-1, undefined, undefined)

        console.log(descendants)
        return {
            ascendants,
            center: all_people[id],
            descendants
        }
    }

    function search(name) {
        return data.filter(d => d.name.includes(name)).map(d => ({...d, value: `${d.id} ${d.name}`}))
    }

    function get_for(id1, id2, id3) {
        all_people = get_new_dataset(collapses)
        if (id1) {
            const ascendants = new Map()
            let node1 = all_people[id1]
            let shared
            while (node1) {
                ascendants.set(node1.id, node1)
                shared = node1
                node1 = node1.parent
            }

            if (id2) {
                let node2 = all_people[id2]
                while (node2 && !ascendants.has(node2.id)) {
                    ascendants.set(node2.id, node2)
                    node2 = node2.parent
                }

                shared = node2
            }

            function traverse(node) {
                (node.children || []).forEach(({id, name}, i) => {
                    const child = ascendants.get(id)
                    if (child) {
                        node.children[i] = child
                        traverse(child)
                    }
                })
            }

            if (shared) {
                traverse(shared)
            } else {
                console.log(`${id1} ${id2} has no shared ancestor`)
            }
            return shared
        } else {
            return {}
        }
    }
    return {get, search, get_for, has}
}

export default Generations