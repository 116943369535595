import {ref_render} from "nano/nano.jsx";
import LanguagePicker from "../components/LanguagePicker.jsx";
import Show from 'nano/Show.jsx'
import For from 'nano/For.jsx'

import {meta} from "../store.jsx";

const NAME = 'Welcome'
console.log({type: 'tracer'})


export default function Welcome(props) {
    const me = {init, update, finished: true}

    const contexts= {NAME, props, navigate: {
        right: 'MatchWord',
        next: 'MatchWord'
    }}

    async function update(/* $current_dom, */$next_dom, $right_dom) {
        // if ($current_dom) {
        //     ref_render(/* $current_dom, */WelcomePage, {contexts})
        // }

        $next_dom && ref_render($next_dom, WelcomePage, contexts)
        $right_dom && ref_render($right_dom, WelcomePage, contexts)
    }
    async function init($current_dom) {
        ref_render($current_dom, WelcomePage, contexts)
    }
    return me
}
export function WelcomePage(props = {}, children=[]) {
    const {data} = props
    return (
        <div class="page welcome flex_center"  style="gap: 2rem; text-align: center;">
            <div class="welcome_content flex_center" style="gap: 2rem; text-align: center;">
                <h1>{data.header}</h1>
                <For _each={data.paragraphs}>{(p) => {
                    return <p>{p}</p>
                }}</For>
            </div>
            <Show when={meta.value.learning_languages.length > 1}>
                <div class="flex_center" style="gap: 2rem; text-align: center;">
                    <h2>Pick an entry Language </h2>
                    <LanguagePicker/>
                </div>
            </Show>

        </div>
    )
}