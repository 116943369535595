import {computed, computed0, on_mounted, watch1} from "nano/reactive.jsx";
import "./App.css";
import "./util.css";
import Show from "nano/Show.jsx";
import UseSwipe from "./UseSwipe.jsx";
import Algorithm from "./Algorithm.js";
import {enable_report, app, PAGE_DEBUG, test} from "./config.js";
import {
    $current_dom,
    $left_dom,
    $next_dom,
    $prev_dom,
    $right_dom,
    algorithm,
    basket,
    foreground,
    header,
    log_flow,
    next_page_generators,
    position,
    prev_page_generators,
    page_theme,
    $active_learning_language,
    $app_language,
    $languages,
    $native_languages,
    $passive_learning_languages,
} from "./store.jsx";
import Signup from "./page/Signup.jsx";
import api from "./api.jsx";

import Header from "./components/Header.jsx";

console.log({type: 'tracer'})

export default function App(props, children) {
    let swipe = UseSwipe({
            onswipeup, onswipeleft, onswiperight, onswipedown,
            $current_dom,
            $left_dom,
            $right_dom,
        }
    );

    swipe.directions = {left: true, right: true, up: true, down: true}
    window.swipe = swipe
    const {$shiftX, $shiftY, $scrollShift, $shiftYExcludeScroll} = swipe
    const algo = Algorithm({swipe, onswipeup, remove})
    algorithm.value = algo
    window.algo = algo
    window.$shiftYExcludeScroll = $shiftYExcludeScroll
    window.$shiftY = $shiftY

    // current <= prev
    // next <= current
    // next <= undefined
    async function onswipedown(event, reason) {
        // console.log(event)
        // only middle page support swipe down
        if (position.value === 'middle') {
            // only need to handle when there still is $prev page
            // otherwise do nothing
            if ($prev_dom.value) {
                const post_hook = $current_dom.value.post_hook
                const pre_hook = $prev_dom.value.pre_hook

                $next_dom.value = $current_dom.value
                $current_dom.value = $prev_dom.value // now $prev_dom assigned to $current_dom, $prev_dom the element will be empty
                $prev_dom.value = undefined // $prev_dom is cleared now, it will be regenerated if there is more generators left
                // after the current_dom, swipe down, invoke the post_hook first
                if (post_hook) {
                    post_hook()
                }

                // then if the prev_dom will be shown, invoke the prev_hook
                if (pre_hook) {
                    pre_hook()
                }

                // it was previous cached generator
                // next -1, current -2,  prev: -3, before prev
                const prev_cached = prev_page_generators.at(-4)

                if (prev_cached) {
                    // still have prev_cached generator
                    await prev_cached.generator($prev_dom)
                    const last_cached = prev_page_generators.pop()

                    next_page_generators.push(last_cached)
                } else {
                    // all cached generator is already popped up
                    // it's in the beginning of all words
                    $prev_dom.value = undefined
                }
                log_flow()
            }
        }
    }


    async function onswipeup(event, reason) {

        // only middle page support swipe down
        if (position.value === 'middle') {
            // still has $next page
            // otherwise do nothing
            if ($next_dom.value) {
                // only when the history need to be saved, need to
                // update the $prev_dom and save $prev_dom to the history queue
                // otherwise, $prev_dom will not be changed
                const is_prev_empty = $prev_dom.value === undefined

                const post_hook = $current_dom.value?.post_hook
                const pre_hook = $next_dom.value.pre_hook

                // if the current_dom has just been removed, don't update $prev_dom
                if ($current_dom.value) {
                    $prev_dom.value = $current_dom.value
                }
                $current_dom.value = $next_dom.value
                $next_dom.value = undefined

                if (post_hook) {
                    post_hook()
                }
                if (pre_hook) {
                    pre_hook()
                }


                if (is_prev_empty && prev_page_generators.length > 2) {
                    // in the case of $prev_dom is empty
                    // the cached generator is the last one of prev_page_generators
                    const cached = prev_page_generators.at(-1)
                    await cached.generator($next_dom)
                } else {
                    // it was previous cached generator
                    const next_cached = next_page_generators.pop()
                    if (next_cached) {
                        await next_cached.generator($next_dom)
                        prev_page_generators.push(next_cached)
                    } else {
                        await algo.peak_update_next()
                    }
                }
                log_flow()
            }
        }
    }

    // swipe left, goes to right :-)
    async function onswipeleft(event, reason) {
        // console.log(event)
        if (position.value === 'middle') {
            position.value = 'right'
            swipe.directions = {left: false, right: true, up: false, down: false}
        } else if (position.value === 'left') {
            position.value = 'middle'
            swipe.directions = {left: true, right: true, up: true, down: true}
        } else {
            // if it's already right, nothing need to do
        }
    }

    // swipe right, goes to left
    async function onswiperight(event) {
        console.log(event)
        if (position.value === 'middle') {
            position.value = 'left'
            // on the left position, there is only one page
            // so only swipe right to change position is needed
            swipe.directions = {left: true, right: false, up: false, down: false}
        } else if (position.value === 'right') {
            position.value = 'middle'
            swipe.directions = {left: false, right: true, up: true, down: true}
        }
        else {
            // if it's already right, nothing need to do
        }
    }


    // for the whole app, transform left or right
    // so that the correct page is showing in the center
    const transform_position = computed(() => {
        switch (position.value) {
            case 'left':
                return `translate(100%, 0)`;
            case 'middle':
                return `translate(0, 0)`;
            case 'right':
                return `translate(-100%, 0)`
            default:
                throw {message: `wrong ${position.value}`}
        }
    })


    const transform_middle = computed(() => {
        return `translate(${$shiftX.value}px, ${$shiftYExcludeScroll.value}px)`
    })

    // remove the $current_dom, a removing effect 400ms
    // then let the $next_dom showing
    function remove(event) {
        const dom = $current_dom.value
        dom.style.transition = `transform 0.3s ease-in`
        dom.style.transform = `translate(0, calc(50% - 3rem)) scale(0.01)`;
        setTimeout(async () => {
            dom.style.transition = ``
            dom.style.transform = ``;
            $current_dom.value = undefined
            await onswipeup(event)
        }, 400)
    }

    async function on_login_success(response) {
        console.log('on_login_success')
        meta.value = response
        basket.value = response.basket


        setTimeout(() => {
            // only run once the meta value is set
            watch1(meta, async (value, old_value) => {
                const {left={}, right={}} = value

                $active_learning_language.value = value.active_learning_language
                $app_language.value = value.app_language
                $languages.value = value.languages
                $native_languages.value = value.native_languages
                $passive_learning_languages.value = value.passive_learning_languages

                console.log(meta.value)

                header.value = localStorage.getItem('header') ? localStorage.getItem('header') === 'true' : meta.value.header

                await algo.init_update()

                // it can be configured only has one position by set both left and right as undefined
                const left_component = localStorage.getItem('left') || left.name
                if (left_component) {
                    await algo.update_page($left_dom, left_component, {props: {$shiftX}})
                    header.value = localStorage.getItem('header') ? localStorage.getItem('header') === 'true' : meta.value.header
                }
                if (right.name) {
                    await algo.update_page($right_dom, right.name, {props: {$shiftX}})
                }
            })
        }, 300)

        swipe.use(true)
    }

    async function check_signin() {
        console.log('check_signin')
        const token = localStorage.getItem("token");
        // if there is token in localStorage
        if (token) {
            const message = {
                token: localStorage.getItem("token") || "",
                username: localStorage.getItem("username") || "",
                password_hash: localStorage.getItem("password_hash") || "",
                app: localStorage.getItem("app") || app
            };
            const response = await api.get("/api/login", message);

            await on_login_success(response)
        } else {
            requestAnimationFrame(() => {
                Signup({type: 'Sign up', on_login_success})
            })
        }
        window.api = api
    }


    on_mounted(check_signin)

    const z_index = computed(() => {
        const result = foreground.value ? 200 : 0
        console.log(result)
        return result
    })
    const app_style = {
        height: "100%",
        "--header-height": computed0([meta], () => meta.value.header ? "64px" : "10px"),
        "--footer-height": "100px"
    }
    return (
        <div style={app_style}>
            <Show when={PAGE_DEBUG}>
                <div class="page_debug">
                    <div id="page_current">current</div>
                    <div id="page_next" style="top: 1rem;">next</div>
                    <div id="page_right">right</div>
                </div>
            </Show>
            <Show when={header}>
                <Header $shiftX={$shiftX}></Header>
            </Show>
            <div id="app" style={{'--percent': test ? '50%': `100%`, transform: transform_position, "z-index": z_index}} data-theme={page_theme}>
                <div class={{page_container: true, left: true}} _dom={$left_dom}
                     data-name="$left_dom"
                     style={{transform: transform_middle}}></div>

                <div class={{page_container: true, prev: true}} _dom={$prev_dom}
                     data-name="$prev_dom"
                     style={{transform: transform_middle}}></div>
                <div class={{page_container: true, current: true}} _dom={$current_dom}
                     data-name="$current_dom"
                     style={{transform: transform_middle}}></div>
                <div class={{page_container: true, next: true}} _dom={$next_dom}
                     data-name="$next_dom"
                     style={{transform: transform_middle}}></div>

                <div class={{page_container: true, right: true}} _dom={$right_dom}
                     data-name="$right_dom"
                     style={{transform: transform_middle}}></div>
                <div id="orientation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat"><polyline points="17 1 21 5 17 9"/><path d="M3 11V9a4 4 0 0 1 4-4h14"/><polyline points="7 23 3 19 7 15"/><path d="M21 13v2a4 4 0 0 1-4 4H3"/></svg>
                    <div id="orientation_message">ROTATE SCREEN</div>
                </div>
            </div>
        </div>
    )
}