import Data from './Data.jsx'
import For from 'nano/For.jsx'
import Hover from 'nano/Hover.jsx'
import Radios from 'nano/components/Radios.jsx'
import './Heritage.css'
import {hierarchy, tree, linkHorizontal, linkVertical} from 'd3'
import IconPlus from "solidjs-feather/IconPlus.jsx";
import IconEdit from "solidjs-feather/IconEdit3.jsx";
import Dialog from "../dialog/Dialog.jsx";
import {on_mounted, watch0, ref, computed, computed0} from 'nano/reactive.jsx'
import Generations from "./Data.jsx";
import IconSave from "solidjs-feather/IconSave.jsx";
import api from "../api.jsx";
import {toPng, toPixelData} from "html-to-image";
import {initial_height, initial_width} from "../store.jsx";

const from_top = 50

function Card(props, children) {
    const {node, band_height, new_nodes, show, direction, collapsed, toggle_collapse} = props
    const {data, x, y} = node
    const {type='normal', name, id, gender} = data
    // console.log(props)
    const z_index = ref(1)
    const display = computed(() => direction.value === 'horizontal' ? '' : 'flex')
    const style = {
        transform: direction.value === 'horizontal' ?
            `translate(calc(-50% + ${x}px), calc(-50% + ${y+from_top}px))`
            : `translate(calc(-50% + ${y}px), calc(-50% + ${x}px))`
        // 'z-index': z_index,
        // display: computed(() => show.value ? 'unset' : 'none')
    }
    function add(type) {
        console.log(type, data)

        Dialog({data, x, y, band_height, new_nodes})
    }

    function edit() {
        Dialog({data, node, x, y, band_height, new_nodes, mode: 'edit'})
    }

    function onmouseenter(event) {
        console.log(event)
        z_index.value = 100
        console.log(style)
    }
    function onmouseleave(event) {
        z_index.value = 0
        console.log(style)
    }

    const chars = computed(() => {
        const parts = data.name.split('')
        if (collapsed.value) {
            parts.push('…')
        }
        return parts
    })

    const numbers = id.slice(1).split('')
    return (
        <div data-name={name} data-id={id} style={style} class={{card: true, collapsed}}
             onmouseenter={onmouseenter}
             onmouseleave={onmouseleave}
             onclick={(event) => toggle_collapse(event, id, name)}
        >

            <div class={{[type]: true, name: true, female: gender === 'female'}} style={{background: 'white', display}}>
                <For _each={chars}>{(char, i) => <div>{char}</div>}</For>
                <div style="font-size: 0.8em; text-align: center" >
                    {/*<For _each={numbers}>{(char, i) => <div style="margin: -7px">{char}</div>}</For>*/}
                </div>
            </div>
            <Hover>
                <div class="hover">
                    <span>{id} </span>
                    <span>{ node.max_siblings}</span>
                </div>
            </Hover>
            {/*<div class="hover">{id}</div>*/}
            {/*<div class="add" style="top: -0.1em" onclick={(event) => edit('parent')}><IconEdit/></div>*/}
            <Hover>
                <div class="add" style="bottom: -0.2em;" onclick={(event) => add('child')}><IconPlus/></div>
            </Hover>
        </div>
    )
}


export default function Heritage(props, children) {

    // const id ='裕289 肇魁'
    // const id = '森344 允昌'

    // const id = '滋542' // 伯發

      // const id = '森344' // 允昌
    // const  id = '培同857 如椿'
    // const id = '煥212 以彪'
    const id = '松2626 友江'
    // const id = '培同857' // 如椿

    // const id = '鎮950'  // 相印
    // const id = '鎮1414' // 相漢

    // const id = '煥301' // 以彬
    // const id = '煥618' // 以彥

    //   const id = '森531' // 允恭
    //      // const id = '煥417' // 宏玉
    // const id='煥493 以文' // 二房門裡
    // const collapses = ref({'培498': '随'})
    // const collapses = ref({'': '随'})


    //     const collapses = ref({'培498': '随', '滋500': '伯雲', '森531': '允恭'})

    // 二房門裡

    const choices = [
            {branch: "景雲|祿雲", font_size: "40px", layers: 4, collapses: {}},

        {branch: "伯雲-允昌-以彪-如柏 ...成清", id: "培568 如柏", font_size: "38px",  collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","煥301":"以彬","煥618":"以彥","培同857":"如椿","培756":"如竹"}},
        {branch: "伯雲-允昌-以彪-如竹 ...天青", id: "培756 如竹", font_size: "42px", collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","煥301":"以彬","煥618":"以彥","培568":"如柏","培同857":"如椿"}},
        {branch: "伯雲-允昌-以彪-如椿-相印 ...文海", id: "培同857 如椿", font_size: "42px",  collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","煥301":"以彬","煥618":"以彥","培568":"如柏","培756":"如竹","鎮1414":"相漢"}},
        {branch: "伯雲-允昌-以彪-如椿-相漢 ...正國", id: "培同857 如椿", font_size: "38px",  collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","煥301":"以彬","煥618":"以彥","培568":"如柏","培756":"如竹","鎮950":"相印"}},
        {branch: "伯雲-允昌-以彬 ...昌利", id: "煥301 以彬", font_size: "42px",  collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","煥618":"以彥","培568":"如柏","培756":"如竹","鎮950":"相印","培同857":"如椿","煥212":"以彪"}},
        {branch: "伯雲-允昌-以彥 ...浩夫", id: "煥618 以彥", font_size: "38px",  collapses: {"森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","滋542":"伯發","培568":"如柏","培756":"如竹","鎮950":"相印","培同857":"如椿","煥212":"以彪","煥301":"以彬"}},
        {branch: "伯發-允恭-宏玉-乾|坤 王村子乾坤 ...吉松", id: "煥417 宏玉", font_size: "38px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥729":"文宗","煥580":"文煥","煥493":"以文","培344":"貞"}},
        {branch: "伯發-允恭-宏玉-貞 王村子貞 ...坤仲", id: "培344 貞", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥729":"文宗","煥580":"文煥","煥493":"以文","培262":"坤","培233":"乾"}},
        {branch: "伯發-允恭-以文 二房門裡", id: "煥493 以文", font_size: "38px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥729":"文宗","煥417":"宏玉","煥580":"文煥"}},
        {branch: "伯發-允恭-文煥-鼎 ...亦飛", id: "培950 鼎", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥729":"文宗","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉"}},
        {branch: "伯發-允恭-文宗-謙 ...鈡良", id: "培581 謙", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培同881":"復","培682":"大有"}},
        {branch: "伯發-允恭-文宗-大有-能山 ...紹清", id: "培682 大有", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培同881":"復","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山"}},
        {branch: "伯發-允恭-文宗-大有-南山-瀣 ...水標", id: "培682 大有", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培同881":"復","培581":"謙","鎮627":"能山","鎮951":"厯山","浩658":"滙"}},
        {branch: "伯發-允恭-文宗-大有-南山-滙 ...紹太", id: "培682 大有", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培同881":"復","培581":"謙","鎮627":"能山","鎮951":"厯山","浩566":"瀣"}},
        {branch: "伯發-允恭-文宗-大有-厯山    ...紹平", id: "培682 大有", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培同881":"復","培581":"謙","鎮627":"能山","浩566":"瀣","浩658":"滙","鎮710":"南山"}},
        {branch: "伯發-允恭-文宗-復-燕山   ...天松", id: "培同881 復", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1335":"東山","鎮1161":"鳳山"}},
        // smaller
        {branch: "伯發-允恭-文宗-復-鳳山  ...柳橋", id: "培同881 復", font_size: "36px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1335":"東山"}},
        {branch: "伯發-允恭-文宗-復-東山  ...永生", id: "培同881 復", font_size: "42px",  collapses: {"滋500":"伯雲","森695":"允傑","森731":"允茂","森822":"允臧","森913":"允彰","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培950":"鼎","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山"}},
        {branch: "伯發-允傑|允茂|允臧|允彰 ...定波", id: "滋542 伯發", font_size: "42px",  collapses: {"滋500":"伯雲","滋395":"祿雲","滋245":"景雲","煥493":"以文","培262":"坤","培233":"乾","培344":"貞","煥417":"宏玉","煥580":"文煥","培581":"謙","浩566":"瀣","浩658":"滙","鎮710":"南山","鎮951":"厯山","培682":"大有","鎮1064":"燕山","鎮1161":"鳳山","鎮1335":"東山","森531":"允恭"}}
    ]

    const i = 12

    const choice = choices[i]
    // const choice = choices.find(choice => choice.branch === "伯發-允恭-以文 二房門裡")
    // const collapses= ref(choice.collapses)
    const collapses= ref({})
    // const font_size = choice.font_size
    const font_size = '16px'

    const layers = choice.layers || 14
    // const id = choice.id ||  '裕289 肇魁'
    function toggle_collapse(event, id, name) {

        if (collapses.value[id]) {
            delete collapses.value[id]
        } else {
            collapses.value[id] = name
        }
        console.log(JSON.stringify(collapses.value))
        collapses.trigger()
    }

    //      const id = '煥580' // 文煥
    //      const id = '煥729 文宗'

    // const id = '培682 大有' // 上村, 水標, A3
    // const id = '培同881 復' // 新屋門裡 A3
    //   const id = '煥659' // 允傑, 兒子後無傳
    //   const id = '森731' // 允茂
    //   const id = '森822' // 允臧
    //   const id = '森913' // 允彰
    const width = window.innerWidth
    const height = window.innerHeight - 90
    const size= [width, height]
    // const size= [window.innerWidth * 2 - 50, window.innerHeight - 100]




    // const layers = 12
    // const size= [2000, 950]


    // const id = '培498' // 隨
    // const id = "鎮618" // 德千
    // const id = '鎮1246' // 榮千
    // const id 鎮1147 // 貴千
    // const id = '楷779'

    function shift({source, target}) {
        try {
            return {
                source: {...source, y: source.y + half_height},
                target: {...target, y: target.y - half_height}
            }
        }
        catch (error) {
            console.log(error, source, target)
        }
    }
    const half_height = 17;

    const direction = ref('horizontal')
    // const direction = ref('vertical') // ref(window.innerWidth > window.innerHeight)

    const trace_up = ref(true)
    // const tracing = ref('up')
    const tracing = ref('up')
    const wei1 = ref(id)
    const wei2 = ref('')
    const genetic_links = ref([])
    const shared_links = ref([])

    const generator = linkVertical().x(
        d => {
            return direction.value === "horizontal" ? d.x : d.y;
        }).y(
        d => {
            return direction.value === "horizontal" ? d.y + from_top: d.x;
        });
    const new_nodes = ref([])

    const band_height = ref(100)
    function traverse(node) {
        const layers = []

        function traverse0(n, depth) {
            if (n.children) {
                if (layers[depth]) {
                    layers[depth].push(n.children)
                } else {
                    layers[depth] = [...n.children]
                }
                n.children.forEach(child => traverse0(child, depth + 1))
            }
        }

        traverse0(node, 0)
        return layers
    }

    const data = ref()
    on_mounted(async () => {
        data.value = await Generations(collapses.value)
    })
    const wei1_options = computed0([data], () => {
        let result = []
        if (wei1.value) {
            result = data.value && data.value.search(wei1.value)
        }
        console.log(result)
        return result
    })
    const wei2_options = computed0([data], () => {
        let result = []
        if (wei2.value) {
            result = data.value && data.value.search(wei2.value)
        }
        console.log(result)
        return result
    })

    const id1 = ref(wei1.value.split(' ').filter(p => p.trim()).at(0))
    const id2 = ref(wei2.value.split(' ').filter(p => p.trim()).at(0))

    watch0(wei1, (new_value, old_value) => {
        const id = new_value.split(' ').filter(p => p.trim()).at(0)
        if (data.value && data.value.has(id)) {
            id1.value = id
        }
    })


    watch0(wei2, (new_value, old_value) => {
        const id = new_value.split(' ').filter(p => p.trim()).at(0)
        if (data.value && data.value.has(id)) {
            id2.value = id
        }
    })


    const descendants = computed0([data], () => {
        let result
        if (data.value) {
            if (tracing.value === 'up') {
                result = data.value && data.value.get_for(id1.value, id2.value)
            } else {
                const {
                    ascendants,
                    center,
                    descendants
                } = data.value && data.value.get(id1.value, layers, collapses.value)
                result = descendants
            }
        }
        return result
    })

    const layout = computed0([data], () => {
        if (descendants.value) {
            const root = hierarchy(descendants.value);

            const _layers = traverse(root);

            const layout = tree().size(direction.value === 'horizontal' ? size : size.reverse())/*.nodeSize([13, 90])*/;

            // const _sizeNode = layout.sizeNode
            // layout.sizeNode = function sizeNode(node) {
            //     _sizeNode(node)
            //     if (collapses[node.data.id]) {
            //
            //     }
            // }

            layout.separation((a, b) => {
                if (collapses[a.data.id] || collapses[b.data.id]) {
                    return 10.8;
                } else {
                    return 1
                }
            })
            return layout(root)
        }
    })

    const descendants_nodes = computed0([data], () => {
        return layout.value && layout.value.descendants()
    })

    const descendant_map = computed0([data], () => {
        return descendants_nodes.value && Object.fromEntries(descendants_nodes.value.map(node => [node.data.id, node]))
    })

    const links = computed0([data], () => {
        return layout.value && layout.value.links().map(shift)
    })

    async function save(event) {
        const resp_parent = await api.post('/api/data/save', {})
        console.log(resp_parent)
    }

    const shiftX = ref(0)
    const shiftY = ref(10)
    const transform = computed(() => {
        return `translate(${shiftX.value}, ${shiftY.value})`
    })
    const canvas_style = {
        transform: computed(() => `translate(${shiftX.value}px, ${shiftY.value}px)`)
    }

    window.onmousedown = function onmousedown(event) {
        const {clientX, clientY, shiftKey} = event
        const startShiftX = shiftX.value
        const startShiftY = shiftY.value

        function onmousemove(event) {
            shiftX.value = startShiftX + (event.clientX - clientX)
            shiftY.value = startShiftY + (event.clientY - clientY)
        }
        function onmouseup(event) {
            window.onmousemove = undefined
            window.onmouseup = undefined
        }

        if (shiftKey) {
            window.onmousemove = onmousemove
            window.onmouseup = onmouseup
        }
    }

    function is_inside(node) {
        const x = node.x + shiftX.value
        const y = node.y + shiftY.value
        return (x > -30 && x < initial_width.value) && (y > -30 && y < initial_height.value)
    }
    const visible_links = computed0([links], () => {
        const result = links.value.filter(link => is_inside(link.source) || is_inside(link.target))
        // console.log(`links: ${result.length}`)
        return result
    })

    const filter_used = {}
    window.filter_usered = filter_used

    const visible_nodes = computed0([descendants_nodes], () => {
        const [x_min, x_max] = [-30 - shiftX.value, innerWidth - shiftX.value]
        // console.log(x_min, x_max)

        function is_inside({x, y}) {
            // const x = node.x + shiftX.value
            // const y = node.y + shiftY.value
            return (x > x_min && x < x_max)
        }

        window.nodes = descendants_nodes.value
        window.is_inside = is_inside
        // const nan = descendants_nodes.value.find(node => node.data.name = '相南')
        const result = descendants_nodes.value.filter(node => is_inside(node))
        window.nan = result.find(node => node.data.name === '相南')
        window.visibles = result
        // const has_nan = result.find(node => node.data.name = '相南')
        // console.log(nan, has_nan)
        // console.log(result.length)
        // console.log([has_guan, innerWidth, innerHeight, guan?.x + shiftX.value, guan?.y + shiftY.value, result.length])
        return result
    })
    const color = ref('orange')
    async function report(event) {

        color.value = 'gray'
        // const dataUrl0 = await toPng(document.body);
        const pixelData = await toPixelData(document.body)

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = window.innerHeight * 1.414;
        canvas.height = window.innerHeight;

        const imageData = new ImageData(pixelData, window.innerWidth, window.innerHeight);
        ctx.putImageData(imageData, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');
        try {

            const response = await fetch("/api/report", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({image: dataUrl, data: window.data, branch: choice.branch})
            });


            // if (response.ok) {
            //     alert("Image uploaded successfully!");
            // } else {
            //     alert("Failed to upload image");
            // }
        } catch (error) {
            console.error("Error generating or uploading PNG:", error);
        }
        color.value = 'orange'
    }
  return (
    <div class="heritage" style={{background: "white", "--font-size": font_size}}>

        <svg class="full">
            <g  transform={transform}>
                <g>
                    <For _each={visible_links}>{(link, index) => {
                        // const display = computed(() => is_inside(link.source) || is_inside(link.target) ? 'unset' : 'none')
                        const source = link?.source?.data?.name
                        const target = link?.target?.data?.name
                        return <path d={generator(link)} class="heritage_link" data-source={source} data-target={target}></path>
                    }}</For>
                </g>
                <g>
                    <For _each={genetic_links}>{(link, index) => {
                        return <path d={generator(link)} class="heritage_link genetic"></path>
                    }}</For>
                </g>
                <g>
                    <For _each={shared_links}>{(link, index) => {
                        return <path d={generator(link)} class="heritage_link shared"></path>
                    }}</For>
                </g>

            </g>
            {/*<g>*/}
            {/*    <line x1={0} x2={width + 100} y1={height + 140} y2={height + 140} stroke="black" stroke-width="2"></line>*/}
            {/*    <line x1={width + 85} x2={width + 85} y1={0} y2={height + 140} stroke="black" stroke-width="2"></line>*/}
            {/*</g>*/}
        </svg>
        <div id="ascendants" class="full top"></div>
        {/*<div id="center"  class="full">*/}
        {/*    <Card node={root}></Card>*/}
        {/*</div>*/}
        {/*<div id="descendants"  class="full bottom" style={canvas_style}>*/}
        {/*    <For _each={new_nodes}>{(node, index) => {*/}
        {/*        return <Card node={node} band_height={band_height} new_nodes={new_nodes} collapses={collapses}></Card>*/}
        {/*    }}</For>*/}
        {/*</div>*/}
        <div id="descendants"  class="full bottom" style={canvas_style}>
            <For _each={visible_nodes}>{(node, index) => {
                // const show = computed(() => is_inside(node))
                const collapsed = computed(() => {
                    const result = collapses.value[node.data.id]
                    if (result) {
                        filter_used[node.data.id]= node.data.name
                    }
                    return result
                })
                return (
                    // <Show when={show}>
                    <Card node={node} band_height={band_height} new_nodes={new_nodes}
                          direction={direction}
                          collapsed={collapsed}
                          toggle_collapse={toggle_collapse}
                    ></Card>
                    // </Show>
                )
            }}</For>
        </div>
        {/*<span class="save" onclick={save}>*/}
        {/*    <IconSave></IconSave>*/}
        {/*</span>*/}
        <div class="filter">
            {/*<span onclick={report} class="report" style={{color}}>*/}
            {/*    Print*/}
            {/*</span>*/}
            {/*<span class="py-result-item">*/}
            {/*    <ruby>*/}
            {/*        <span class="py-chinese-item">汉</span>*/}
            {/*        <rp>(</rp>*/}
            {/*        <rt class="py-pinyin-item">hàn</rt>*/}
            {/*        <rp>)</rp>*/}
            {/*    </ruby>*/}
            {/*    </span>*/}
            {/*                <span class="py-result-item">*/}
            {/*    <ruby>*/}
            {/*        <span class="py-chinese-item">语</span>*/}
            {/*        <rp>(</rp>*/}
            {/*        <rt class="py-pinyin-item">yǔ</rt>*/}
            {/*        <rp>)</rp>*/}
            {/*    </ruby>*/}
            {/*</span>*/}
            {/*<div>*/}
            {/*    <Radios model={tracing} values={['up', 'down']} labels={['上溯', '下溯']}/>*/}
            {/*</div>*/}
            <div>
                <input list="wei1" type="text" model={wei1}
                       onfocus={(event) => event.target.select()}
                       oncontextmenu={event=> {
                           // avoid chrome in device mode, trigger contextmenu after invoke event.target.select()
                           event.preventDefault()
                       }}
                       placeholder="諱/輩序"
                       class="wei"
                       style="width: 10em; text-align: end; padding: 5px"></input>
                <datalist id="wei1">
                    <For _each={wei1_options}>{(d) => {
                        const p = d?.parent?.name
                        const label = p ? `${p}>${d.name}` : d.name
                        const value = `${d.id} ${d.name}`
                        return <option value={value}>{label}</option>
                    }}</For>
                </datalist>

            </div>
            <div>
                <input list="wei2" type="text" model={wei2}
                       onfocus={(event) => event.target.select()}
                       oncontextmenu={event=> {
                           // avoid chrome in device mode, trigger contextmenu after invoke event.target.select()
                           event.preventDefault()
                       }}
                       class="wei"
                       placeholder="諱/輩序"
                       style="width: 10em; text-align: end; padding: 5px"></input>
                <datalist id="wei2">
                    <For _each={wei2_options}>{(d) => {
                        const p = d?.parent?.name
                        const label = p ? `${p}>${d.name}` : d.name
                        // const value = `${d.id} ${d.name}`
                        return <option value={d.value}>{label}</option>
                    }}</For>
                </datalist>
            </div>
        </div>
    </div>
  )
}
