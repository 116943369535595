import {per_page_words, word_match_batch_size} from "../config.js";
import Options from "../page/Options.jsx";
import MatchWordPage from "./MatchWordPage.jsx";
import {algorithm, basket, batch, current_progress, theme} from "../store.jsx";
import {ref_render} from "nano/nano.jsx";

console.log({type: "tracer"})

let match_index = 0

export default function MatchAlgorithm() {

    let option = false
    const me = {update, finished: false}
    let match_times = 0

    const navigate = {
        right: 'MatchWord',
        next: 'MatchWord'
    }

    async function update(/* $current_dom, */$dom) {
        const words = batch.value
        const props = {words}
        const pre_hook = () => {
            theme.value = 'light'
            algorithm.value.swipe.use(true)
            match_times = match_times + 1
            if (match_times >= 3) {
                me.finished = true
                batch.value = []
                match_times = 0
            }
        }
        const post_hook = () => {
            // match_times = match_times + 1
            // console.log(match_index)
            // current_progress.value = current_progress.value + 1
            // me.finished = match_index % word_match_batch_size === 0
        }

        const contexts = {props, pre_hook, post_hook, navigate}

        await ref_render($dom, MatchWordPage, contexts)
    }
    return me
}
