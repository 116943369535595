export function AsyncImage(src) {
    return new Promise((resolve, reject) => {
        const obj = new Image();
        obj.onload = () => {
            resolve(obj)
        };
        obj.onerror = (error) => {
            reject(error)
        };
        obj.src = src;
    })
}

export function AsyncAudio(url) {
    const audio = new Audio(url);
    audio.load();

    function play() {
        audio.play().then(resp => {
            // played
        }).catch(error => {
            console.error(error, url)
        })
    }

    return {
        play,
        pause() {
            audio.pause()
        },
        set volume(v) {
            audio.volume = v
        },
        get volume() {
            return audio.volume
        }
    }
}
export async function async_wrap(fn) {
    return await fn()
}
