import "./Article.css";
import {ref_render} from "nano/nano.jsx";
import {MAX_ARTICLE, MAX_BASKET_SIZE, MAX_WORDS} from "../config.js";
import {ref} from "nano/reactive.jsx";
import Summary from "./ArticleSummary.jsx";
import Page from "./ArticlePage.jsx";
import Status from "./ArticleStatus.jsx";
import {basket} from "../store.jsx";


export default function Article(algorithm) {

    let n_articles = 0
    let n_words = 0
    let status_showed = false
    let prompt_showed = false
    const navigate = {
        right: 'ReviewWord',
        next: 'ReviewWord',
    }
    const prompt = ref()
    const me = {update, finished: false}

    function pre_hook(context) {

    }

    async function update($dom) {
        if (status_showed) {
            if (n_articles >= MAX_ARTICLE || n_words >= MAX_WORDS || basket.value.length >= MAX_BASKET_SIZE) {
                const data = {props: {n_articles, n_words}}
                await ref_render($dom, Summary, data)
                me.finished = true
            } else {
                await ref_render($dom, Page, {})
                n_articles += 1
            }
        } else {
            await ref_render($dom, Status)
            status_showed = true
        }
    }

    return me
}
