import {play} from "../audio.js";
import Particle from "./Particle.js";
import {rect_collide} from "./Collission.js";

console.log({type: "tracer"})

export default function Word(options) {
    const {language, word, font_size, use_language} = options;
    const {detail = {}} = word;
    const text = word[language] || word.text
    const meaning = word[use_language]
    console.log(text)
    const {morphemes = [], syllables = []} = detail
    // morphemes using [morpheme, english meaning, chinese meaning]
    const parts = morphemes.length > 0 ? morphemes.map(morpheme => morpheme[0]) : syllables;

    const font = `${font_size}px monospace`
    // top left as the coordinate
    const textAlign = "left";
    const textBaseline = "hanging";
    let width = text.length * font_size * 0.5
    let height = font_size + 5

    const me = {

        dx: 0,
        dy: 1,
        friction: 1,
        a: 0,
        finished: false,
        height,
        ...options,
        text,
        // meaning,
        split: 0,
        init,
        update,
        clear,
        draw,
        resolve_collision,
        left: options.x,
        right: options.x + width,
        top: options.y,
        bottom: options.y + height
    };

    let played = undefined;

    function init(game) {
        const {ctx} = game
        ctx.beginPath()
        ctx.font = font
        const metrics = ctx.measureText(me.text)
        width = metrics.width
        // height = metrics.height
        me.right = options.x + width
        me.bottom = options.y + height;
    }

    function update(game) {
        me.dy = (me.dy + me.a) * me.friction;
        me.x += me.dx;
        me.y += me.dy;
        me.left = me.x;
        me.top = me.y;
        // me.right = me.x + width;
        me.bottom = me.y + height;
    }

    function add_explosion_particles(xy, particles) {
        Array(5).fill(0).forEach(_ => {
            const particle = new Particle({
                x: xy.x,
                y: xy.y,
                dx: (Math.random() - 0.5) * me.explosion_speed,
                dy: (Math.random() - 0.5) * me.explosion_speed,
                radius: Math.random() * me.explosion_radius,
                color: me.color,
                alpha: 1
            });
            particles.push(particle);
        });
    }

    function get_split_text() {
        if (parts.length > 1) {
            let result = "";
            for (let i = 0; i < parts.length; i++) {
                result += parts[i];
                if (i < me.split) {
                    result += " ";
                }
            }
            return result;
        } else {
            return me.text;
        }
    }


    function resolve_collision(game, {player, projectiles, particles}) {
        const {ctx, turn_down_volume, turn_up_volume} = game
        for (let i = projectiles.length - 1; i >= 0; i -= 1) {
            const projectile = projectiles[i];

            const collide_y = projectile.y > me.y && projectile.y < me.bottom;
            const collide_x = projectile.x > me.x && projectile.x < me.right;
            if (collide_x && collide_y) {
                projectile.collide = true;
                projectiles.splice(i, 1);
                add_explosion_particles(projectile, particles);
                me.split += 1;
                me.text = get_split_text();
                init(game)
                me.meaning = meaning
                const positions = word[use_language];
                // if there is still no meaning set yet and there is translations
                if (!me.meaning && positions.length > 0) {
                    const position_index = ~~(Math.random() * positions.length);
                    const [pos, meanings = []] = positions[position_index];
                    const meaning_index = ~~(Math.random() * meanings.length);
                    me.meaning = meanings[meaning_index];
                }

                if (me.split >= parts.length) {
                    if (me.played && !me.finished) {
                        setTimeout(() => {
                            me.finished = true;
                        }, 400);
                    } else {
                        turn_down_volume();
                        play(text, language, 1, turn_up_volume);
                        me.played = true;
                    }
                }
            }
        }


        if (me.finished) {
            // if already finished, nothing need be done
        } else if (me.y > innerHeight) {
            player.health -= 1;
        } else if (rect_collide(me, player)) {
            add_explosion_particles(player, particles);
            player.health -= 1;
            me.finished = true;
        }
    }

    function clear(game) {
        const {ctx} = game
        ctx.beginPath()
        ctx.fillStyle = me.background;

        ctx.fillRect(me.x, me.y - 1, width, height);

        ctx.arc(me.x, me.y, 5, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath()
    }

    function draw(game) {
        const {ctx} = game
        ctx.beginPath()
        ctx.font = font

        ctx.fillStyle = me.color;
        ctx.textAlign = textAlign // "left";
        ctx.textBaseline = textBaseline // "hanging";
        ctx.fillText(me.text, me.x, me.y);

        // ctx.strokeStyle = me.color
        //
        // ctx.moveTo(me.x, me.y + height)
        // ctx.lineTo(me.x + width, me.y + height)
        // ctx.stroke()
        if (me.meaning) {
            ctx.fillText(me.meaning, me.x, me.y + 20);
        }

        // ctx.fillStyle = 'white';
        // ctx.arc(me.x, me.y, 5, 0, Math.PI * 2);
        // ctx.fill();
        ctx.closePath()
    }

    return me;
}
