import IconMessageSend from "solidjs-feather/IconMessageSend.jsx";
import {ref} from "nano/reactive.jsx";

export default function PromptBar(props = {}, children = []) {
    const {on_finish, placeholder = "Type a topic", show} = props

    const message = ref()
    const input_style = {
        width: "100%",
        border: '1px solid #ccd',
        "border-radius": "1.2em",
        "padding": "0.5em 1em",
        "padding-right": "3rem",
        // "line-height": 1.5,
        "outline": 'none',
        "overflow-y": 'auto',
        "text-wrap": "wrap",
        resize: 'none'

    }

    const send_bar_style = {
        'font-size': '20px',
        "width": "calc(100% - 1rem)",
        position: 'absolute',
        bottom: '1rem'
    }
    const send_style = {
        position: 'absolute',
        bottom: "-2px",
        right: "1px",
        'font-size': '30px',
    }

    const max_height = 200;
    const rows = ref(1)

    function onkeydown(event) {
        const {currentTarget, key} = event
        if (key === "Enter") {
            event.preventDefault();
            on_finish(message.value);
            message.value = ''
        } else {
            requestAnimationFrame(() => {
                const matches = currentTarget.value.match(/\n/g)
                rows.value = (matches?.length + 1) || 1
            })
        }
    }

    function onclick(event) {
        on_finish(message.value)
        message.value = ''
    }

    function oninput(event) {
        const {currentTarget} = event
        console.log(prompt.value)
        currentTarget.style.height = `${Math.min(currentTarget.scrollHeight + 2, max_height)}px`
    }

    return (
        <div style={send_bar_style}>
            <textarea model={message} placeholder={placeholder} style={input_style} rows={rows} onkeydown={onkeydown} oninput={oninput}></textarea>
            <span style={send_style} onclick={onclick}><IconMessageSend/></span>
        </div>)
}