function safe_params(obj) {
    const params = new URLSearchParams();
    Object.entries(obj).forEach(function set_props_if_has_value([name, value]) {
        if (value) {
            params.set(name, value);
        }
    });
    if (!obj.hasOwnProperty("token")) {
        params.set("token", localStorage.getItem("token") || "");
    }
    return params;
}


export default async function safe_fetch(url, init= {}, params = {}) {

    const full_url = `${url}?${safe_params(params).toString()}`;
    try {
        const response = await fetch(full_url, init);
        const json = await response.json();
        console.log(url, json);
        return json;
    } catch (error) {
        console.log(url, params, error);
    }
};