
export default function Player(options) {
    const {img} = options
    const me = {
        max_dx: 3,
        health: 10,
        dx: 10,
        dy: 10,
        cx: 0,
        cy: 0,
        trail: 100,
        ...options,
        init,
        update,
        draw,
        clear,
    };

    const trail = me.trail.toString(16).padStart(2, '0')
    const clear_background = `${options.background}${trail}`

    let $clientX = {}
    let $clientY = {}
    function init(game) {
        $clientX = game.$clientX
        $clientY = game.$clientY
    }

    function update(game) {
        let clientX = $clientX.value
        let clientY = $clientY.value
        if (clientX) {
            // me.dx = Math.min(me.dx + me.a, me.max_dx);
            if (clientX.value > me.x) {
                me.x = Math.min(me.x + me.dx, clientX);
            } else {
                me.x = Math.max(me.x - me.dx, clientX);
            }
        }
        if (clientY) {
            // me.dx = Math.min(me.dx + me.a, me.max_dx);
            if (clientY > me.y) {
                me.y = Math.min(me.y + me.dy, clientY);
            } else {
                me.y = Math.max(me.y - me.dy, clientY);
            }
        }
        me.left = me.x - 10;
        me.right = me.x - 10;
        me.top = me.y - 10;
        me.bottom = me.y + 10;
        me.cx = me.x - img.width / 2
        me.cy = me.y - img.height / 2
    }

    function draw(game) {
        const {ctx} = game
        ctx.beginPath();
        if (img) {
            ctx.drawImage(img, me.cx, me.cy);
        } else {
            ctx.fillStyle = me.color;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
        ctx.closePath()
    }

    function clear(game) {
        const {ctx} = game
        // ctx.font = `20px "Press Start 2P", monospace`;
        ctx.beginPath();
        if (img.width) {
            ctx.fillStyle = me.background;
            // ctx.fillStyle = 'orange';
            const args = [me.cx, me.cy, img.width, img.height]
            ctx.fillRect(me.cx, me.cy, img.width, img.height);
            ctx.fill();
        } else {
            ctx.fillStyle = me.background;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
        ctx.closePath()
    }


    return me;
}