import IconArrowUp from "solidjs-feather/IconArrowUp.jsx";
import IconTrash2 from "solidjs-feather/IconTrash2.jsx";
import {enable_report} from "../config.js";
import {$left_dom, $right_dom, position, report, page_theme} from "../store.jsx";
import {computed, computed0, on_mounted, after_mounted, watch1, ref} from "nano/reactive.jsx";
import IconFrown from "solidjs-feather/IconFrown.jsx";
import Dialog from "../dialog/Dialog.jsx";
import {toPng} from "html-to-image";
import Show from "nano/Show.jsx"

export default function Footer(props = {}, children=[]) {
    const {on_continue, remove} = props
    const report_style = {
        // color: computed(() => page_theme.value === 'dark' ? 'white' : 'black'),
        border: "none",
        outline: "none",
        padding: "10px",
        "font-size": "1em",
        background: "none"
    }

    const show_report = ref(enable_report.value)

    return (
        <div class="footer">
            <Show when={show_report}>
                <button style={report_style}
                        onclick={async (event) => {
                            show_report.value = false
                            await report(event)
                        }} >
                    <IconFrown/>
                    {/*🐞*/}
                </button>

            </Show>
            <button class="continue_button" style="flex: 1; "
                    onclick={on_continue}>
                <span style="font-size: 1.3rem; position: relative; top: 3px;"><IconArrowUp/></span>
                <span>CONTINUE</span>
            </button>
            <Show when={remove}>
                <button style="border: none; outline: none; padding: 10px; font-size: 1em; background: none;"
                        onclick={remove} ><IconTrash2/></button>
            </Show>
        </div>)
}