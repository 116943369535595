import {ref_render} from "nano/nano.jsx";
import EditorPage from './EditorPage.jsx'


console.log( {type: "tracer"})
export default function EditorAlgorithm(context) {
    const props = {data: context.data}
    const me = {update}
    async function update($dom) {
        await ref_render($dom, EditorPage, {props})
    }
    return me
}