import effects from "./effects.jsx";
import {partition} from "./lib/_.js";

console.log("dom");

export function swap_node (nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    siblingA ? parentA.insertBefore(nodeB, siblingA) : parentA.appendChild(nodeB);
};


export async function animate($el, keyframes, options) {
    const animation = $el.animate(keyframes, options);

    return animation.finished;
}

export async function flip($el, first, last) {
    const [keyframes, options] = effects.flip(first, last);
    return animate($el, keyframes, options);
}

function multi_animate(promises, $el, effect, {duration}) {
    const [keyframes, options] = effect($el._first, $el._last, {duration});
    if (keyframes && options) {
        promises.push(animate($el, keyframes, options));
    }
}

export async function flips(added, moved, removed, {add = true, move = true, remove = true, duration = 200} = {}) {
    const animate_added = add ? added : [];
    const animate_moved = move ? moved : [];
    const animate_removed = remove ? removed : [];

    const promises = [];
    if (add) {
        animate_added.forEach($el => {
            multi_animate(promises, $el, effects.grow, {duration});
        });
    }

    if (move) {
        animate_moved.forEach($el => {
            multi_animate(promises, $el, effects.flip, {duration});
        });
    }

    if (remove) {
        animate_removed.forEach($el => {
            multi_animate(promises, $el, effects.shrink, {duration});
        });
    }

    await Promise.all(promises);

}

function is_element_node($el) {
    return $el.nodeType === Node.ELEMENT_NODE;
    // return $el.nodeType !== Node.TEXT_NODE;
}

function cancel_bubbling(event) {
    event.cancelBubble = true;
    event.stopPropagation && event.stopPropagation();
}


const sanitize_map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    "\"": "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
};

function remove_script(input) {
    return input.replace(/<\s*script\s*>.*<\/\s*script\s*>/g, "");
}

export function sanitize(input) {
    // Escape HTML special characters
    const sanitized = typeof input === "string" ? remove_script(input) : input;
    if (sanitized !== input) {
        console.log(input, sanitized);
    }
    return sanitized;
}

function js_to_css_case(str) {
    return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

// some attributes are in camelCase not like other attributes as dash-case
const camelCases = new Set(["contentEditable", "markerWidth", "markerHeight", "markerUnits", "viewBox"]);

function option_js_to_css_case(key) {
    return camelCases.has(key) ? key : js_to_css_case(key);
}

const key_map = (function () {
    const maps = {
        "className": "class",
        "onClick": "onclick"
    };
    return (key) => {
        return option_js_to_css_case(maps[key] || key);
    };
}());

export function set($el, type, val) {
    switch (type) {
        case "value":
            return $el.value = val;
        case "checked":
            return $el.checked = sanitize(val);
        case "innerHTML":
            return $el.innerHTML = sanitize(val);
        default:
            set_attribute($el, type, val);
    }
}

export function enable_class($el, enabled, clazz) {
    try {
        if (clazz) {
            enabled ? $el.classList.add(clazz) : $el.classList.remove(clazz);
        }
    } catch (error) {
        console.error({$el, name, clazz});
    }
}

export function set_style($el, name, value) {
    const is_variable = name.startsWith("--");
    try {
        if (value === undefined) {
            // nothing need to be done
        } else {
            if (is_variable) {
                $el.style.setProperty(name, value);
            } else {
                $el.style[name] = value;
            }
        }
    } catch (error) {
        console.error({$el, name, value});
    }
}

export function set_attribute($el, name, val) {
    try {
        const key = key_map(name)
        if (key === 'value') {
            $el.value = val
        } else {
            $el.setAttribute(key, sanitize(val));
        }
    } catch (error) {
        console.error({$el, name, val});
    }
}

export function dynamic_function_name(name, fun, $el, event_name, listener) {
    const new_fun = fun.toString().replace(/function [a-zA-Z_][a-zA-Z0-9_]*/, `function ${name}`);
    const f = new Function("$el", "event_name", "listener", `return ${new_fun}`);
    return f($el, event_name, listener);
}

export function add_event_listener($el, event_name, listener, {capture = false} = {}) {
    $el.event_listeners = $el.event_listeners || [];
    // char charCode which
    // TODO here can patch to make the event handler normalized

    async function normalized_listener(event, {stop = false, prevent = false} = {}) {
        event = event || window.event;
        // Cannot set property target of #<Event> which has only a getter
        // event.target = event.target || event.srcElement;

        if (stop) {
            // According to Douglas Crockford, there are 2 ways of stop propagation
            // while might be now only ONE way is needed
            event.cancelBubble = true;
            event.stopPropagation && event.stopPropagation();
        }
        if (prevent) {
            // According to Douglas Crockford, there are 3 ways of prevent default behavior
            // while might be now only ONE way is needed
            // event.returnValue = false
            event.preventDefault && event.preventDefault();
        }

        // According to Douglas Crockford, there are 3 ways of get char code
        // while might be now only ONE way is needed
        // event.charCode = event.char || event.charCode || event.which;
        try {
            await listener(event);
        } catch (error) {
            console.error({error, $el, event_name, listener});
        }
        if (prevent) {
            return false;
        }
    };


    try {
        // if (import.meta.env.DEV) {
        //     const at = Date.now();
        //     if (event_name === 'touchclick') {
        //         const dynamic_named = dynamic_function_name(`click_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener('click', dynamic_named, capture);
        //         $el.event_listeners.push({event_name: 'click', listener, normalized_listener: dynamic_named, at});
        //
        //         const dynamic_named_touchstart = dynamic_function_name(`touchstart_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener('touchstart', dynamic_named_touchstart, capture);
        //         $el.event_listeners.push({event_name: 'touchstart', listener, normalized_listener: dynamic_named_touchstart, at});
        //
        //     } else {
        //         const dynamic_named = dynamic_function_name(`${event_name}_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener(event_name, dynamic_named, capture);
        //         $el.event_listeners.push({event_name, listener, normalized_listener: dynamic_named, at});
        //     }
        // } else {
        if (event_name === "touchclick") {
            $el.addEventListener("click", listener, capture);
            $el.addEventListener("touchstart", (event) => {
                // event.preventDefault();
                listener(event);
            }, capture);
        } else {
            $el.addEventListener(event_name, listener, capture);
        }

        // }
    } catch (error) {
        console.warn($el, event_name, listener);
    }
}

export function remove_event_listener($el, event_name, listener) {

    // const is_prod = true;
    // if (import.meta.env.DEV) {
    //     const [my_listeners, other_listeners] = partition($el.event_listeners,
    //         e => e.event_name === event_name && e.listener === listener);
    //
    //     $el.event_listeners = other_listeners;
    //
    //     my_listeners.forEach(item => {
    //         $el.removeEventListener(event_name, item.normalized_listener);
    //     });
    // } else {
    $el.removeEventListener(event_name, listener);
    // }
}

export function remove_all_event_listeners($el) {
    ($el.event_listeners || []).forEach(e => {
        $el.removeEventListener(e.event, e.listener);
    });
}


export async function append_element($parent, $el, effect = effects.fade_in) {
    try {
        $parent.append($el);
        if (is_element_node($el) && effect) {
            const first = undefined;
            const last = $el.getBoundingClientRect();
            const [keyframes, options] = effect(first, last, {});
            await animate($el, keyframes, options);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error, $parent, $el);
    }
}


export async function remove_element($el, effect = effects.fly_out) {
    if (is_element_node($el) && effect) {
        const first = $el.getBoundingClientRect();
        const last = {...JSON.parse(JSON.stringify(first))};
        console.log({first, last});

        await flip($el, first, last);

        const [keyframes, options] = effect(first, last, {});
        await animate($el, keyframes, options);
    }
    remove_all_event_listeners($el);
    $el.remove();
}

export async function move_item(from_array_ref, to_array_ref, d) {
    const $el = d.$el;
    // 1 get first
    d._first = d.$el.getBoundingClientRect();
    console.log("first", d._first);
    // 2 set d.$el to undefined, since we want to recreate the element
    // so that all the event listeners will be attached properly
    d.$el = undefined;
    $el.style.opacity = 0.001;


    requestAnimationFrame(() => requestAnimationFrame(async () => {
        // 3 append to_array_ref
        // 4 it will trigger create_element and d.$el will be assigned
        // 5 the $el will be appended to the parent
        to_array_ref.append(d);


        // 6 get last
        d._last = d.$el.getBoundingClientRect();
        console.log("last", d._last);
        // 7 flip animation
        await flip(d.$el, d._first, d._last);

        // 8 remove element
        from_array_ref.remove(d);
        // await remove_element($el, effects.shrink);
    }));

}

export function adjust_element_size({max_font= 50, width=300, tolerance=0.2, font_width_ratio=0.65, max_rows=3}) {
    return function adjust_size($el) {
        const words = $el.textContent.split(' ')
        const max_word_length = words.reduce((acc, curr) => acc > curr.length ? acc : curr.length, 0)
        const estimate_length = Math.max(max_word_length, $el.textContent.length / max_rows)
        let fontSize = Math.min(width / estimate_length / font_width_ratio, max_font)
        let iterations = 5
        function adjust() {
            $el.style['font-size'] = `${fontSize}px`
            const rect = $el.getBoundingClientRect()
            if (rect.width === 0) {
                // console.log(rect)
            } else {
                const shift = (width - rect.width) / estimate_length

                if (Math.abs(shift) > tolerance && ((max_font - fontSize) > tolerance) && iterations > 0) {
                    fontSize = Math.min(fontSize + shift / font_width_ratio, max_font)
                    iterations -= 1
                    requestAnimationFrame(adjust)
                }
            }
        }
        adjust()
    }
}