import {effect, is_ref, untrack} from "./reactive.jsx";
import {_create, create_element, Render} from "./nano.jsx";
import {add_element, flips, remove_all_event_listeners, remove_element} from "./dom.jsx";
import {fly_out, shrink} from "./effects.jsx";

console.log({type: "tracer"});
// require one single element wrap for around
export default function For(props, children) {
    // console.log({props, children});
    const {
        _each,
        _scroll_bottom = false,
        $parent,
        _add_effect,
        _move_effect,
        _remove_effect} = props;


    // fn: (d, i) => Element
    const [fn, ..._] = children;
    const fnx = (args) => _create(args, $parent);
    // console.log({each: _each, fn, isCreateElement: fn === create_element});
    _each.$el = $parent;

    function create(ar) {
        const result = ar.map((d, i) => {
            if (typeof d === "object" && d.__type === "ref") {
                // the previous item already exist
                // we will need to remove it afterwards
                const $existing = d.$el;
                d.$el = undefined
                if ($existing) {
                    requestAnimationFrame(() => {
                        remove_element($existing);
                    });
                }
                // const $el = fn(d, i, children);
                const $el = untrack(() => Render(fn, [d, i], children));
                d.$el = $el;
                return $el;
            } else {
                // const $el = fn(d, i, children);
                const $el = untrack(() => Render(fn, [d, i], children));
                return $el;
            }
        });
        // console.log({result});
        return result;
    }

    function _in(...args) {
        console.log("int args", args);
    }

    function _out(...args) {
        console.log("out args", args);
    }

    let initial = true;
    // underlying each is a ref object
    if (is_ref(_each)) {
        let $els = [];
        effect(async () => {
            // console.log({type: "each changed", _each: _each.value});

            $els.forEach($el => {
                $el._first = $el.getBoundingClientRect();
                $el._last = undefined;
                $el._not_removed = undefined;
            });

            const values = _each.value || [];
            const $result = [];
            // console.log("values", values);
            let $prev = undefined
            for (let i = 0; i < values.length; i += 1) {
                const d = values[i];
                let $el;
                // if (typeof d === "object" && d.__type === "ref") {
                // the previous item already exist
                if (d.$el) {
                    $el = d.$el;
                } else {
                    $el = untrack(() => Render(fn, [d, i], children));
                    if (typeof d !== "string") {
                        d.$el = $el;
                        $el.d = d
                    }
                    // TODO might need to loop as well for Fragment
                    add_element($parent, $el, $prev, false).then(() => {
                        // async return value is ignored
                        // console.log
                    });
                }

                $prev = $el
                // console.log($el);
                $el._not_removed = true;
                $result.push($el);
            }

            // all element in $els without _last means it is removed
            const removed = $els.filter(el => el._not_removed === undefined);
            // all element in result without _first means it's newly added
            const added = $result.filter(el => el._first === undefined);
            // all element has both _first and _last are the common ones, they are moved around
            const moved = $els.filter(el => el._first !== undefined && el._last !== undefined);


            removed.forEach($el => {
                $el.style.height = 0;
            });

            $result.forEach($el => {
                $el._last = $el.getBoundingClientRect();
            });

            if (initial) {
                // do NOT run animate when create for the first time
                initial = false
            } else {
                await flips(added, moved, removed, {add_effect: _add_effect, move_effect: _move_effect, remove_effect: _remove_effect});
            }

            removed.forEach($el => {
                remove_all_event_listeners($el)
                if ($el.d) {
                    $el.d.$el = undefined
                }
                $el.remove();
            });
            // console.log({added, moved, removed});
            // removed.forEach($el => {
            //     if ($el.d) {
            //         $el.d.$el = undefined
            //     }
            //     if (_animate["remove"]) {
            //
            //         remove_element($el, _animate["remove"] && shrink);
            //     } else {
            //         $el.remove();
            //     }
            //
            // });
            $els = $result;
            if (_scroll_bottom) {
                setTimeout(() => {
                    console.log('scroll', $parent.scrollHeight)
                    // $parent.scrollTop = $parent.scrollHeight
                    $parent.scrollTo({
                        top: $parent.scrollHeight,
                        behavior: "smooth",
                    })
                }, 100)
            }
        });
        // console.log({els});
        // return els;

    } else {
        const els = create(_each);
        els.forEach($el => {
            Array.isArray($el) ? $el.forEach(_$el => $parent.append(_$el)) : $parent.append($el);
        });
    }
}
