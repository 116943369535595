import "./Article.css";
import For from "nano/For.jsx";
import Show from "nano/Show.jsx";
import {entry_language, fluent_language, get_translation} from "../store.jsx";
import Spanize from "nano/components/Spanize.jsx";
import {on_mounted, ref} from "nano/reactive.jsx";
import safe_fetch from "nano/lib/safe_fetch.js";
import api from "../api.jsx";
import {playByText} from "../audio.js";
import PromptBar from "../components/PromptBar.jsx";


function Chapter(props = {}, children = []) {
    const {chapter} = props

    return (
        <section class="chapter">
            <h3><Spanize text={chapter.title}/></h3>
            <p class="paragraph">
                <Spanize text={chapter.sentences.join('')}/>
            </p>
        </section>
    )
}

export default function ArticlePage(props = {}, children = []) {
    const {
        use_prompt=false
    } = props;

    // console.log(new Error().stack);

    const [$title, $subtitle, $chapters] = [ref(''), ref(''), ref([])]
    if (use_prompt) {
        $title.value = 'Your Article'
        $subtitle.value = "Type something you like below"
    } else {
        on_mounted(get_article)
    }
    const show_prompt = ref(use_prompt)

    async function get_article(topic) {
        const resp = await safe_fetch('/api/article', {}, {topic, language: entry_language.value})
        console.log(resp)

        $title.value = resp.title
        $subtitle.value = resp.subtitle
        $chapters.value = resp.chapters

        show_prompt.value = false
    }


    // async function on_speak(event) {
    //     event.stopPropagation()
    //     const {target, currentTarget} = event
    //     console.log(target, currentTarget)
    //
    //     await play_object($chapters.value)
    // }
    let timeout_tooltip = undefined
    function onclick(event) {
        const {target, currentTarget} = event
        console.log(target, currentTarget)
        const text = target.innerText
        if (timeout_tooltip) {
            clearTimeout(timeout_tooltip)
        }

        function show_tooltip(resp) {
            const {data} = resp
            console.log(data)
            const translation = data[fluent_language.value]
            if (translation) {
                const rect = target.getBoundingClientRect()
                const $tooltip = document.getElementById('tooltip')
                const font_size = Number(window.getComputedStyle(target).fontSize.split('.').at(0))
                const right_most = (target.parentElement.getBoundingClientRect().right - rect.right) < 5
                const width = right_most ? rect.width : (rect.width - font_size)
                const left = `${rect.left + width / 2}px`
                const top = `${rect.top + rect.height + 5}px`
                $tooltip.style.left = left
                $tooltip.style.top = top
                $tooltip.innerText = translation

                timeout_tooltip = setTimeout(() => {
                    $tooltip.style.left = ''
                    $tooltip.innerText = ''
                }, 3000)

            }
        }
        get_translation(text, entry_language.value).then(show_tooltip)
        // play(text, entry_language.value)
        playByText(entry_language.value, text)


    }


    const style = {
        "display": "flex",
        "flex-direction": "column",
        // this will cause the scrollHeight not able to accomdate all children, so not able to scroll all the way to top
        /* justify-content: center; */
        "align-items": "center",
    }
    return (
        <div class="page article" style={style} onclick={onclick}>
            {/* SpeechSynthesisUtterance for paragraph is TERRIBLE. TODO Replace to other TTS*/}
            {/*<div class="speak_button" onclick={on_speak} >*/}
            {/*    <IconVolume2/>*/}
            {/*</div>*/}
            <section class="title">
                <h1><Spanize text={$title}/></h1>
                <h2><Spanize text={$subtitle}/></h2>
            </section>
            <div>
                <For _each={$chapters}>{(chapter, i) => {
                    return <Chapter chapter={chapter}></Chapter>
                }}</For>
            </div>
            <Show when={show_prompt}>
                <PromptBar on_finish={get_article} show={show_prompt}></PromptBar>
            </Show>
        </div>
    );
}