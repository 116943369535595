import For from "../For.jsx";
import {computed} from "../reactive.jsx";

console.log({type: "tracer"});
const regex = /([ \t\n\r\f\v!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~])/;

const symbol_set = new Set('.,;:?!'.split(''))
function is_symbol(part) {
    return symbol_set.has(part)
}

// split a whole string to list of spans
// so that event listeners can be separated target those spans
//
// "hello,world" =>
// <span>
//      <span>hello</span>
//      <span>, </span>
//      <span>world</span>
// </span>
export default function Spanize(props = {}, children=[]) {
    const {text = '', ruby, clazz} = props
    // Regular expression to match spaces and symbols

    if (ruby) {
        return <span _html={ruby}></span>
    } else {
        const words = computed(() => {
            const v = (text || {}).hasOwnProperty('value') ? text.value : text
            const parts = (v || '').split(regex).filter(part => part.trim())
            // merge symbol to the preview word
            // ['hello', ','] will be merged as 'hello,'
            for (let i = parts.length - 1; i > 1; i -= 1) {
                const part = parts[i]
                if (is_symbol(part)) {
                    parts.splice(i, 1)
                    parts[i - 1] = parts[i - 1] + part
                }
            }

            return parts.map(word => symbol_set.has(word) ? `${word} ` : word)
        });

        return (
            <span>
            <For _each={words}>{(word, i) => {
                return <span class={clazz}>{word} </span>
            }}</For>
        </span>)
    }
}