import {ref_render} from "nano/nano.jsx";

import VocabularyPage from "./VocabularyPage.jsx";

console.log({type: "tracer"})

export default function VocabularyAlgorithm(context) {
    const props = {data: context.data}
    const me = {update}
    async function update($dom) {
        await ref_render($dom, VocabularyPage, {props})

    }
    return me
}