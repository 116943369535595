import For from 'nano/For.jsx'
import Show from 'nano/Show.jsx'
import Radios from 'nano/components/Radios.jsx'
import IconSave from "solidjs-feather/IconSave.jsx";
import IconPlus from "solidjs-feather/IconPlus.jsx";
import {computed, ref, on_mounted, watch0} from 'nano/reactive.jsx'
import {to_base64} from 'nano/lib/converter.js'
import api from "../api.jsx";



export default function WordValidator(props = {}, children=[]) {
    const table_style = {
        'width': '100%',
        "border-collapse": "collapse"
    }
    const header_style = {
        display: 'flex',
        'justify-content': 'left',
        gap: '10px'
    }

    const style = {
        width: '100%',
        height: '100%',
        display: 'flex',
        'flex-direction': 'column',
        'font-size': '18px'

    }
    let offset = ref(0)
    let limit = ref(100)
    const filter = ref()
    const rows = ref([])
    const headers = ['id', 'language', 'text', ...Object.keys(meta.value.enabled_languages), '🐞']
    const last_column = headers.length - 1

    const filtered_rows = computed(() => rows.value)

    async function get_range() {
        const resp = await api.get(`/api/translation/range`, {offset: offset.value, limit: limit.value})
        rows.value = resp.data
    }

    function Row(props={}, children=[]) {
        const {row} = props
        function onclick(event) {
            const {currentTarget} = event
            currentTarget.contentEditable = true
            currentTarget.focus()
        }

        function onkeydown(event) {
            const {currentTarget, key} = event
            if (key === "Enter") {
                event.preventDefault();
                currentTarget.contentEditable = false
            }
        }


        return (
            <tr>
                <For _each={headers}>{(header, i) => {

                    if (i === last_column) {
                        return <td></td>
                    } else {
                        return <td onclick={onclick} onkeydown={onkeydown}>{row[header]}</td>
                    }
                }}</For>
            </tr>)
    }

    on_mounted(get_range)

    return (
        <div class="page word_validator" style={style}>
            <div style={header_style}>
                {/*<For _each={Object.entries(generation_names)}>{([generation_index, generation_name]) => {*/}
                {/*    return (*/}
                {/*        <label>*/}
                {/*            <input type="radio"*/}
                {/*                   value={`${generation_index}${generation_name}`}*/}
                {/*                   model={generation}*/}
                {/*                   name="generation"></input>*/}
                {/*            <span>{generation_index}</span>*/}
                {/*            <span>{generation_name}</span>*/}
                {/*        </label>*/}
                {/*    )*/}
                {/*}}</For>*/}
                <div><input type="text" model={filter} style="border: 1px solid #dde;"></input></div>
            </div>
            <div style="flex: 1; overflow: auto; ">
            <table style={table_style}>
                <thead>
                    <tr style="background: #ccd; padding: 5px 0">
                        <For _each={headers}>{(header, i) => {
                            return <th style="text-align: left; ">{header}</th>
                        }}</For>

                    </tr>
                </thead>
                <tbody>
                <For _each={filtered_rows}>{(row, i) => {
                    return <Row row={row} i={i}/>
                }}</For>
                </tbody>
            </table>
            </div>
    </div>)
}