import "./Word.css";
import Tip from "../components/Tip.jsx";
import {algorithm, basket} from "../store.jsx";
import TextRect from "nano/components/TextRect.jsx";
import Footer from "../components/Footer.jsx";

console.log({type: 'tracer'})

export default function Summary(props={}, children) {
    const {n_articles=3, n_words=10, type} = props
    let status_texts = undefined
    let $tip = (
        <div>
            <Tip/>
            <ul style="text-align: left">
                <li>translate in the app</li>
                <li>use translingo extension</li>
                <li>swipe read article</li>
            </ul>
        </div>)

    switch (type) {
        case 'empty':
            status_texts = ['YOUR BASKET', 'IS', 'EMPTY']
            break;
        case 'finished':
            status_texts = [`All words`, `have been reviewed`]
            break;
        case 'progress':
            status_texts = [`Awesome work`, `${n_words} words`, `reviewed`]
            break;
        default:
            console.error(type)
    }

    const empty_text = [`your basket is empty`]
    return (
        <div class="page review_word flex_center" style="gap: 2rem; text-align: center;">
            {/*<div class="header_placeholder"></div>*/}
            <TextRect texts={status_texts} colors={{1: 'var(--blue)'}}/>

            {$tip}
            <div></div>
            <Footer on_continue={algorithm.value.onswipeup}/>
        </div>
    )

}
