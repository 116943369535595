import "./Article.css";

export default function Summary(props={}, children) {
    const {n_articles=3, n_words=10} = props
    return (
        <div class="page article flex_center" style="gap: 2rem; text-align: center;">
            <h2>Awesome work</h2>
            <section>
                <p>Articles Read</p>
                <p>{n_articles}</p>
            </section>
            <section>
                <p>Words Added</p>
                <p>{n_words}</p>
            </section>
        </div>
    )
}