import {ref} from "nano/reactive.jsx";

export const rfc5646_language = {
    arabic: "ar",
    chinese: "zh",
    // english: "en",
    english: "en-US",
    french: "fr",
    japanese: "ja",
    russian: "ru",
    spanish: "es",
    swedish: "sv",
};


let _speechSynth;
let _voices;
const _cache = {};

export function turn_down_volume() {
    const audio = document.getElementById("audio");
    audio.volume = 0.05;
}

export function turn_up_volume() {
    const audio = document.getElementById("audio");
    audio.volume = 0.5;
}

/**
 * retries until there have been voices loaded. No stopper flag included in this example.
 * Note that this function assumes, that there are voices installed on the host system.
 */

function load_voices_when_available(onComplete = () => {}) {
    if ("speechSynthesis" in window) {
        _speechSynth = window.speechSynthesis;
        const voices = _speechSynth.getVoices();

        if (voices.length !== 0) {
            _voices = voices;
            onComplete();
        } else {
            return setTimeout(function () { load_voices_when_available(onComplete); }, 100);
        }
    }
}

/**
 * Returns the first found voice for a given language code.
 */

function getVoices(locale) {
    if (!_speechSynth) {
        throw new Error("Browser does not support speech synthesis");
    }
    if (_cache[locale]) return _cache[locale];

    _cache[locale] = _voices.filter(voice => voice.lang === locale);
    return _cache[locale];
}

/**
 * Speak a certain text
 * @param locale the locale this voice requires
 * @param text the text to speak
 * @param volume the text to speak
 * @param onend callback if tts is finished
 */
export function playByText(locale, text, volume = 1, onend = console.log) {
    const voices = getVoices(locale);
    console.log(locale, text, volume, voices);
    // TODO load preference here, e.g. male / female etc.
    // TODO but for now we just use the first occurrence
    const utterance = new window.SpeechSynthesisUtterance();
    // utterance.voice = voices[0];
    utterance.pitch = 1;
    utterance.rate = 1;
    utterance.voiceURI = "native";
    utterance.volume = volume;
    utterance.rate = 1;
    utterance.pitch = 0.8;
    utterance.text = text;
    utterance.lang = rfc5646_language[locale] || locale;

    utterance.onend = onend;
    utterance.onstart = function onstart(error) {
        console.log("onstart", error);
        window._ignited = true;
        // resolve(true);
    };
    utterance.onerror = function onerror(event) {
        console.log("onerror", event);
        // resolve(false);
    };


    _speechSynth.cancel(); // cancel current speak, if any is running
    _speechSynth.speak(utterance);
}

// on document ready
load_voices_when_available(function () {
    console.log("loaded");
});

export function speak(text, volume = 1) {
    setTimeout(() => playByText("en-US", text, volume), 0);
}

let playing = false;

function on(name, resolve) {
    return function (event) {
        console.log(name, event);
    };
}

let audio_context;

async function fetch_play(url) {
    if (audio_context === undefined) {
        audio_context = new AudioContext();
    }
    const resp = await fetch(url);
    const buffer = await resp.arrayBuffer();
    const decoded_audio = await audio_context.decodeAudioData(buffer);
    const play_sound = audio_context.createBufferSource();
    play_sound.buffer = decoded_audio;
    play_sound.connect(audio_context.destination);
    play_sound.start(audio_context.currentTime);
}

window.fetch_play = fetch_play;

export function async_play(text, language, volume, TIMEOUT=30 * 1000) {
    return new Promise((resolve, reject) => {
        const utterance = new SpeechSynthesisUtterance();
        utterance.lang = rfc5646_language[language];
        utterance.text = text;
        // utterance.voice = "Samantha";
        utterance.volume = volume;
        utterance.pitch = 1;

        let informed = false
        let playing = true
        // console.log(utterance);
        let timeout = setTimeout(() => {
            if (informed) {
            } else {
                reject('TIMEOUT')
            }
        }, TIMEOUT);

        utterance.onstart = function onstart(error) {
            // console.log("onstart", error);
            playing = true;

        };
        utterance.onerror = function onerror(event) {
            // console.log("onerror", event);
            playing = false
            if (timeout) {
                clearTimeout(timeout);
                timeout = undefined;
            }

            reject(event)
        };
        utterance.onend = function onend(event) {
            resolve(event)
        }
        window.speechSynthesis.speak(utterance);
    })
}
export async function play_object(object, language='english', volume=1) {
    const values = Array.isArray(object) ? object : Object.values(object)
    for (let i = 0; i < values.length; i += 1) {
        const value = values[i]
        if (typeof value === 'string') {
            await async_play(value, language, volume)
        } else {
            await play_object(value, language, volume)
        }
    }
}

export function play(text, language, volume = 1, callback) {
    // console.log("playing", volume);
    // if (ignited) {

    if (!`speechSynthesis` in window) {
        console.log("no speechSynthesis");
        return;
    }
    if (speechSynthesis.speaking) {
        return ref(false);
    } else {
        if (playing) {
            // still playing

            return ref(false);
        } else {
            const played = ref(false);
            try {
                // const voices = speechSynthesis.getVoices();
                // console.log(voices);
                const utterance = new SpeechSynthesisUtterance();
                utterance.lang = rfc5646_language[language];
                utterance.text = text;
                // utterance.voice = "Samantha";
                utterance.volume = volume;
                utterance.pitch = 1;

                // console.log(utterance);
                let timeout = setTimeout(() => {
                    played.value = "timeout";
                }, 2000);

                utterance.onstart = function onstart(error) {
                    // console.log("onstart", error);
                    playing = true;
                    played.value = true;

                };
                utterance.onerror = function onerror(event) {
                    // console.log("onerror", event);
                    played.value = "error";
                    callback && callback();
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = undefined;
                    }
                };
                utterance.onend = function onend(event) {
                    // console.log("onend", event);
                    playing = false;
                    played.value = true;
                    callback && callback();
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = undefined;
                    }
                };

                window.speechSynthesis.speak(utterance);

            } catch (error) {
                console.log({error, text, language});
            }

            playing = false;
            return played;
        }
    }
    // } else {
    //     const igniting = document.getElementById("igniting");
    //     igniting.dataset.text = text;
    //     igniting.click();
    //     ignited = true;
    //     // return playByText(language, text, volume);
    // }
}