import {after_mounted, computed, ref} from "../reactive.jsx";
import For from '../For.jsx'

let index = 0
export default function TextRect(props = {}, children=[]) {
    console.log(props)
    const {
        width=300,
        tolerance=0.2,
        max_adjust=8,
        max_size = 100,
        texts = ['WHERE', 'ARE WE', 'AND HOW', 'DID WE', 'GET HERE?'],
        colors = {}
    } = props
    let left_adjust = max_adjust
    const sizes = texts.map(text => ref(Math.min(width / text.length, max_size)))
    const font_sizes = sizes.map(size => computed(() => `${size.value.toFixed(1)}px`))
    const id = `text_rect_${index}`
    index += 1
    console.log(sizes.map(r => r.value.toFixed(1)))
    function adjust_size() {
        const children = [...document.getElementById(id).getElementsByTagName('span')]
        let adjusted = false
        const shifts = children.map((p, i) => {
            const rect = p.getBoundingClientRect()
            const text = texts[i]
            return (width - rect.width) / text.length
        })

        shifts.forEach((shift, i) => {
            const size = sizes[i]
            if (Math.abs(shift) > tolerance && size < max_size) {
                size.value = Math.min(size.value + shift, max_size)
                adjusted = true
            }
        })

        console.log(sizes.map(r => r.value.toFixed(1)))
        left_adjust -= 1
        if (adjusted && left_adjust > 0) {
            requestAnimationFrame(adjust_size)
        }
    }

    after_mounted(adjust_size)
    return (
        <div id={id} style="padding: 1rem">
            <For _each={texts}>{(text, i) =>
                <p style={{'font-size': font_sizes[i], color: colors[i]}} class="flex_center"><span>{text}</span></p>
            }</For>
        </div>)
}