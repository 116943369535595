import {ref_render} from "nano/nano.jsx";
import RecorderPage from './RecorderPage.jsx'


console.log({type: "tracer"})
export default function RecorderAlgorithm(context) {
    const props = {data: context.data}
    const me = {update, finished: true}
    async function update($dom) {
        await ref_render($dom, RecorderPage, {props})
    }
    return me
}