export default function Particle(options) {
    const me = {
        friction: 1,
        d_alpha: -0.02,
        ...options,
        clear,
        update,
        draw
    };

    const radius2 = me.radius * 2
    function clear({ctx}) {
        ctx.fillStyle = me.background;
        // ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);

        ctx.fillRect(me.x - me.radius - 1, me.y - me.radius - 1 , radius2 + 2, radius2+ 2);
    }

    function update(game) {
        me.dx *= me.friction;
        me.dy *= me.friction;
        me.x = me.x + me.dx;
        me.y = me.y + me.dy;
        me.alpha += me.d_alpha;
    }

    function draw({ctx}) {
        ctx.save();
        ctx.globalAlpha = me.alpha;
        ctx.beginPath();
        ctx.fillStyle = me.color;
        ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
        ctx.fill();
        ctx.restore();
    }

    return me;
}