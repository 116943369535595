import "./Options.css";
import IconArrowUp from "solidjs-feather/IconArrowUp.jsx";
import IconArrowLeft from "solidjs-feather/IconArrowLeft.jsx";
import For from 'nano/For.jsx'
import Show from 'nano/Show.jsx'
import Visible from 'nano/Visible.jsx'

import {computed, ref} from 'nano/reactive.jsx'
import IconPlus from "solidjs-feather/IconPlus.jsx";
import {meta} from '../store.jsx'
import api from '../api.jsx'

import {get_lang_flag} from "nano/lib/unicode.js";
import Header from "../components/Header.jsx";
import {to_base64} from "../../common/converter.js";

console.log({type: 'tracer'})
export default function Options(props = {}, children = []) {
    const {
        data = {}
    } = props;
    const {
        title='Congratulations',
        subtitle = 'You have reviewed all word',
        left = 'To word again',
        up = 'To start match'} = data

    const enabled_languages = Object.keys(meta.value.enabled_languages || {})
    const learning_languages = ref(meta.value.learning_languages)
    const fluent_languages = ref(meta.value.fluent_languages)

    async function save_options() {
        const data = JSON.stringify({
            learning_languages: learning_languages.value,
            fluent_languages: fluent_languages.value
        })
        console.log(data)
        await api.post('/api/meta', {data: to_base64(data)})
    }


    function LanguageSelector(props) {
        const {$result, total} = props
        const each = computed(() => total.filter(lang => !$result.value.includes(lang)))
        function onclicker(lang) {
            return async function (event) {
                $result.push(lang)
                await save_options()
            }
        }
        return (
            <div style="display: flex; gap: 15px;">
                <For _each={each}>{(lang, i) => {
                    return  <span onclick={onclicker(lang)} class="language">{get_lang_flag(lang)}</span>
                }}</For>
            </div>)
    }

    const adding_learning_language = ref(false)
    const adding_fluent_language = ref(false)

    return (
        <div class="page options">
           {/* <div class="header">*/}
           {/*    <h2>Options</h2>*/}
           {/*</div>*/}
           {/* <Header></Header>*/}
            <div style="height: 10px"></div>
            <div>
                <div>
                    <span>Learning Languages</span>
                </div>
                <div style="display: flex; align-items: center; gap: 1rem;">
                    <div style="display: flex; align-items: center; gap: 15px;">
                        <For _each={learning_languages}>{(lang, i) => {
                            async function onclick() {
                                learning_languages.filter(language => language !== lang)
                                await save_options()
                            }

                            return <span class="language" onclick={onclick}>{get_lang_flag(lang)}</span>
                        }}</For>
                    </div>
                    <span
                        style={{color: computed(() => adding_learning_language.value ? '' : 'var(--blue)')}}
                        onclick={() => adding_learning_language.value = !adding_learning_language.value}
                    ><IconPlus/></span>
                </div>
                <Visible when={adding_learning_language}>
                    <LanguageSelector $result={learning_languages} total={enabled_languages}/>
                </Visible>
            </div>
            <div>
                <div>Fluent Languages</div>
                <div style="display: flex; align-items: center; gap: 1rem;">
                    <div>
                        <For _each={fluent_languages}>{(lang, i) => {
                            async function onclick() {
                                fluent_languages.filter(language => language !== lang)
                                await save_options()
                            }

                            return <span class="language" onclick={onclick}>{get_lang_flag(lang)}</span>
                        }}</For>
                    </div>
                    <span
                        style={{color: computed(() => adding_fluent_language.value ? '' : 'var(--blue)')}}
                        onclick={() => adding_fluent_language.value = !adding_fluent_language.value}
                    ><IconPlus/></span>
                </div>
                <Visible when={adding_fluent_language}>
                    <LanguageSelector $result={fluent_languages} total={enabled_languages}/>
                </Visible>

            </div>
            <div style="flex: 1"></div>
        </div>
    );
}