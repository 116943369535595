import "./Article.css";
import {algorithm, basket} from "../store.jsx";
import TextRect from "nano/components/TextRect.jsx";

export default function Status(props={}, children) {

    const size = basket.value.length
    const status_texts = size ?
        ['  ONLY  ', `${size}`, ` words `, `in your basket`] :
        ['YOUR BASKET IS', 'EMPTY']
    const texts = [`Read AN`, 'Article?']
    const right_texts = [`←Swipe Left`, 'Prompt']

    return (
        <div class="page article flex_center" style="gap: 3rem;">
            <TextRect texts={status_texts} colors={{1: 'var(--blue)'}}/>
            <TextRect texts={texts} max_size={40} w/>
            <div>
                <div style="font-size: 1.3em"><span style="color: var(--blue)">↑</span><span>Swipe Up</span></div>
                <div style="text-align: center">Automatic</div>
            </div>
            <div>
                <div style="font-size: 1.3em"><span style="color: var(--blue)">←</span><span>Swipe Left</span></div>
                <div style="text-align: center">You Choose</div>
            </div>
        </div>
    )
}
