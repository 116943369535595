import {ref} from 'nano/reactive.jsx'

console.log({type: 'tracer'})
export default function Photo(props={}, children=[]) {
    const src = ref('/images/apple.jpg')

    return (
        <div>
            <img src={src} alt=""/>
        </div>)
}